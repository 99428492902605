import React, { useEffect, useState } from 'react';
import styles from './coin-and-cash.module.scss';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import { Info as InfoIcon } from '@mui/icons-material';
import { ApplyPrxCurrency, getWallet } from 'utils/api';
import { addDelimeter } from 'utils/commonFunctions';
import DownArrow from '@mui/icons-material/KeyboardArrowDown';
import { ReactComponent as CashIcon } from 'assets/icons/dollar-cash.svg';
import { ReactComponent as CoinIcon } from 'assets/icons/dollar-coin.svg';
import useCartStore from 'store/cartStore';
import { enqueueSnackbar } from 'notistack';

const CoinAndCash = ({ increaseHeight, handleToggleHeight, userId }) => {
  const priceDetails = useCartStore((state) => state.priceDetails);
  const cartItems = useCartStore((state) => state.cartItems);
  const setPriceDetails = useCartStore((state) => state.setPriceDetails);
  const setCartItems = useCartStore((state) => state.setCartItems);

  const [wallet, setWallet] = useState({});
  const [coinsApplied, setCoinsApplied] = useState(0);
  const [cashApplied, setCashApplied] = useState(0);
  const [shippingToolTipOpen, setOpenShippingTooltip] = useState(false);

  const fetchActiveWallet = async () => {
    try {
      const payload = {
        headers: { user_id: userId },
      };
      const response = await getWallet()(payload);
      setWallet(response?.data?.data);
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e?.response?.data?.message, { variant: 'error' });
    }
  };

  const handleChange = async (event) => {
    try {
      const payload = {
        data: {
          amountType: event.target.value,
          selected: event.target.checked,
        },
        headers: { user_id: userId },
      };
      const res = await ApplyPrxCurrency()(payload);
      setPriceDetails(res?.data?.priceDetails);
      setCartItems(res?.data);
    } catch (e) {
      console.log(e);
      enqueueSnackbar(e?.response?.data?.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchActiveWallet();
  }, []);

  useEffect(() => {
    setCoinsApplied(priceDetails?.prxCoin?.toFixed(2));
    setCashApplied(priceDetails?.prxCash?.toFixed(2));
  }, [priceDetails]);

  return (
    <>
      {(wallet?.prxCoin > 0 || wallet?.prxCash > 0) && (
        <div
          className={`${styles.coupon_box_bottom} ${
            increaseHeight ? styles.inc_height : styles.dec_height
          }`}
        >
          <div className={styles.header} onClick={() => handleToggleHeight()}>
            <div className={styles.header__title}>Apply PRx Coins & Cash</div>

            <DownArrow
              className={increaseHeight ? styles.rotate : styles.rotateReverse}
            />
          </div>
          <div className={`${increaseHeight ? styles.show : styles.hide}`}>
            <div className={styles.main}>
              <div className={styles.cards_container}>
                {wallet?.prxCoin > 0 && (
                  <div className={styles.cards}>
                    <div className={styles.left_cont}>
                      <CoinIcon className={styles.coin_icon} />
                      <div>
                        <div className={styles.total_prx}>
                          {coinsApplied > 0 ? (
                            <p className={styles.value}>
                              PRx Coin: -₹
                              {addDelimeter(Number(coinsApplied))}
                            </p>
                          ) : (
                            <p className={styles.value}>
                              PRx Coin: ₹
                              {addDelimeter(
                                Math.min(
                                  100,
                                  Number(
                                    (priceDetails?.totalAmountToBePaid * 10) /
                                      100
                                  )
                                )
                              )}
                              <span className={styles.Tooltip}>
                                {' '}
                                <Tooltip
                                  setOpenTooltip={setOpenShippingTooltip}
                                  isToolTipOpen={shippingToolTipOpen}
                                  placement='top'
                                  content={
                                    <div className={styles.shipping_tooltip}>
                                      Redeem up to 10% of cart value in PRx
                                      coins, limited to ₹100 per order.
                                    </div>
                                  }
                                  icon={
                                    <InfoIcon
                                      className={styles.tooltip__icon}
                                      fontSize='16px'
                                    />
                                  }
                                />
                              </span>
                            </p>
                          )}
                        </div>
                        <div className={styles.prx}>
                          <p className={styles.title}>
                            Total PRx Coins:{' '}
                            {addDelimeter(Number(wallet?.prxCoin))}
                          </p>
                        </div>
                      </div>
                    </div>

                    <Checkbox
                      checked={cartItems?.cart?.prxCoin?.selected}
                      className={styles.check_box}
                      value='prxCoin'
                      onChange={handleChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </div>
                )}
                {wallet?.prxCash > 0 && (
                  <div className={styles.cards}>
                    <div className={styles.left_cont}>
                      <CashIcon className={styles.cash_icon} />
                      <div>
                        <div className={styles.total_prx}>
                          {cashApplied > 0 ? (
                            <p className={styles.value}>
                              PRx Cash: -₹
                              {addDelimeter(Number(cashApplied))}
                            </p>
                          ) : (
                            <p className={styles.desc}>
                              PRx Cash ₹{addDelimeter(Number(wallet?.prxCash))}
                            </p>
                          )}
                        </div>
                        <div className={styles.prx}>
                          <p className={styles.title}>
                            Total PRx Cash:{' '}
                            {addDelimeter(Number(wallet?.prxCash))}
                          </p>
                        </div>
                      </div>
                    </div>
                    <Checkbox
                      checked={cartItems?.cart?.prxCash?.selected}
                      className={styles.check_box}
                      value='prxCash'
                      onChange={handleChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CoinAndCash;
