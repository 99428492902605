import React, { useState } from 'react';
import styles from './cartDetails.module.scss';
import ItemDetails from './ItemDetails';
import { Button, Radio, FormControlLabel, TextField } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { initiateReturn, updatePaymentDetails } from 'utils/api';
import Modal from 'components/CustomElements/Modal';

const CartDetailsReturn = ({
  orderItems,
  orderId,
  setShowReturn,
  fetchOrderDetails,
}) => {
  const [returnItems, setReturnItems] = useState([]);
  const [returnData, setReturnData] = useState({});
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [upiDetails, setUpiDetails] = useState({
    beneficiaryName: '',
    upi: '',
  });
  const [bankDetails, setBankDetails] = useState({
    beneficiaryName: '',
    bankName: '',
    bankAccountNumber: '',
    ifscCode: '',
  });

  const handleReturn = async () => {
    try {
      const payload = returnItems.map((item) => ({
        ...item,
        imageUrls: item.imageUrls.map((img) => img.file_url),
      }));

      // Check for any empty fields
      const errorMessages = returnItems.flatMap((item, index) => {
        const messages = [];
        if (Array.isArray(item.imageUrls) && item.imageUrls.length === 0) {
          messages.push(
            `imageUrls is empty for drugCode: ${item.drugCode} at index: ${
              index + 1
            }`
          );
        }
        Object.entries(item).forEach(([key, value]) => {
          if (key !== 'imageUrls' && value === '') {
            messages.push(
              `${key} is empty for drugCode: ${item.drugCode} at index: ${
                index + 1
              }`
            );
          }
        });
        return messages;
      });

      if (errorMessages.length > 0) {
        console.log('error:', errorMessages.join(', '));
        enqueueSnackbar(errorMessages.join(', '), { variant: 'error' });
      } else {
        const data = {
          orderId: orderId,
          data: {
            items: payload,
          },
        };
        const res = await initiateReturn()(data);
        setReturnData(res.data);
        enqueueSnackbar('Return items submitted successfully', {
          variant: 'success',
        });
        setShowPaymentModal(true);
      }
    } catch (error) {
      enqueueSnackbar(error.response.data.message, { variant: 'error' });
    }
  };

  const handleCheckboxChange = (e, item) => {
    const isChecked = e.target.checked;
    const itemData = {
      drugCode: item.itemCatalogData.masterDrugCode,
      quantity: item.itemCartData.quantity,
      reason: '',
      imageUrls: [],
    };

    if (isChecked) {
      setReturnItems([...returnItems, itemData]);
    } else {
      setReturnItems(
        returnItems.filter((data) => data.drugCode !== itemData.drugCode)
      );
    }
  };

  const handlePayment = async () => {
    try {
      const paymentData =
        paymentMethod === 'upi'
          ? { key: 'upiDetails', value: upiDetails }
          : paymentMethod === 'banktransfer'
          ? { key: 'bankDetails', value: bankDetails }
          : paymentMethod === 'prxCash'
          ? { key: 'prxCash', value: { prxCash: 'prxCash' } }
          : paymentMethod === 'source'
          ? { key: 'source', value: { source: 'source' } }
          : null;
      const data = {
        returnId: returnData.returnRequest.id,
        data: {
          transferMode: paymentMethod,
          [paymentData.key]: paymentData.value,
        },
      };

      await updatePaymentDetails()(data);
      fetchOrderDetails();
      enqueueSnackbar('Payment details submitted successfully', {
        variant: 'success',
      });
      setShowReturn(false);
    } catch (error) {
      enqueueSnackbar(error.response.data.message, { variant: 'error' });
    }
  };

  return (
    <div className={styles.details_container}>
      {orderItems?.length > 0 &&
        orderItems.map((item, index) => (
          <div key={index}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type='checkbox'
                onChange={(e) => handleCheckboxChange(e, item)}
                checked={returnItems.some(
                  (data) =>
                    data.drugCode === item.itemCatalogData.masterDrugCode
                )}
              />
              <ItemDetails
                item={item}
                returnItem={returnItems.find(
                  (data) =>
                    data.drugCode === item.itemCatalogData.masterDrugCode
                )}
                returnItems={returnItems}
                setReturnItems={setReturnItems}
              />
            </div>
            {index !== orderItems.length - 1 && (
              <div className={styles.horizontal_divider} />
            )}
          </div>
        ))}
      <Button variant='contained' onClick={handleReturn}>
        Return
      </Button>

      <Modal openModal={showPaymentModal} setOpenModal={setShowPaymentModal}>
        <div className={styles.payment_modal}>
          <h2>Select Payment Method</h2>
          <div className={styles.payment_options_container}>
            <div className={styles.payment_options}>
              <div className={styles.payment_options__item}>
                <FormControlLabel
                  control={
                    <Radio
                      checked={paymentMethod === 'prxCash'}
                      onChange={() => setPaymentMethod('prxCash')}
                    />
                  }
                  label='PRx Cash'
                />
              </div>
              {returnData?.paymentMode == 'Online Payment' ? (
                <div className={styles.payment_options__item}>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={paymentMethod === 'source'}
                        onChange={() => setPaymentMethod('source')}
                      />
                    }
                    label='Back to source'
                  />
                </div>
              ) : (
                <>
                  <div className={styles.payment_options__item}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={paymentMethod === 'upi'}
                          onChange={() => setPaymentMethod('upi')}
                        />
                      }
                      label='UPI'
                    />
                    {paymentMethod === 'upi' && (
                      <div className={styles.payment_options__item_sub}>
                        <TextField
                          size='small'
                          label='Name'
                          value={upiDetails.name}
                          onChange={(e) =>
                            setUpiDetails({
                              ...upiDetails,
                              beneficiaryName: e.target.value,
                            })
                          }
                        />
                        <TextField
                          size='small'
                          label='Enter VPA'
                          value={upiDetails.vpa}
                          onChange={(e) =>
                            setUpiDetails({
                              ...upiDetails,
                              upi: e.target.value,
                            })
                          }
                        />
                      </div>
                    )}
                  </div>

                  <div className={styles.payment_options__item}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={paymentMethod === 'banktransfer'}
                          onChange={() => setPaymentMethod('banktransfer')}
                        />
                      }
                      label='Bank Details'
                    />
                    {paymentMethod === 'banktransfer' && (
                      <div className={styles.payment_options__item_sub}>
                        <TextField
                          size='small'
                          label='Beneficiary Name'
                          value={bankDetails.beneficiaryName}
                          onChange={(e) =>
                            setBankDetails({
                              ...bankDetails,
                              beneficiaryName: e.target.value,
                            })
                          }
                        />
                        <TextField
                          size='small'
                          label='Enter Bank Name'
                          value={bankDetails.bankName}
                          onChange={(e) =>
                            setBankDetails({
                              ...bankDetails,
                              bankName: e.target.value,
                            })
                          }
                        />
                        <TextField
                          size='small'
                          label='Enter Account Number'
                          value={bankDetails.bankAccountNumber}
                          onChange={(e) =>
                            setBankDetails({
                              ...bankDetails,
                              bankAccountNumber: e.target.value,
                            })
                          }
                        />
                        <TextField
                          size='small'
                          label='Re Enter Account Number'
                          value={bankDetails.bankAccountNumber}
                          onChange={(e) =>
                            setBankDetails({
                              ...bankDetails,
                              bankAccountNumber: e.target.value,
                            })
                          }
                        />
                        <TextField
                          size='small'
                          label='Enter IFSC Code'
                          value={bankDetails.ifscCode}
                          onChange={(e) =>
                            setBankDetails({
                              ...bankDetails,
                              ifscCode: e.target.value,
                            })
                          }
                        />
                      </div>
                    )}
                  </div>
                </>
              )}

              <Button variant='contained' onClick={handlePayment}>
                Submit
              </Button>
            </div>
            <div className={styles.payment_options__price_details}>
              <h4>Price Details</h4>
              <div className={styles.payment_options__item_sub}>
                Total Refund Amount:{' '}
                {returnData?.totalRefundAmount?.totalAmount}
              </div>
              <div className={styles.payment_options__item_sub}>
                Shipping Refund: {returnData?.totalRefundAmount?.shippingRefund}
              </div>
              <div className={styles.payment_options__item_sub}>
                Handling Refund: {returnData?.totalRefundAmount?.handlingRefund}
              </div>
              <div className={styles.payment_options__item_sub}>
                Payment Mode: {returnData?.paymentMode}
              </div>
              <ul>
                Per Item Refund: <li>Drug Code : Refund Amount</li>
                {returnData?.totalRefundAmount?.perItemRefunds.map((item) => (
                  <li key={item.drugCode}>
                    {item.drugCode} : {item.refundAmount}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CartDetailsReturn;
