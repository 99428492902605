import React from 'react';
import styles from './AcceptOrder.module.scss';
import { Button, TextField } from '@mui/material';
import OrImg from 'assets/icons/or.png';
import { autoAcceptOrder } from 'utils/api';
import { enqueueSnackbar } from 'notistack';

const AcceptOrder = () => {
  const AutoAccept = async (orderId) => {
    try {
      const data = {
        orderId: orderId,
      };
      const res = await autoAcceptOrder()(data);
      window.location.href = `/wms/picker/scanCode/${res?.data?.data?.orderId}`;
    } catch (e) {
      enqueueSnackbar(e?.response?.data?.error_message, { variant: 'error' });
      console.log(e);
    }
  };

  const userDetails = JSON.parse(
    localStorage.getItem('platinumRxDashboardUserDetail')
  );

  return (
    <div className={styles.AcceptOrderMain}>
      <div className={styles.upperSection}>
        <div className={styles.userDetailsSection}>
          <p>Name: {userDetails?.username}</p>
          <p>Role: Picker</p>
        </div>
      </div>
      <div className={styles?.lowerSection}>
        <div className={styles.searchBarSection}>
          <TextField
            fullWidth
            id='searchInput'
            variant='outlined'
            placeholder='Search Order ID'
            className={styles.searchInput}
            inputProps={{ maxLength: 6 }} // Restrict input to 6 digits
            onKeyPress={(e) => {
              // Allow only numeric keys (0-9) and prevent non-numeric input
              if (!/^[0-9]$/.test(e.key)) {
                e.preventDefault();
              }
              if (e.key === 'Enter') {
                const input = e.target.value; // Get the current input value
                AutoAccept(input); // Pass the current input value
              }
            }}
          />
          <Button
            sx={{ padding: '0 24px' }}
            variant='contained'
            className={styles.searchButton}
            onClick={() => {
              const input = document.querySelector('#searchInput');
              AutoAccept(input?.value);
            }}
          >
            Search
          </Button>
        </div>
        <img src={OrImg} alt='orImage' width='180px' />
        <Button
          onClick={() => AutoAccept()}
          variant='contained'
          className={styles.acceptOrderButton}
        >
          Accept Order
        </Button>
      </div>
    </div>
  );
};

export default AcceptOrder;
