'use client';
import React, { useState, useEffect } from 'react';
import {
  getActiveCart,
  setItemQuantity,
  initiateOrder,
  fetchUserDetails,
  uploadFiles,
} from 'utils/api';
import {
  Button,
  CircularProgress,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField,
  Typography,
  IconButton,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import styles from './order-details.module.scss';
import BillingDetails from 'containers/OrderPage/BillDetails/BillDetails';
import { useParams } from 'react-router-dom';
import CartItems from 'components/CartItems/index';
import useCartStore from 'store/cartStore';
import AddMedicineModal from 'containers/Polaris/Order/AddMedicineModal/AddMedicineModal';
import { enqueueSnackbar } from 'notistack';
import CouponSection from 'containers/Polaris/Order/CouponSection';
import UserDeliveryDetails from 'containers/OrderPage/UserDeliveryDetails';
import { CopyAll } from '@mui/icons-material';
import CoinAndCash from 'containers/OrderPage/CoinAndCash';
import UploadFile from 'containers/Polaris/Customer/UploadFiles';
import { useNavigate } from 'react-router-dom';

const EditOrderDetailsPage = () => {
  const [loading, setLoading] = useState(true);
  const [cartDetails, setCartDetails] = useState({});
  const priceDetails = useCartStore((state) => state.priceDetails);
  const cartItems = useCartStore((state) => state.cartItems);
  const [openMedicineModal, setOpenMedicineModal] = useState(false);
  const [addToCartLoading, setAddToCartLoading] = useState(false);
  const { userId } = useParams();
  const [userData, setUserData] = useState(null);
  const [paymentType, setPaymentType] = useState('COD');
  const [partialAmount, setPartialAmount] = useState(0);
  const [paymentLink, setPaymentLink] = useState('');
  const [increaseHeight, setIncreaseHeight] = useState(false);
  const [prescriptionFiles, setPrescriptionFiles] = useState([]);
  const ALLOWED_FILE_FORMATS = ['image/jpeg', 'image/png', 'application/pdf'];
  const MAX_FILE_SIZE_MB = 15;
  const navigate = useNavigate();

  const handleToggleHeight = () => {
    setIncreaseHeight(!increaseHeight);
  };

  const fetchActiveCart = async ({ userId }) => {
    try {
      setLoading(true);
      const payload = { userId };
      const resp = await getActiveCart()(payload);
      useCartStore.getState().setCartItems(resp.data.message);
      useCartStore.getState().setPriceDetails(resp.data.message?.priceDetails);
      useCartStore
        .getState()
        .setCoupon(resp.data.message?.couponDetails?.couponCode);
      setCartDetails(resp.data.message);
      if (
        resp.data.message?.priceDetails?.tokenAmount ||
        resp.data.message?.priceDetails?.loyaltyAmount
      ) {
        setPaymentType('Partial COD');
        setPartialAmount(
          resp.data.message?.priceDetails?.tokenAmount +
            resp.data.message?.priceDetails?.loyaltyAmount
        );
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const addToCart = async (item) => {
    try {
      const drugCode = item?.masterItemData?.master_drug_code;
      setAddToCartLoading(true);
      const payload = {
        userId: userId,
        cartId: cartItems?.cart?.cartId,
        data: {
          drugCode: drugCode,
          quantity: 1,
        },
      };
      const res = await setItemQuantity()(payload);
      if (res.data?.message?.error) {
        enqueueSnackbar(res?.data?.message?.error, { variant: 'error' });
        return;
      }
      const { userCart, priceDetails, couponDetails } = res?.data?.message;
      useCartStore.setState({
        cartItems: res?.data?.message,
        totalItems: userCart.length,
        priceDetails: priceDetails,
        coupon: couponDetails?.couponCode,
      });

      if (
        !res.data.message?.userCart.find(
          (drug) => drug?.itemCartData?.drugCode === drugCode
        )
      ) {
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
    } finally {
      setAddToCartLoading(false);
    }
  };

  const handleSearch = async () => {
    const valueToSearch = userId;

    if (!valueToSearch) {
      alert('Please enter a value to search.');
      return;
    }

    try {
      let response;
      response = await fetchUserDetails()(valueToSearch);

      const data = await response.data;
      setUserData(data);
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, {
        variant: 'error',
      });
    }
  };

  const getCurrentAddress = () => {
    return userData?.addresses.find(
      (address) => address.id === userData?.currentAddressId
    );
  };

  const getCurrentPatient = () => {
    return userData?.patients.find(
      (patient) => patient.id === userData?.currentPatientId
    );
  };

  useEffect(() => {
    handleSearch();
  }, []);

  const handleUploadPrescription = async () => {
    try {
      const formData = new FormData();
      prescriptionFiles.forEach((file) => {
        formData.append('files', file);
      });

      const payload = {
        data: formData,
        headers: {
          'user-id': userId,
          'Content-Type': 'multipart/form-data',
        },
      };
      await uploadFiles()(payload);
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
    }
  };

  const placeOrder = async (zeroValueOrder) => {
    try {
      const payload = {
        userId: userId,
        data: {
          paymentType: paymentType === 'Partial COD' ? 'PL' : paymentType,
          orderType: 'SEARCH',
          userName: `${userData?.firstName} ${userData?.lastName}`,
          patientName: getCurrentPatient()?.patient_name,
          patientAddress: getCurrentAddress()?.formatted_address,
          patientMobileNumber: userData?.phoneNumber,
          patientAge: String(getCurrentPatient()?.patient_age),
          patientGender: getCurrentPatient()?.patient_gender,
          pincode: Number(getCurrentAddress()?.pincode),
          city: getCurrentAddress()?.city,
          state: getCurrentAddress()?.state,
          placeZeroValueOrder: zeroValueOrder,
          tokenAmount:
            paymentType === 'Partial COD' ? Number(partialAmount) : null,
        },
      };
      if (prescriptionFiles.length > 0) {
        await handleUploadPrescription();
      }
      const res = await initiateOrder()(payload);
      if (res.data.message.paymentLink) {
        setPaymentLink(res.data.message.paymentLink);
      } else if (res.data.message.orderId) {
        enqueueSnackbar('Order placed successfully', { variant: 'success' });
        navigate(`/polaris/order-details/${res.data.message.orderId}`);
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchActiveCart({ userId });
  }, []);

  const handleFileSelect = (files) => {
    if (prescriptionFiles.length + files.length > 5)
      return enqueueSnackbar(
        'Too many images. You can upload a maximum of 5 images.',
        { variant: 'info' }
      );
    const newFiles = [];
    [...files].map((item) => {
      if (!ALLOWED_FILE_FORMATS.includes(item.type))
        return enqueueSnackbar('Invalid Format.', { variant: 'error' });
      if (item.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        return enqueueSnackbar('FileSize exceeded.', { variant: 'error' });
      }
      newFiles.push(item);
    });
    setPrescriptionFiles((prev) => [...prev, ...newFiles]);
  };

  const removeReasonImg = (e, item) => {
    e.stopPropagation();
    setPrescriptionFiles(
      prescriptionFiles.filter((file) => file.name != item.name)
    );
  };

  return (
    <div className={styles.order_details_page}>
      {loading ? (
        <div className={styles.loading_container}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className={styles.order_details_container}>
            <div className={styles.order_details}>
              <UserDeliveryDetails
                userData={userData}
                setUserData={setUserData}
                handleSearch={handleSearch}
                getCurrentAddress={getCurrentAddress}
                getCurrentPatient={getCurrentPatient}
                priceDetails={priceDetails}
              />
              <div className={styles.cart_items_container}>
                <div className={styles.cart_items_container__left}>
                  <CartItems
                    data={cartItems?.userCart}
                    userId={userId}
                    cartId={cartItems?.cart?.cartId}
                  />
                  <div
                    onClick={() => setOpenMedicineModal(true)}
                    className={styles.add_medicine_btn}
                  >
                    <AddCircleIcon
                      style={{ fontSize: '18px', color: '#FC5A5A' }}
                    />
                    <p>Add Medicines</p>
                  </div>
                </div>
                <div className={styles.bill_details_container}>
                  <BillingDetails
                    data={priceDetails}
                    order={cartDetails?.order}
                    oldPriceDetails={cartDetails?.oldPriceDetails}
                    paymentMode={cartDetails?.orderPriceDetailsV2?.paymentMode}
                  />
                </div>
              </div>
            </div>

            <div className={styles.right_container}>
              <CouponSection userId={userId} cartId={cartItems?.cart?.cartId} />
              <CoinAndCash
                userId={userId}
                increaseHeight={increaseHeight}
                handleToggleHeight={handleToggleHeight}
              />

              <div>
                <UploadFile
                  removeImg={removeReasonImg}
                  handleFileSelect={handleFileSelect}
                  prescriptionFiles={prescriptionFiles}
                  id='id'
                  uploading={false}
                  source='prescription'
                />
                <div className={styles.payment_details_container}>
                  {paymentLink && (
                    <div className={styles.payment_link_container}>
                      <Typography variant='body1' color='text.secondary'>
                        Payment Link: {paymentLink}
                      </Typography>
                      <IconButton
                        onClick={() => {
                          navigator.clipboard.writeText(paymentLink);
                          enqueueSnackbar('Payment link copied to clipboard', {
                            variant: 'success',
                          });
                        }}
                      >
                        <CopyAll />
                      </IconButton>
                    </div>
                  )}
                  <FormControl component='fieldset' sx={{ mb: 2 }}>
                    <FormLabel component='legend'>Payment Method</FormLabel>
                    <RadioGroup
                      row
                      name='payment-type'
                      value={paymentType}
                      onChange={(e) => setPaymentType(e.target.value)}
                    >
                      <FormControlLabel
                        value='COD'
                        control={<Radio />}
                        label='Cash on Delivery'
                      />
                      <FormControlLabel
                        value='PL'
                        control={<Radio />}
                        label='Payment Link'
                      />
                      <FormControlLabel
                        value='Partial COD'
                        control={<Radio />}
                        label='Partial COD'
                      />
                    </RadioGroup>
                  </FormControl>
                  {paymentType === 'Partial COD' && (
                    <TextField
                      label='Partial Amount'
                      value={partialAmount}
                      onChange={(e) => setPartialAmount(e.target.value)}
                    />
                  )}
                  <Button
                    variant='contained'
                    onClick={() => {
                      placeOrder(false);
                    }}
                  >
                    Place Order
                  </Button>
                  {paymentType === 'COD' && (
                    <Button
                      variant='contained'
                      onClick={() => {
                        placeOrder(true);
                      }}
                    >
                      Place 0 Value Order
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>

          {openMedicineModal && (
            <AddMedicineModal
              userId={userId}
              cartId={cartItems?.cart?.cartId}
              selectedData={cartItems?.userCart}
              open={openMedicineModal}
              setOpen={setOpenMedicineModal}
              addMedicine={addToCart}
              toggle={() => setOpenMedicineModal(false)}
              banned={false}
              substituteMedicine={false}
            />
          )}
        </>
      )}
    </div>
  );
};

export default EditOrderDetailsPage;
