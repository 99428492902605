import { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styles from './leadHistory.module.scss';
import MuiTabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
import {
  fetchUserDetails,
  getTaskDetails,
  sendWhatsappMessage,
  AcceptTask,
  getAdminTaskDetails,
} from 'utils/api';
import RenderActivityTimeline from 'containers/CRM Dashboard/Activity History';
import CustomerTasks from 'containers/CRM Dashboard/Tasks';
import HadAConversation from 'containers/CRM Dashboard/HadAConversation';
import { enqueueSnackbar } from 'notistack';
import { WhatsApp } from '@mui/icons-material';
import {
  Button,
  Menu,
  MenuItem,
  IconButton,
  Switch,
  Tooltip,
} from '@mui/material';
import { useAuth } from 'context/authContext';
import { AssignmentTurnedIn, DoNotDisturbAlt } from '@mui/icons-material';
import OrderHistory from 'containers/CRM Dashboard/OrderHistory';
import { chatTemplates } from 'utils/constants';

const LeadHistory = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [activeTab, setActiveTab] = useState('activity');
  const { userId, taskId } = useParams();
  const [taskDetails, setTaskDetails] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const profile = useAuth();
  const queryParams = new URLSearchParams(location.search);
  const isAdmin = queryParams.get('admin') === 'true';
  const [eventRanks, setEventRanks] = useState([
    50, 63, 127, 65, 70, 110, 124, 125, 27, 26, 52, 71, 73, 20, 28, 29, 80, 100,
    128, 79, 126, 64, 69, 30, 19, 67, 101, 25, 36, 37, 35,
  ]);
  const [showImportantOnly, setShowImportantOnly] = useState(true);

  const fetchTaskDetails = async () => {
    try {
      const data = {
        taskId: taskId,
      };
      let taskResponse;
      if (isAdmin) {
        taskResponse = await getAdminTaskDetails()(data);
      } else {
        taskResponse = await getTaskDetails()(data);
      }
      setTaskDetails(taskResponse?.data?.data);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchUserData = async () => {
    try {
      const userResponse = await fetchUserDetails()(userId);
      setUserDetails(userResponse?.data);
    } catch (err) {
      console.error(err);
    }
  };

  const sendChatMessage = async (value) => {
    try {
      const data = {
        userId: Number(userId),
        templateId: value,
      };
      await sendWhatsappMessage()(data);
      enqueueSnackbar('Message Sent Successfully', {
        variant: 'success',
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar(`error while sending message: ${err.data.message}`, {
        variant: 'error',
      });
    }
  };

  const handleAcceptTask = async (taskId, status) => {
    try {
      const data = {
        taskId: Number(taskId),
        status: status,
      };
      await AcceptTask()(data);
      enqueueSnackbar('Accepted Task!', {
        variant: 'success',
      });
    } catch (error) {
      console.error('Error accepting task:', error.response.data);
      enqueueSnackbar(error?.response?.data?.error_message, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserData();
    }
    if (taskId) {
      fetchTaskDetails();
    }
  }, [userId, taskId]);

  useEffect(() => {
    if (location.state?.activeTab) {
      setActiveTab(location.state.activeTab);
    }
  }, [location]);

  const renderUserHeader = () => (
    <div className={styles.headerMain}>
      <div className={styles.userHeader}>
        <div className={styles.userAvatar}>
          {userDetails?.firstName?.[0] || 'U'}
        </div>
        <div className={styles.userInfo}>
          <h2>
            {userDetails?.firstName || ''} {userDetails?.lastName || ''}
          </h2>
          <div className={styles.userMeta}>
            <span>Ph: {userDetails?.phoneNumber}</span>
          </div>
          {userDetails?.meta?.doNotCall && (
            <div className={styles.dnc}>
              <DoNotDisturbAlt /> <p>Do Not Call</p>
            </div>
          )}
        </div>
      </div>
      <div className={styles.taskDetails}>
        <p>
          Task type: <span>{taskDetails?.name}</span>
        </p>
        <p>
          Task id: <span>{taskId}</span>
        </p>
        {taskDetails?.status == 5 && (
          <IconButton
            color='primary'
            onClick={(e) => {
              e.stopPropagation(); // Prevent row click
              handleAcceptTask(taskId, 10);
            }}
            style={{
              padding: '4px 8px',
              borderRadius: '20px',
              border: '1px solid #1976d2',
              color: '#1976d2',
              fontSize: '12px',
            }}
          >
            <AssignmentTurnedIn />
            &nbsp;Accept Task
          </IconButton>
        )}
      </div>
    </div>
  );

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const toggleEventRanks = () => {
    if (showImportantOnly) {
      setEventRanks([]);
    } else {
      setEventRanks([
        50, 63, 127, 65, 70, 110, 124, 125, 27, 26, 52, 71, 73, 20, 28, 29, 80,
        100, 128, 79, 126, 64, 69, 30, 19, 67, 101, 25, 36, 37, 35,
      ]);
    }
    setShowImportantOnly(!showImportantOnly);
  };

  return (
    <div className={styles.container}>
      {renderUserHeader()}

      <div className={styles.headerContainer}>
        <div className={styles.tabsContainer}>
          <MuiTabs value={activeTab} onChange={handleTabChange}>
            <MuiTab label='Activity History' value='activity' />
            <MuiTab label='Disposition History' value='disposition' />
            <MuiTab label='Chat History' value='chat' />
            <MuiTab label='Call History' value='call' />
            <MuiTab label='Tasks' value='tasks' />
            <MuiTab label='Order History' value='orderHistory' />
            {taskDetails?.status === 10 ? (
              <MuiTab label='Had a Conversation' value='conversation' />
            ) : (
              <div
                onClick={() => {
                  if (taskDetails?.status === 5) {
                    enqueueSnackbar('First Accept the task ', {
                      variant: 'error',
                    });
                  } else {
                    enqueueSnackbar(
                      'Disposition can only be filled for IN_PROGRESS tasks',
                      {
                        variant: 'success',
                      }
                    );
                  }
                }}
                className={styles.conversationTab}
              >
                Had a Conversation
              </div>
            )}
          </MuiTabs>
        </div>

        <div>
          <Button
            variant='outlined'
            startIcon={<WhatsApp />}
            onClick={(e) => setAnchorEl(e.currentTarget)}
            sx={{
              color: '#4caf50',
              borderColor: '#4caf50',
              borderRadius: '8px',
              '&:hover': {
                borderColor: '#45a049',
                backgroundColor: 'rgba(76, 175, 80, 0.04)',
              },
            }}
          >
            Start Chat
          </Button>
          <Menu
            sx={{ zIndex: 900 }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            {chatTemplates.map((option) => (
              <MenuItem
                key={option.value}
                onClick={() => {
                  sendChatMessage(option.value);
                  setAnchorEl(null);
                }}
                sx={{ whiteSpace: 'normal', position: 'relative' }}
              >
                <Tooltip
                  title={option.message}
                  placement='top'
                  arrow
                  enterDelay={500}
                  leaveDelay={200}
                >
                  <span>{option.name}</span>
                </Tooltip>
              </MenuItem>
            ))}
          </Menu>
        </div>
      </div>

      {activeTab === 'activity' && (
        <div>
          <div className={styles.toggle}>
            <Switch checked={showImportantOnly} onChange={toggleEventRanks} />
            <div>{showImportantOnly ? 'Show All' : 'Show Only Imp'}</div>
          </div>
          <div className={styles.timeline}>
            <RenderActivityTimeline
              key={eventRanks}
              userId={userId}
              eventRanks={eventRanks}
              isActive={activeTab === 'activity'}
            />
          </div>
        </div>
      )}
      {activeTab === 'tasks' && (
        <div className={styles.timeline}>
          <CustomerTasks userId={userId} activeTab={activeTab} />
        </div>
      )}
      {activeTab === 'conversation' && (
        <div className={styles.timeline}>
          <HadAConversation
            userId={userId}
            taskId={taskId}
            activeTab={activeTab}
          />
        </div>
      )}
      {activeTab === 'disposition' && (
        <div className={styles.timeline}>
          <RenderActivityTimeline
            userId={userId}
            eventRanks={[124, 125, 129]}
            isActive={activeTab === 'disposition'}
          />
        </div>
      )}
      {activeTab === 'orderHistory' && (
        <div className={styles.timeline}>
          <OrderHistory
            userId={userId}
            isActive={activeTab === 'orderHistory'}
          />
        </div>
      )}
      {activeTab === 'chat' && (
        <div className={styles.timeline}>
          <RenderActivityTimeline
            userId={userId}
            eventRanks={[36, 37]}
            isActive={activeTab === 'chat'}
          />
        </div>
      )}
      {activeTab === 'call' && (
        <div className={styles.timeline}>
          <RenderActivityTimeline
            userId={userId}
            eventRanks={[26, 27, 28, 29, 31]}
            isActive={activeTab === 'call'}
          />
        </div>
      )}
    </div>
  );
};

export default LeadHistory;
