import { useState } from 'react';
import Modal from 'components/CustomElements/Modal.js';

import apiConfig from 'config.js';
import './AddMedicineModal.css';
import ComponentLoader from 'components/Loaders/ComponentLoader.js';
import { getAddtoCart } from 'components/AddToCart/utils';
import AddToCartDropdown from 'components/AddToCart/AddToCartDropdown';
import styles from './AddMedicineModal.module.scss';
import HowToShowDiscounterPrice from 'components/Common/howtoShowDiscountedPrice';
import HowToShowMrp from 'components/Common/howtoShowMrp';

let baseURL = apiConfig.apiUrl;

const AddMedicineModal = ({ open, setOpen, toggle, userId, cartId }) => {
  const [query, setQuery] = useState();
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addToCartloading, setAddToCartLoading] = useState(false);

  const handleSearch = async () => {
    const token = localStorage.getItem('platinumRxDashboardToken');
    if (!token) return;
    if (!query) {
      setResult([]);
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(`${baseURL}/pdp/fetchPlpInfo`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({ drugName: query }),
      });
      const result = await response.json();
      setResult(result.message);
      setQuery('');
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') handleSearch();
  };

  const getDiscountPercentage = (item) => {
    const itemTotalPrice = item?.masterItemData?.mrp;
    const itemTotalDiscountPrice = item?.masterItemData?.discounted_price;
    const discountPercentage = Math.round(
      ((itemTotalPrice - itemTotalDiscountPrice) / itemTotalPrice) * 100
    );
    return discountPercentage;
  };

  return (
    <Modal
      openModal={open}
      setOpenModal={setOpen}
      showCloseIcon={true}
      onClose={toggle}
    >
      <div style={{ padding: '16px' }}>
        <h3>Add Medicine</h3>
        <div className='medicine-container'>
          <input
            placeholder='Search by Name'
            style={{
              width: '90%',
              borderRadius: '15px',
              height: '25px',
              paddingLeft: '20px',
            }}
            type='text'
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onKeyDownCapture={(e) => handleKeyPress(e)}
          />
          <button className='button submit_btn' onClick={() => handleSearch()}>
            Search
          </button>
        </div>
        {loading ? (
          <ComponentLoader className='mTop50' />
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              width: '100%',
              overflowY: 'scroll',
              marginTop: '50px',
            }}
          >
            {result.length > 0 &&
              result.map((item) => {
                return (
                  <div style={{ marginBottom: '0px' }}>
                    <div className='single-medicine-card'>
                      <div style={{ position: 'relative' }}>
                        <div className='hero-img-container'>
                          <img
                            loading='lazy'
                            src={item.masterItemData.hero_image}
                          />
                        </div>
                        <div className='medicine-details'>
                          <div style={{ fontSize: '13px' }}>
                            Salt: {item.masterItemData.salt_composition}
                          </div>
                          <div className='medicine-name'>
                            Display name: {item.masterItemData.display_name}
                          </div>
                          <div>
                            <div>
                              Manufacturer:{' '}
                              {item.masterItemData.manufacturer_name}
                            </div>
                          </div>
                          <div className={styles.price_container}>
                            <div className={styles.price_container__info}>
                              <HowToShowDiscounterPrice
                                drugCode={
                                  item?.masterItemData?.master_drug_code
                                }
                                mrp={item?.masterItemData?.mrp}
                                discountedPrice={
                                  item?.masterItemData?.discounted_price
                                }
                                drugStock={item?.masterItemData?.drug_stock}
                                customClass={styles.price_container__discounted}
                                type='substitute'
                              />
                              <HowToShowMrp
                                mrp={item?.masterItemData?.mrp}
                                discountedPrice={
                                  item?.masterItemData?.discounted_price
                                }
                                drugStock={item?.masterItemData?.drug_stock}
                                customClass={styles.itemMrp}
                                extratext='MRP'
                              />
                              <div className={styles.discountPercentage}>
                                {getDiscountPercentage(item)}% OFF
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ position: 'absolute', right: '0px' }}>
                          <AddToCartDropdown
                            type='Cart'
                            userId={userId}
                            cartId={cartId}
                            smallButton={true}
                            showTrashButton={true}
                            drugCode={item.masterItemData.master_drug_code}
                            discountedPrice={
                              item.masterItemData.discounted_price
                            }
                            offerPrice={item.masterItemData.offer_price}
                            addToCartloading={addToCartloading}
                            setAddToCartLoading={setAddToCartLoading}
                            getAddtoCartDetails={getAddtoCart({
                              banned: item.masterItemData.banned,
                              substituteMedicine:
                                item.masterItemData.substitute_medicine,
                              type: 'PLP',
                              text: 'Add',
                              isMobile: true,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AddMedicineModal;
