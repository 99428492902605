import React, { useState } from 'react';
import styles from '../cartDetails.module.scss';
import { addDelimeter } from 'utils/commonFunctions';
import UploadedFiles from '../UploadFiles/uploaded-files';
import ReturnItem from 'components/ReturnItem';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/bundle';
import 'components/swiper/navigation.css';

const ItemDetails = ({ item, returnItem }) => {
  const {
    displayName = '',
    discountedPrice = 0,
    mrp = 0,
    heroImage = '',
    drugCategory = '',
    manufacturerName = '',
    packForm = '',
    packQuantityValueDecimal = 0,
    drugForm = '',
  } = item.masterDrugData || {};

  return (
    <div className={styles.item_container}>
      <div className={styles.content}>
        <div style={{ position: 'relative' }}>
          <div
            style={{
              backgroundImage: `url("${heroImage}")`,
            }}
            className={styles.item_img}
          />
          {drugCategory === 'RX' && <div className={styles.rx_icon}>Rx</div>}
        </div>
        <div className={styles.content_details}>
          <div className={styles.item_details}>
            <p className={styles.drug_name}>{displayName}</p>
            <div className={styles.img_container}>
              <div className={styles.manufacturer_name}>
                {manufacturerName?.split(' ')[0]}
              </div>
              <div className={styles.vertical_divider} />
              <div className={styles.manufacturer_name}>
                {packForm} of {packQuantityValueDecimal} {drugForm}
              </div>
            </div>
            <div className={styles.price_container}>
              <div className={styles.discounted_price}>
                ₹{addDelimeter(discountedPrice)}
              </div>
              <div className={styles.mrp_price}>₹{addDelimeter(mrp)}</div>
            </div>
          </div>
        </div>
        <div className={styles.return_qty_container}>
          <div>
            <p>
              Return Qty : <span>{item.quantity}</span>
            </p>
          </div>
          <div>
            <p style={{ color: 'green' }}>
              Approved Qty : <span>{item.approved}</span>
            </p>
          </div>
          <div>
            <p style={{ color: 'red' }}>
              Rejected for Damage : <span>{item.rejectedForDamage}</span>
            </p>
          </div>
          <div>
            <p style={{ color: 'red' }}>
              Rejected for BM: <span>{item.rejectedForBatchMismatch}</span>
            </p>
          </div>
          <div>
            <p style={{ color: 'red' }}>
              Rejected for Others : <span>{item.rejectedForOthers}</span>
            </p>
          </div>
        </div>

        {returnItem && (
          <div className={styles.item_qty}>
            <ReturnItem
              returnItemId={item?.id}
              itemQty={item.finalApprovedNumber}
              maxQuantity={item.quantity}
            />
          </div>
        )}
      </div>
      <div
        className={styles.card_container}
        style={{ position: 'relative', width: 'min-content' }}
      >
        <Swiper
          navigation={{
            clickable: true,
            nextEl: `#myswiper-next-${item?.id}`,
            prevEl: `#myswiper-prev-${item?.id}`,
          }}
          modules={[Navigation, Scrollbar]}
          grabCursor={true}
          slidesPerView='auto'
        >
          <SwiperSlide>
            <div className={styles.card}>
              {item?.imageUrls && (
                <div className={styles.uploaded_files_container}>
                  <p>User Uploaded Images</p>
                  <UploadedFiles
                    showText={false}
                    reasonImgs={item?.imageUrls.map((url) => ({
                      file_url: url,
                    }))}
                  />
                </div>
              )}
            </div>
          </SwiperSlide>
          {item?.warehouseImageUrls && (
            <SwiperSlide>
              <div className={styles.card}>
                <div className={styles.uploaded_files_container}>
                  <p>Warehouse Images</p>
                  <UploadedFiles
                    showText={false}
                    reasonImgs={item?.warehouseImageUrls.map((url) => ({
                      file_url: url,
                    }))}
                  />
                </div>
              </div>
            </SwiperSlide>
          )}
        </Swiper>
        <div className='my-swiper'>
          <div
            id={`myswiper-prev-${item?.id}`}
            className='swiper-button-prev'
          ></div>
          <div
            id={`myswiper-next-${item?.id}`}
            className='swiper-button-next'
          ></div>
        </div>
      </div>
      <div className={styles.note}>
        {item?.warehouseImageUrls ? (
          <p>*Swipe to see WareHouse Images</p>
        ) : (
          <p>*No warehouse images available </p>
        )}
      </div>
    </div>
  );
};

export default ItemDetails;
