import Modal from 'components/CustomElements/Modal';
import React from 'react';
import Trash from 'assets/icons/Trash';
import styles from './AddToCartDropdown.module.scss';

const QuantityModal = ({
  openDropdown,
  setOpenDropdown,
  addToCart,
  maxQuantity,
}) => {
  const numbers = Array.from(
    { length: maxQuantity + 1 || 31 },
    (_, index) => index
  );
  return (
    <Modal
      customClass='quantity_modal'
      openModal={openDropdown}
      setOpenModal={setOpenDropdown}
      showCloseIcon={true}
      iconStyle={{
        color: '#6D7478',
        zIndex: 99,
        position: 'absolute',
        right: 8,
        top: 8,
      }}
    >
      <>
        <div className={styles.modal_header}>
          <p className={styles.modal_header__text}>Select Quantity</p>
        </div>
        <div className={styles['num-dropdown']}>
          {numbers.map((num) => {
            return (
              <div
                key={num}
                onMouseDown={(e) => addToCart(e, num)}
                className={`quantity-select ${styles['quantity-list']}`}
              >
                {num > 0 ? num : <Trash />}
              </div>
            );
          })}
        </div>
      </>
    </Modal>
  );
};

export default QuantityModal;
