import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { getAWBList, markAsDispatched } from 'utils/api';
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';

const PrintAWBList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState('Blitz');

  const carriers = [
    'Ekart',
    'Delhivery',
    'Ekart Logistics',
    'Amazon Shipping',
    'Blitz',
    'Ats Amazon Shipping',
    'Bluedart',
    'Ecom Express',
    'BlueDart',
    'Dtdc',
    'DTDC',
    'false',
    'XpressBees',
    'India Post',
    'Xpress Bees',
    'Shadowfax',
  ];

  const columns = [
    {
      field: 'orderId',
      headerName: 'Order ID',
      width: 100,
    },
    {
      field: 'awbNumber',
      headerName: 'AWB Number',
      width: 150,
    },
    {
      field: 'carrier',
      headerName: 'Carrier',
      width: 120,
    },
  ];

  const rows = data?.orders?.map((order) => ({
    id: order.orderId,
    orderId: order.orderId,
    awbNumber: order.shipments[0]?.awbNumber || 'N/A',
    shipmentPartner: order.shipments[0]?.shipmentPartner || 'N/A',
    carrier: order.shipments[0]?.carrier || 'N/A',
    createdAt: order.createdAt,
    labelUrl: order.meta?.label_url || '#',
  }));

  const fetchAWBList = async () => {
    try {
      setLoading(true);
      const data = {
        carrier: selectedCarrier,
      };

      const response = await getAWBList()(data);
      if (response) {
        setData(response.data);
      }
    } catch (e) {
      console.log('error', e);
      enqueueSnackbar('Failed to fetch AWB list', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleDispatch = async () => {
    if (selectedRows.length === 0) {
      enqueueSnackbar('Please select orders to dispatch', {
        variant: 'warning',
      });
      return;
    }

    try {
      setLoading(true);
      const payload = {
        orderIds: selectedRows,
      };

      await markAsDispatched()(payload);
      enqueueSnackbar('Orders marked as dispatched successfully', {
        variant: 'success',
      });
      fetchAWBList();
      setSelectedRows([]);
    } catch (error) {
      console.error('Error dispatching orders:', error);
      enqueueSnackbar('Failed to mark orders as dispatched', {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAWBList();
  }, [selectedCarrier]);

  return (
    <div className='home'>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '1rem',
        }}
      >
        <h1>
          <b>AWB List</b>
        </h1>
        <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id='carrier-select-label'>Select Carrier</InputLabel>
            <Select
              labelId='carrier-select-label'
              value={selectedCarrier}
              label='Select Carrier'
              onChange={(e) => {
                setSelectedCarrier(e.target.value);
                setSelectedRows([]); // Clear selections when carrier changes
              }}
            >
              {carriers.map((carrier) => (
                <MenuItem key={carrier} value={carrier}>
                  {carrier}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant='contained'
            color='primary'
            onClick={handleDispatch}
            disabled={loading || selectedRows.length === 0}
          >
            Mark as Dispatched ({selectedRows.length})
          </Button>
        </div>
      </div>
      <DataGrid
        rows={rows}
        columns={columns}
        autoHeight
        pagination={false}
        slots={{ toolbar: GridToolbar }}
        loading={loading}
        checkboxSelection
        disableRowSelectionOnClick={false}
        onRowSelectionModelChange={(newSelection) => {
          setSelectedRows(newSelection);
        }}
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#f5f5f5',
            fontWeight: 'bold',
            position: 'sticky',
            top: 0,
            zIndex: 1,
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
          },
        }}
      />
    </div>
  );
};

export default PrintAWBList;
