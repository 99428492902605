import React from 'react';
import styles from '../order-details.module.scss';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import Dates from '../Dates';
import SavingSection from '../SavingSection';

const OrderInfo = ({ orderDetails, trueremark, shipment, warehouseOrder }) => {
  const statusId = orderDetails?.order?.statusId;
  const navigate = useNavigate();
  const zohoStatus = orderDetails?.order?.zohoOrderCreationStatus;

  const handleDownloadOrderEditedPDF = () => {
    window.open(orderDetails?.order?.substitute_info_pdf, '_blank');
  };

  const getZohoStatusText = (zohoOrderCreationStatus) => {
    const statusText = {
      0: 'Zoho Sales Order Status Pending',
      1: 'Zoho Sales Order Status Success',
      2: 'Zoho Sales Order Status Failed',
    };
    return statusText[`${zohoOrderCreationStatus}`];
  };

  const handleTrack = (e) => {
    e.preventDefault();
    window.open(orderDetails?.order?.meta?.tracking_link, '_blank');
  };

  const handleEditOrder = async (orderId) => {
    try {
      navigate(`/polaris/edit-order-details/${orderId}`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.order_info}>
      <div className={styles.order_info__top}>
        <div className={styles.order_info__status}>
          <p className={statusId === 0 ? styles.order_info__pending : ''}>
            {warehouseOrder
              ? orderDetails?.order?.status
              : `Order id: ${orderDetails?.order?.orderId}`}
          </p>
          {orderDetails?.order?.meta?.hyper_local_order && (
            <p className={styles.order_info__hyper_local}>Hyper Local</p>
          )}
        </div>
        <div className={styles.order_info__btn_container}>
          {orderDetails?.order?.meta?.tracking_link && (
            <Button variant='contained' onClick={(e) => handleTrack(e)}>
              Track
            </Button>
          )}
          {statusId <= 43 && (
            <Button
              variant='outlined'
              onClick={() => handleEditOrder(orderDetails?.order?.orderId)}
            >
              Edit
            </Button>
          )}
          <Button
            variant='outlined'
            onClick={() =>
              navigate(
                `/polaris/customer?userId=${orderDetails?.order?.userId}`
              )
            }
          >
            Visit User
          </Button>
        </div>
      </div>
      {orderDetails?.order?.status === 'Cancelled' && (
        <p className={styles.order_info__reason}>
          Reason: {orderDetails?.order?.orderRemarks || trueremark}
        </p>
      )}
      {orderDetails?.order?.meta?.internal_user_name ? (
        <p className={styles.order_info__order_id}>
          Placed by: {orderDetails?.order?.meta?.internal_user_name}
        </p>
      ) : (
        <p className={styles.order_info__order_id}>
          Placed by: self via {orderDetails?.orderDetails?.source}
        </p>
      )}
      <Dates
        statusId={orderDetails?.order?.statusId}
        placedOn={orderDetails?.order?.createdAt}
        tentativeDelivery={orderDetails?.order?.orderFulfillmentDate}
      />

      <div className={styles.zoho_status}>
        <p
          style={{
            color:
              zohoStatus == 0 ? 'orange' : zohoStatus == 1 ? 'green' : 'red',
          }}
        >
          {getZohoStatusText(zohoStatus)}
        </p>
      </div>
    </div>
  );
};

export default OrderInfo;
