'use client';
import React, { useState, useEffect, useCallback } from 'react';
import { getReturnItems, updateReturnStatus } from 'utils/api';
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import styles from './return-details.module.scss';
import CartDetailsReturnView from 'containers/OrderDetails/CartDetailsReturnView';
import { useParams, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import TimeStamp from 'containers/OrderDetails/Status/TimeStamps';
import useReturnStore from 'store/returnStore';

const ReturnDetails = () => {
  const [loading, setLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState('');
  const { returnId } = useParams();
  const navigate = useNavigate();
  const [remark, setRemark] = useState('');
  const returnData = useReturnStore((state) => state.returnData);
  const setReturnData = useReturnStore((state) => state.setReturnData);

  const options = [
    { label: 'Approve and Inititate Refund', value: 250 },
    { label: 'Cancel Return', value: 280 },
    { label: 'Initiate Return Pickup', value: 220 },
  ];

  const fetchreturnData = useCallback(async () => {
    try {
      const payload = { returnId };
      const resp = await getReturnItems()(payload);
      setReturnData(resp.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [returnId]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      const payload = {
        returnRequestId: Number(returnId),
        finalStatus: selectedOption,
        ...(remark !== '' ? { remark } : {}),
      };
      await updateReturnStatus()(payload);
      enqueueSnackbar('Return status updated successfully', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Failed to update return status', {
        variant: 'error',
      });
    }
  };

  const handleOrderClick = () => {
    navigate(`/polaris/order-details/${returnData?.returnRequest?.orderId}`);
  };

  useEffect(() => {
    fetchreturnData();
  }, [fetchreturnData]);

  return (
    <div className='home'>
      <h1>Return Details</h1>
      <div>
        {loading ? (
          <div className={styles.loading_container}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className={styles.order_id_container}>
              <h4 className={styles.order_id}>
                Order ID: {returnData?.returnRequest?.orderId}
              </h4>
              <Button
                variant='contained'
                color='primary'
                onClick={handleOrderClick}
              >
                Go to Order
              </Button>
            </div>
            <div className={styles.order_details_container}>
              <div className={styles.order_details}>
                <CartDetailsReturnView orderItems={returnData?.returnItems} />
              </div>
              <div className={styles.right_container}>
                <div className={styles.bill_details_container}>
                  <p className={styles.bill_details_container__heading}>
                    Bill Details
                  </p>
                  <div className={styles.bill_details_container__content}>
                    <div
                      className={styles.bill_details_container__content_item}
                    >
                      <p>Refund Amount</p>
                      <p>
                        {returnData?.returnRequest?.refundAmount
                          ? returnData?.returnRequest?.refundAmount
                          : '0'}
                      </p>
                    </div>
                    <div
                      className={styles.bill_details_container__content_item}
                    >
                      <p>Shipping Refund</p>
                      <p>
                        {returnData?.returnRequest?.shippingRefund
                          ? returnData?.returnRequest?.shippingRefund
                          : '0'}
                      </p>
                    </div>
                    <div
                      className={styles.bill_details_container__content_item}
                    >
                      <p>Handling Refund</p>
                      <p>
                        {returnData?.returnRequest?.handlingRefund
                          ? returnData?.returnRequest?.handlingRefund
                          : '0'}
                      </p>
                    </div>
                    <div
                      className={styles.bill_details_container__content_item}
                    >
                      <p>Transfer Mode</p>
                      <p>
                        {returnData?.returnRequest?.transferMode
                          ? returnData?.returnRequest?.transferMode
                          : 'N/A'}
                      </p>
                    </div>
                    {returnData?.returnRequest?.transferMode === 'upi' && (
                      <div
                        className={styles.bill_details_container__content_item}
                      >
                        <p>UPI ID</p>
                        <p>{returnData?.returnRequest?.paymentDetails?.upi}</p>
                      </div>
                    )}
                    {returnData?.returnRequest?.transferMode ===
                      'banktransfer' && (
                      <div
                        className={styles.bill_details_container__content_item}
                      >
                        <p>Bank Name</p>
                        <p>
                          {returnData?.returnRequest?.paymentDetails?.bankName}
                        </p>
                      </div>
                    )}
                    {returnData?.returnRequest?.transferMode ===
                      'banktransfer' && (
                      <div
                        className={styles.bill_details_container__content_item}
                      >
                        <p>IFSC Code</p>
                        <p>
                          {returnData?.returnRequest?.paymentDetails?.ifscCode}
                        </p>
                      </div>
                    )}
                    {returnData?.returnRequest?.transferMode ===
                      'banktransfer' && (
                      <div
                        className={styles.bill_details_container__content_item}
                      >
                        <p>Beneficiary Name</p>
                        <p>
                          {
                            returnData?.returnRequest?.paymentDetails
                              ?.beneficiaryName
                          }
                        </p>
                      </div>
                    )}
                    {returnData?.returnRequest?.transferMode ===
                      'banktransfer' && (
                      <div
                        className={styles.bill_details_container__content_item}
                      >
                        <p>Bank Account Number</p>
                        <p>
                          {
                            returnData?.returnRequest?.paymentDetails
                              ?.bankAccountNumber
                          }
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.bill_details_container}>
                  <p className={styles.bill_details_container__heading}>
                    Return Details
                  </p>
                  <div className={styles.bill_details_container__content}>
                    <div
                      className={styles.bill_details_container__content_item}
                    >
                      <p>Amount to be credited in Source </p>
                      <p>
                        {returnData?.returnRequest?.totalSource
                          ? returnData?.returnRequest?.totalSource
                          : '0'}
                      </p>
                    </div>
                    <div
                      className={styles.bill_details_container__content_item}
                    >
                      <p>Amount to be credited in Prx Cash</p>
                      <p>
                        {returnData?.returnRequest?.totalCash
                          ? returnData?.returnRequest?.totalCash
                          : '0'}
                      </p>
                    </div>
                    <div
                      className={styles.bill_details_container__content_item}
                    >
                      <p>Amount to be credited in Prx Coins</p>
                      <p>
                        {returnData?.returnRequest?.totalCoin
                          ? returnData?.returnRequest?.totalCoin
                          : '0'}
                      </p>
                    </div>
                  </div>
                </div>
                <div className={styles.status_container}>
                  {returnData?.returnRequest?.trackingDetails?.map(
                    (item, index) => {
                      return <TimeStamp key={index} item={item} />;
                    }
                  )}
                </div>
                <div className={styles.submit_container}>
                  <FormControl>
                    <InputLabel id='select-status-label'>
                      Select Status
                    </InputLabel>
                    <Select
                      labelId='select-status-label'
                      id='select-status'
                      value={selectedOption}
                      input={
                        <OutlinedInput
                          id='select-status'
                          label='Select Status'
                        />
                      }
                      onChange={handleOptionChange}
                    >
                      {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {(selectedOption == 250 || selectedOption == 280) && (
                    <TextField
                      label='Remark'
                      value={remark}
                      onChange={(e) => setRemark(e.target.value)}
                    />
                  )}
                  <Button
                    onClick={handleSubmit}
                    disabled={selectedOption === ''}
                    variant='contained'
                    color='primary'
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ReturnDetails;
