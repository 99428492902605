import React, { useEffect, useState, useRef } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { getOrderDetailsWithBoxCode, updateOrderStatus } from 'utils/api';
import { enqueueSnackbar } from 'notistack';

const ScanForDispatch = () => {
  const [boxCode, setBoxCode] = useState('');
  const [orderId, setOrderId] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState();
  const [showDispatch, setShowDispatch] = useState(false);
  const boxCodeRef = useRef(null); // Added useRef for text field focus

  const handleBoxCodeChange = (e) => {
    const value = e.target.value;
    setBoxCode(value);
  };

  const handleSubmit = async () => {
    try {
      const data = {
        barcodeSerial: boxCode,
      };
      const response = await getOrderDetailsWithBoxCode()(data);
      if (response.data.data.order.statusId < 61) {
        enqueueSnackbar(
          'Order need to be in QC Done status to get manifested',
          { variant: 'error' }
        );
        return;
      } else if (response.data.data.order.statusId > 61) {
        enqueueSnackbar('Order is already manifested', { variant: 'error' });
        return;
      }
      const labelPrintUrl = response?.data?.data?.order?.meta.label_url;
      setOrderId(response.data.data.order.orderId);
      const printWindow = window.open(labelPrintUrl, '_blank');
      printWindow.onload = function () {
        printWindow.print();
      };

      enqueueSnackbar('done', { variant: 'success' });
      setBoxCode('');
      setShowDispatch(true);
    } catch (error) {
      enqueueSnackbar(error.response.data.message, { variant: 'error' });
    }
  };

  const markAsManifested = async () => {
    try {
      const data = {
        orderId: orderId,
        finalStatus: 64,
        opsUsername: loggedInUser['email'],
      };
      await updateOrderStatus()(data);
      enqueueSnackbar('Order is marked as Manifested', { variant: 'success' });
      setShowDispatch(false);
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.error_message, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    const userDetails = JSON.parse(
      localStorage.getItem('platinumRxDashboardUserDetail')
    );
    setLoggedInUser(userDetails);
    boxCodeRef.current?.focus(); // Focus on text field on mount
  }, []);

  return (
    <Box className={'main'} sx={{ p: 3 }}>
      {!showDispatch ? (
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            label='Box Code'
            value={boxCode}
            onChange={handleBoxCodeChange}
            helperText={'Format: PRX1234'}
            fullWidth
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSubmit();
              }
            }}
            inputRef={boxCodeRef} // Added inputRef for focus
          />
          <Button
            disabled={boxCode == ''}
            variant='contained'
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant='h6'>Box Code: {boxCode}</Typography>
          <Button
            variant='contained'
            color='primary'
            onClick={markAsManifested}
          >
            Mark As Manifested
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ScanForDispatch;
