import React, { useState } from 'react';
import styles from './ReturnItem.module.scss';
import { CircularProgress, Typography } from '@mui/material';
import { setReturnItemQuantity } from 'utils/api';
import { ReactComponent as RightArrow } from 'assets/icons/RightArrow.svg';
import Button from '@mui/material/Button';
import QuantityModal from 'components/AddToCart/quantityModal';
import { enqueueSnackbar } from 'notistack';
import useReturnStore from 'store/returnStore';

const ReturnItem = ({ returnItemId, extraText, itemQty, maxQuantity }) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [returnItemloading, setreturnItemLoading] = useState(false);
  const setReturnData = useReturnStore((state) => state.setReturnData);

  const returnItem = async (e, increaseQuantityBy = 1) => {
    e.preventDefault();
    try {
      setreturnItemLoading(true);
      const payload = {
        data: {
          finalApprovedNumber: increaseQuantityBy,
        },
        returnItemId: returnItemId,
      };
      const res = await setReturnItemQuantity()(payload);
      setReturnData(res?.data);
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    } finally {
      setreturnItemLoading(false);
      setOpenDropdown(false);
    }
  };

  const handleDropdown = () => {
    setOpenDropdown((prev) => !prev);
  };

  return (
    <>
      {itemQty ? (
        <div className={styles.add_btn}>
          <p className={styles.extraText}>{extraText ? extraText : null}</p>
          <div
            onClick={(e) => e.preventDefault()}
            className={styles['change-cart-btn']}
          >
            <div
              style={{ width: '36px', height: '24px' }}
              className={styles.quantity}
            >
              {itemQty}
            </div>
            <QuantityModal
              openDropdown={openDropdown}
              setOpenDropdown={setOpenDropdown}
              addToCart={returnItem}
              maxQuantity={maxQuantity}
            />
            <div
              onClick={() => {
                handleDropdown();
              }}
              className={`${styles.arrow} open-dropdown`}
            >
              <RightArrow />
            </div>
          </div>
        </div>
      ) : (
        <Button
          startIcon={
            returnItemloading && (
              <CircularProgress size={'20px'} style={{ color: '#fff' }} />
            )
          }
          variant='contained'
          onClick={(e) => {
            returnItem(e, 1);
          }}
          disabled={returnItemloading}
        >
          <Typography variant='p'>return Item</Typography>
        </Button>
      )}
      {openDropdown && (
        <div
          onClick={() => setOpenDropdown(false)}
          className={styles.overlay}
        ></div>
      )}
    </>
  );
};

export default ReturnItem;
