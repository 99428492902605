import React, { useEffect, useState } from 'react';
import {
  StyledAccordion,
  StyledAccordionSummary,
} from 'components/CustomElements/Accordion';
import styles from './IssueOrderDetails.module.scss';
import CartDetails from 'containers/QCDetails/CartDetails';
import { useParams } from 'react-router-dom';
import { getOrderDetails } from 'utils/api';
import { enqueueSnackbar } from 'notistack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PickSKUIndex from 'containers/WMS/Picker/PickSKU';
import Header from 'containers/WMS/Picker/PickSKU/Header';

const IssueOrderDetails = () => {
  const { orderId } = useParams();
  const [details, setDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [savedRemarks, setSavedRemarks] = useState({});
  const [batchRows, setBatchRows] = useState();
  const [remarks, setRemarks] = useState({});
  const [isVerified, setIsVerified] = useState(false);

  const getDetails = async (orderId) => {
    setLoading(true);
    try {
      const data = {
        orderId: orderId,
        params: { warehouseLocations: true },
      };
      const response = await getOrderDetails()(data);

      response?.data?.orderItems?.sort((a, b) => {
        const locA =
          a.itemCatalogData?.warehouseLocations?.[0]?.shortHandLocation || '';
        const locB =
          b.itemCatalogData?.warehouseLocations?.[0]?.shortHandLocation || '';
        return locA.localeCompare(locB);
      });
      setDetails(response?.data);

      if (
        response?.data?.orderItems?.[0]?.itemCatalogData?.warehouseLocations
      ) {
        const locations =
          response.data.orderItems[0].itemCatalogData.warehouseLocations;
        setBatchRows(
          locations.map((loc, index) => ({
            id: index,
            batch: loc.batch,
            availableQty: loc.quantity,
            pickedQty: 0,
            mrp: response.data.orderItems[0].itemCartData.unitMrp,
            expiry: loc.expiresOn,
          }))
        );
      }

      const newRemarks = {};
      response?.data?.orderItems?.forEach((item) => {
        const issues = response?.data?.orderRemarks.filter(
          (remark) => remark.masterDrugCode === item.itemCartData?.drugCode
        );
        if (issues.length > 0) {
          newRemarks[item.itemCartData?.drugCode] = {
            id: issues[0].id,
            issueReason: issues[0].remark, // Assuming you want the first issue reason
            drugName: item.itemCartData?.drugName,
          };
        }
      });
      setRemarks(newRemarks);
      setSavedRemarks(newRemarks);
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Error fetching order details', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDetails(orderId);
  }, [orderId]);

  return (
    <div className='home'>
      <Header
        isVerified={isVerified}
        setIsVerified={setIsVerified}
        boxCode={details?.order?.barcodeSerial}
        rackCode={details?.order?.rackIdentifier}
        orderId={orderId}
        navigation={false}
      />
      <StyledAccordion className={styles.accordion}>
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
          Order Details
        </StyledAccordionSummary>
        <div className={styles.details_container}>
          <CartDetails hideHeader={true} orderDetails={details} location='tl' />
        </div>
      </StyledAccordion>
      <div className={isVerified ? '' : styles.unfocusedContent}>
        <PickSKUIndex
          tl={true}
          orderId={orderId}
          boxCode={details?.order?.barcodeSerial}
          getDetails={getDetails}
          details={details}
          setDetails={setDetails}
          savedRemarks={savedRemarks}
          setBatchRows={setBatchRows}
          remarks={remarks}
          setRemarks={setRemarks}
        />
      </div>
    </div>
  );
};

export default IssueOrderDetails;
