import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Autocomplete, TextField, FormControl, Grid } from '@mui/material';
import { getReturnRequests } from 'utils/api';
import dayjs from 'dayjs';
import styles from './ReturnAdmin.module.scss';
import CustomDateRangePicker from 'components/CustomElements/CustomDatepicker';
import PageLoader from 'components/Loaders/PageLoader';
import RefreshIcon from '@mui/icons-material/RefreshRounded';
import useDebounce from 'components/hooks/useDebounce';
import { returnStatus } from 'utils/constants';
import SaveIcon from '@mui/icons-material/CopyAll';
import { enqueueSnackbar } from 'notistack';
import { useApp } from 'context/AppContext';
const ReturnAdminPage = () => {
  const [returns, setReturns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [rowCount, setRowCount] = useState(0);
  const [dataGridLoading, setDataGridLoading] = useState(false);
  const { returnStatusParams, setReturnStatusParams } = useApp();
  const [orderIds, setOrderIds] = useState('');
  const debouncedOrderIds = useDebounce(orderIds, 300);

  const navigate = useNavigate();

  const handlePaginationModelChange = (newModel) => {
    setPage(newModel.page);
    setPageSize(newModel.pageSize);
  };

  const fetchReturns = async (page, pageSize) => {
    setDataGridLoading(true);
    try {
      const data = {
        orderIds: debouncedOrderIds,
        returnStatusIds:
          returnStatusParams.length > 0
            ? returnStatusParams.map(Number).join(',')
            : undefined,
        startDate: fromDate ? dayjs(fromDate).format('YYYY-MM-DD') : undefined,
        endDate: toDate ? dayjs(toDate).format('YYYY-MM-DD') : undefined,
        skip: page * pageSize,
        limit: pageSize,
      };

      const response = await getReturnRequests()(data);

      setReturns(response.data);
      setRowCount(response?.data?.count || 100);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    } finally {
      setLoading(false);
      setDataGridLoading(false);
    }
  };

  useEffect(() => {
    fetchReturns(page, pageSize);
  }, [
    returnStatusParams,
    fromDate,
    toDate,
    page,
    pageSize,
    rowCount,
    debouncedOrderIds,
  ]);

  const handleRowClick = (params) => {
    const returnId = params.row.id;
    navigate(`/polaris/return-details/${returnId}`);
  };

  const handleCopy = (params) => {
    navigator.clipboard.writeText(params.row.orderId);
    enqueueSnackbar(`Order ID ${params.row.orderId} copied to clipboard`, {
      variant: 'success',
    });
  };

  const rows = returns.map((returnItem) => ({
    id: returnItem.id,
    orderId: returnItem.orderId,
    returnStatus: returnStatus[returnItem.returnStatus],
    refundAmount: returnItem.refundAmount,
    shippingRefund: returnItem.shippingRefund,
    handlingRefund: returnItem.handlingRefund,
    transferMode: returnItem.transferMode,
    createdAt: new Date(returnItem.createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }),
  }));

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'orderId', headerName: 'Order ID', width: 120 },
    {
      field: 'copy',
      headerName: '',
      width: 1,
      type: 'actions',
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label='Save'
            onClick={() => handleCopy(params)}
            color='primary'
          />,
        ];
      },
    },
    { field: 'returnStatus', headerName: 'Return Status', width: 120 },
    { field: 'refundAmount', headerName: 'Refund Amount', width: 120 },
    { field: 'shippingRefund', headerName: 'Shipping Refund', width: 120 },
    { field: 'handlingRefund', headerName: 'Handling Refund', width: 120 },
    { field: 'transferMode', headerName: 'Transfer Mode', width: 120 },
    { field: 'createdAt', headerName: 'Created At', width: 180 },
  ];

  return (
    <>
      {loading ? (
        <PageLoader />
      ) : (
        <div className='home'>
          <h1>Return Admin</h1>
          <div
            className={styles.filtersContainer}
            style={{ display: 'flex', gap: '24px', alignItems: 'center' }}
          >
            <FormControl variant='outlined' sx={{ minWidth: 300 }}>
              <Autocomplete
                id='status-autocomplete'
                multiple
                options={Object.values(returnStatus)}
                value={
                  returnStatusParams.map((status) => returnStatus[status]) || []
                }
                onChange={(event, newValue) => {
                  const selectedStatusIds = newValue
                    .map((name) => {
                      const selectedStatus = Object.keys(returnStatus).find(
                        (key) => returnStatus[key] === name
                      );
                      return selectedStatus ? parseInt(selectedStatus) : null;
                    })
                    .filter((id) => id !== null);
                  setReturnStatusParams(selectedStatusIds);
                }}
                renderInput={(params) => (
                  <TextField {...params} label='Status' variant='outlined' />
                )}
              />
            </FormControl>
            <FormControl variant='outlined' sx={{ minWidth: 300 }}>
              <TextField
                label='Order ID'
                value={orderIds}
                onChange={(e) => setOrderIds(e.target.value)}
              />
            </FormControl>
            <Grid fullWidth>
              <CustomDateRangePicker
                setStartDate={setFromDate}
                setEndDate={setToDate}
                startDate={fromDate}
                endDate={toDate}
              />
            </Grid>
            <div>
              <div
                className={styles.refreshIcon}
                onClick={() => fetchReturns(page, pageSize)}
              >
                <RefreshIcon />
              </div>
              <div className={styles.Count}>Total Count: {rowCount}</div>
            </div>
          </div>

          <DataGrid
            rows={rows}
            columns={columns}
            autoHeight
            initialState={{
              pagination: {
                paginationModel: { pageSize: 25, page: 0 },
              },
            }}
            loading={dataGridLoading}
            paginationMode='server'
            pageSizeOptions={[10, 25, 50, 100]}
            paginationModel={{ page, pageSize }}
            onPaginationModelChange={handlePaginationModelChange}
            rowCount={rowCount}
            disableSelectionOnClick
            onRowClick={handleRowClick}
            sx={{
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#f5f5f5',
                fontWeight: 'bold',
              },
              '& .MuiDataGrid-footerContainer': {
                backgroundColor: '#f5f5f5',
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export default ReturnAdminPage;
