import React from 'react';
import styles from './EditOrderDetails.module.scss';

const EditOrderDetails = ({ data }) => {
  const getKey = (key, value) => {
    return key
      .replace(/_/g, ' ')
      .replace(/([A-Z])/g, ' $1')
      .trim()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const getValue = (key, value) => {
    if (key.includes('pdf')) {
      return (
        <a
          className={styles.edit_details__items__link}
          href={value}
          target='_blank'
          rel='noreferrer'
        >
          View PDF
        </a>
      );
    } else if (value == true) {
      return 'Yes';
    } else if (value == false) {
      return 'No';
    }
    return value;
  };

  return (
    <div className={styles.edit_details}>
      <p className={styles.edit_details__title}>Edit Order Details</p>
      <div className={styles.edit_details__items}>
        {Object.entries(data || {}).map(
          ([key, value]) =>
            value && (
              <p className={styles.edit_details__items__name}>
                {getKey(key)}:{' '}
                <span className={styles.edit_details__items__name__label}>
                  {getValue(key, value)}
                </span>
              </p>
            )
        )}
      </div>
    </div>
  );
};

export default EditOrderDetails;
