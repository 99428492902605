'use client';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { cancelOrder, getOrderDetails } from 'utils/api';
import {
  Button,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  OrderAddress,
  CartDetails,
  SavingSection,
  OrderStatus,
  OrderInfo,
  Dates,
  BillingAccordion,
} from 'containers/OrderDetails/indexing';
import CancelDrawer from 'components/Drawers/CancelDrawer';
import styles from './order-details.module.scss';
import { ReactComponent as Cash } from 'assets/icons/dollar-cash.svg';
import BillingDetails from 'containers/OrderPage/BillDetails/BillDetails';
import { useParams } from 'react-router-dom';
import PrescriptionContainer from 'components/PrescriptionContainer/PrescriptionContainer';
import CartDetailsReturn from 'containers/OrderDetails/CartDetailsReturn';
import PendingOrders from 'containers/OrderDetails/PendingOrders';

const OrderDetailsPage = () => {
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [orderDetails, setOrderDetails] = useState({});
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [trueremark, setTrueRemark] = useState();
  const [cancelbutton, setCancelButton] = useState();
  const [warehouseOrder, setWarehouseOrder] = useState(-1);
  const statusId = orderDetails?.order?.statusId;
  const { orderId } = useParams();
  const [showPrescription, setShowPrescription] = useState(false);
  const [showReturn, setShowReturn] = useState(false);
  const returnContainerRef = useRef(null);
  const zohoStatus = orderDetails?.order?.zohoOrderCreationStatus;

  const fetchOrderDetails = useCallback(async () => {
    try {
      const payload = { orderId };
      const resp = await getOrderDetails()(payload);
      const user_id = resp.data.order.userId;
      const allowedStatusIds = [90, 15, 25, 33, 31, 1, 50, 32];
      setOrderDetails(resp.data);
      localStorage.setItem('userID', user_id);
      setLoading(false);
      setCancelButton(allowedStatusIds.includes(statusId));
    } catch (error) {
      setLoading(false);
    }
  }, [orderId]);

  useEffect(() => {
    fetchOrderDetails();
  }, [fetchOrderDetails]);

  const handleSubmitReason = async (reason, id) => {
    const payload = {
      orderId: id,
      remarks: reason,
      orderType: orderDetails?.order?.split ? 'WarehouseOrder' : 'Order',
    };
    try {
      const res = await cancelOrder()(payload);
      const {
        data: { data: orderData },
      } = res;
      setOrderDetails(orderData);
      setTrueRemark(reason);
      setCancelButton(false);
    } catch (error) {
      console.log('Error reason:', error);
    }
    handleDrawerClose();
  };

  const handleDrawerClose = (warehouseItemIndex) => {
    warehouseItemIndex && setWarehouseOrder(warehouseItemIndex);
    setDrawerOpen(false);
  };

  const handleCancelOrder = (index) => {
    setWarehouseOrder(index);
    setDrawerOpen(true);
  };

  const handleGetPrescriptions = async () => {
    setShowPrescription(true);
  };

  const handleShowReturn = () => {
    setShowReturn(true);
    setTimeout(() => {
      returnContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  return (
    <div className={styles.order_details_page}>
      {loading ? (
        <div className={styles.loading_container}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className={styles.order_details_container}>
            <div className={styles.order_details}>
              {!orderDetails?.warehouseOrders ? (
                <>
                  <div className={styles.billing_address_container}>
                    {zohoStatus == 2 && <PendingOrders orderId={orderId} />}

                    <OrderAddress
                      address={orderDetails?.orderDetails?.patientAddress}
                      number={orderDetails?.orderDetails?.patientMobileNumber}
                      userName={orderDetails?.orderDetails?.userName}
                      patientName={orderDetails?.orderDetails?.patientName}
                      patientAge={orderDetails?.orderDetails?.patientAge}
                      statusId={statusId}
                      tokenAmount={
                        orderDetails?.orderPriceDetailsV2?.tokenAmount
                      }
                      loyaltyAmount={
                        orderDetails?.orderPriceDetailsV2?.loyaltyAmount
                      }
                      totalDiscount={
                        orderDetails?.orderPriceDetailsV2?.totalDiscount
                      }
                      paymentMode={
                        orderDetails?.orderPriceDetailsV2?.paymentMode
                      }
                    />

                    <OrderStatus
                      isMobile={isMobile}
                      priceDetails={orderDetails?.orderPriceDetailsV2}
                      customStyle={styles.order_status}
                      data={orderDetails?.order?.trackingDetails}
                      handleCancelOrder={handleCancelOrder}
                      cancelbutton={cancelbutton}
                      statusId={orderDetails?.order?.statusId}
                      status={orderDetails?.order?.status}
                      handleShowReturn={handleShowReturn}
                      setShowReturn={setShowReturn}
                    />

                    <CartDetails
                      orderItems={orderDetails?.orderItems}
                      statusId={orderDetails?.order?.statusId}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.billing_address_container}>
                    <OrderAddress
                      address={orderDetails?.orderDetails?.patientAddress}
                      number={orderDetails?.orderDetails?.patientMobileNumber}
                      userName={orderDetails?.orderDetails?.userName}
                      patientName={orderDetails?.orderDetails?.patientName}
                      statusId={statusId}
                      tokenAmount={
                        orderDetails?.orderPriceDetailsV2?.tokenAmount
                      }
                      loyaltyAmount={
                        orderDetails?.orderPriceDetailsV2?.loyaltyAmount
                      }
                    />
                  </div>
                  {orderDetails?.warehouseOrders.map(
                    (warehouseOrder, index) => (
                      <div key={index}>
                        <OrderInfo
                          order={warehouseOrder?.warehouseOrder}
                          trueremark={trueremark}
                          warehouseOrder={true}
                        />
                        <div className={styles.billing_address_container}>
                          <OrderStatus
                            orderId={orderId}
                            shipment={true}
                            isMobile={isMobile}
                            priceDetails={warehouseOrder?.priceDetails}
                            customStyle={styles.order_status}
                            data={
                              warehouseOrder?.warehouseOrder?.trackingDetails
                            }
                            handleCancelOrder={() => handleCancelOrder(index)}
                            cancelbutton={cancelbutton}
                            statusId={warehouseOrder?.warehouseOrder?.statusId}
                            status={warehouseOrder?.warehouseOrder?.status}
                          />

                          <CartDetails
                            shipment={true}
                            orderItems={warehouseOrder?.warehouseOrderItems}
                            statusId={warehouseOrder?.warehouseOrder?.statusId}
                          />
                          {warehouseOrder?.warehouseOrder?.statusId !== 0 && (
                            <BillingAccordion
                              statusId={
                                warehouseOrder?.warehouseOrder?.statusId
                              }
                              data={warehouseOrder?.priceDetails}
                              order={orderDetails?.order}
                              oldPriceDetails={orderDetails?.oldPriceDetails}
                              paymentMode={
                                warehouseOrder?.warehouseOrderPaymentDetails[0]
                                  ?.modeOfPayment
                              }
                            />
                          )}
                          <Dates
                            shipment={true}
                            statusId={warehouseOrder?.warehouseOrder?.statusId}
                            placedOn={warehouseOrder?.warehouseOrder?.createdAt}
                            tentativeDelivery={
                              warehouseOrder?.warehouseOrder
                                ?.orderFulfillmentDate
                            }
                          />
                        </div>
                      </div>
                    )
                  )}
                </>
              )}
            </div>
            {orderDetails?.orderPriceDetailsV2?.cashbackType ? (
              <div className={styles.cashback}>
                <Cash width={20} height={20} />
                <p>
                  {statusId == 70
                    ? `${
                        orderDetails?.orderPriceDetailsV2?.cashbackType == 1
                          ? orderDetails?.orderPriceDetailsV2?.cashback +
                            'Prx Cash Credited to your Account'
                          : orderDetails?.orderPriceDetailsV2?.cashback +
                            'Prx Coin Credited to your Account'
                      }`
                    : 'Cash back will be credited after Delivery'}
                </p>
              </div>
            ) : null}
            <div className={styles.bill_details_container}>
              <OrderInfo
                shipment={orderDetails?.order?.split}
                orderDetails={orderDetails}
                trueremark={trueremark}
                statusId={statusId}
              />
              {statusId !== 0 && (
                <SavingSection
                  savingAmount={
                    orderDetails?.orderPriceDetailsV2?.totalSavingOnOrder +
                    orderDetails?.orderPriceDetailsV2?.loyaltySavings
                  }
                  loyaltySavings={
                    orderDetails?.orderPriceDetailsV2?.loyaltySavings
                  }
                />
              )}
              {statusId !== 0 && (
                <BillingDetails
                  data={orderDetails?.orderPriceDetailsV2}
                  order={orderDetails?.order}
                  oldPriceDetails={orderDetails?.oldPriceDetails}
                  paymentMode={orderDetails?.orderPriceDetailsV2?.paymentMode}
                />
              )}
              <Button
                variant='contained'
                onClick={handleGetPrescriptions}
                sx={{ marginTop: '10px' }}
              >
                Get Prescriptions
              </Button>
              {showPrescription && (
                <PrescriptionContainer
                  source='polaris'
                  isMobile={isMobile}
                  userId={orderDetails?.order?.userId}
                  details={orderDetails}
                />
              )}
            </div>
          </div>
          {statusId === 70 && showReturn && (
            <div className={styles.return_container}>
              <h3>Return Section</h3>
              <CartDetailsReturn
                orderDetails={orderDetails}
                setShowReturn={setShowReturn}
                orderId={orderDetails?.order?.orderId}
                orderItems={orderDetails?.orderItems}
                fetchOrderDetails={fetchOrderDetails}
              />
            </div>
          )}
          <div ref={returnContainerRef} />
          <CancelDrawer
            isMobile={isMobile}
            isOpen={drawerOpen}
            warehouseItemIndex={warehouseOrder}
            onClose={handleDrawerClose}
            setDrawerOpen={setDrawerOpen}
            orderDetails={orderDetails}
            handleSubmitReason={handleSubmitReason}
          />
        </>
      )}
    </div>
  );
};

export default OrderDetailsPage;
