import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Radio,
  FormControlLabel,
  RadioGroup,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  IconButton,
} from '@mui/material';
import {
  Person,
  Person2,
  Transgender,
  Edit,
  Delete,
} from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';
import styles from 'pages/Polaris/Customer/Customer.module.scss';
import { deleteUserPatient, addUpdateUserPatient } from 'utils/api';

const Patients = ({
  source,
  userData,
  setUserData,
  handleUpdateUser,
  refetchUserDetails,
}) => {
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [patientForm, setPatientForm] = useState({
    patient_name: '',
    patient_age: '',
    patient_gender: 'male',
  });
  const [patientDialogOpen, setPatientDialogOpen] = useState(false);

  const handlePatientChange = async (event) => {
    const patientId = event.target.value;
    try {
      handleUpdateUser({
        currentPatientId: Number(patientId),
      });
      enqueueSnackbar('Patient changed successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
    }
  };

  const handleEditPatient = (patient) => {
    setSelectedPatient(patient);
    setPatientForm({
      patient_name: patient.patient_name,
      patient_age: patient.patient_age,
      patient_gender: patient.patient_gender,
    });
    setPatientDialogOpen(true);
  };

  const handleFormChange = (e) => {
    setPatientForm({ ...patientForm, [e.target.name]: e.target.value });
  };

  const handleSavePatient = async () => {
    try {
      const response = await addUpdateUserPatient()({
        ...patientForm,
        patient_age: Number(patientForm.patient_age),
        user_id: userData.userId,
        id: selectedPatient ? selectedPatient.id : undefined,
      });
      setUserData(response.data);
      setPatientDialogOpen(false);
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
    }
  };

  const handleDeletePatient = async (patientId) => {
    try {
      await deleteUserPatient()({
        id: patientId,
        user_id: userData.userId,
      });
      refetchUserDetails(userData.userId);
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
    }
  };

  return (
    <Grid item xs={12}>
      <Card
        className={
          source === 'PO' ? styles.customerCard_po : styles.customerCard
        }
      >
        <CardContent className={styles.customerCard__content}>
          <div className={styles.customerCard__header}>
            <div className={styles.customerCard__header__left}>
              <Typography variant={source === 'PO' ? 'body1' : 'h5'}>
                Patients
              </Typography>
            </div>
            <Button
              className={styles.customerButton}
              variant='contained'
              onClick={() => {
                setSelectedPatient(null);
                setPatientForm({
                  patient_name: '',
                  patient_age: '',
                  patient_gender: 'male',
                });
                setPatientDialogOpen(true);
              }}
            >
              Add Patient
            </Button>
          </div>

          <RadioGroup
            value={userData?.currentPatientId}
            onChange={handlePatientChange}
          >
            {userData.patients.map((patient) => (
              <FormControlLabel
                key={patient.id}
                value={patient.id}
                control={<Radio />}
                label={
                  <div className={styles.patientInfo}>
                    {patient.patient_gender === 'male' && (
                      <Person style={{ color: '#01A6EA' }} />
                    )}
                    {patient.patient_gender === 'female' && (
                      <Person2 style={{ color: '#FFB1CB' }} />
                    )}
                    {patient.patient_gender !== 'male' &&
                      patient.patient_gender !== 'female' && (
                        <Transgender style={{ color: '#A649A4' }} />
                      )}
                    <Typography variant={source === 'PO' ? 'body1' : 'h6'}>
                      {patient.patient_name} - {patient.patient_age}Y
                    </Typography>
                    <div className={styles.patientActions}>
                      <IconButton
                        color='default'
                        onClick={(e) => {
                          e.preventDefault();
                          handleEditPatient(patient);
                        }}
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        color='error'
                        onClick={(e) => {
                          e.preventDefault();
                          handleDeletePatient(patient.id);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </div>
                  </div>
                }
              />
            ))}
          </RadioGroup>
        </CardContent>
      </Card>

      <Dialog
        open={patientDialogOpen}
        onClose={() => setPatientDialogOpen(false)}
      >
        <div className={styles.addressDialog}>
          <DialogTitle>
            {selectedPatient ? 'Edit Patient' : 'Add Patient'}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin='dense'
              name='patient_name'
              label='Patient Name'
              type='text'
              fullWidth
              value={patientForm.patient_name}
              onChange={handleFormChange}
            />
            <TextField
              margin='dense'
              name='patient_age'
              label='Age'
              type='number'
              fullWidth
              value={patientForm.patient_age}
              onChange={handleFormChange}
            />
            <RadioGroup
              row
              name='patient_gender'
              value={patientForm.patient_gender}
              onChange={handleFormChange}
            >
              <FormControlLabel value='male' control={<Radio />} label='Male' />
              <FormControlLabel
                value='female'
                control={<Radio />}
                label='Female'
              />
              <FormControlLabel
                value='other'
                control={<Radio />}
                label='Other'
              />
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setPatientDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleSavePatient}>
              {selectedPatient ? 'Update' : 'Add'}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </Grid>
  );
};

export default Patients;
