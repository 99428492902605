import React from 'react';
import styles from '../cartDetails.module.scss';
import { addDelimeter } from 'utils/commonFunctions';

const ItemDetails = ({ item }) => {
  const {
    itemCartData: {
      drugName = '',
      itemTotalDiscountPrice = 0,
      itemTotalPrice = 0,
      quantity = 0,
    } = {},
    itemCatalogData: {
      displayName = '',
      masterDrugCode = 0,
      heroImage = '',
      drugCategory = '',
      manufacturerName = '',
      packForm = '',
      packQuantityValueDecimal = 0,
      drugForm = '',
    } = {},
  } = item || {};

  return (
    <div className={styles.item_container}>
      <div className={styles.content}>
        <div>
          <div
            style={{
              backgroundImage: `url("${heroImage}")`,
            }}
            className={styles.item_img}
          />
          {drugCategory === 'RX' && <div className={styles.rx_icon}>Rx</div>}
        </div>
        <div className={styles.item_details}>
          <div className={styles.drug_Name}>{displayName}</div>
          <div className={styles.img_container}>
            <div className={styles.manufacturer_name}>
              {manufacturerName?.split(' ')[0]}
            </div>
            <div className={styles.vertical_divider} />
            <div className={styles.manufacturer_name}>
              {packForm} of {packQuantityValueDecimal} {drugForm}
            </div>
          </div>
          <div className={styles.price_container}>
            <div className={styles.discounted_price}>
              ₹{addDelimeter(itemTotalDiscountPrice)}
            </div>
            <div className={styles.mrp_price}>
              ₹{addDelimeter(itemTotalPrice)}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.item_qty}>Qty: {quantity}</div>
    </div>
  );
};

export default ItemDetails;
