import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Card, CardContent, Typography, Grid, Button } from '@mui/material';
import { getCustomerOrders } from 'utils/api'; // Assume this is your API call function
import styles from './orderHistory.module.scss';

const OrderHistory = ({ userId }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRowId, setSelectedRowId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = {
          userId: userId,
          params: {
            limit: 10,
            skip: 0,
          },
        };
        const response = await getCustomerOrders()(data); // Fetch the order history
        const ordersData = response.data; // Assuming response.data contains the array of orders
        const formattedOrders = ordersData.map((order) => ({
          id: order.order.orderId, // Unique ID for each order
          userId: order.order.userId,
          userCartId: order.order.userCartId,
          status: order.order.status,
          orderCreationFlow: order.order.orderCreationFlow,
          orderFulfillmentDate: order.order.orderFulfillmentDate,
          createdAt: new Date(order.order.createdAt).toLocaleString(), // Format date
          totalAmountToBePaid: order.orderPriceDetails.totalAmountToBePaid,
          trackingDetails: order.order.trackingDetails
            .map((detail) => detail.statusName)
            .join(', '),
          orderItems: order.orderItems
            .map((item) => item.itemCatalogData.displayName)
            .join(', '), // New field for order items
        }));
        setOrders(formattedOrders);
      } catch (error) {
        console.error('Error fetching order history:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleRowClick = (id) => {
    setSelectedRowId(id === selectedRowId ? null : id);
  };

  const renderOrderItems = (items) => (
    <ul>
      {items.split(', ').map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  );

  const renderTrackingDetails = (details) => (
    <ul>
      {details.split(', ').map((detail, index) => (
        <li key={index}>{detail}</li>
      ))}
    </ul>
  );

  const columns = [
    {
      field: 'totalAmountToBePaid',
      headerName: 'Total Amoun to be paid',
      width: 150,
    },
    { field: 'id', headerName: 'Order ID', width: 150 },
    { field: 'userCartId', headerName: 'User Cart ID', width: 150 },
    { field: 'status', headerName: 'Order Status', width: 200 },
    { field: 'orderCreationFlow', headerName: 'Creation Flow', width: 200 },
    {
      field: 'orderFulfillmentDate',
      headerName: 'Fulfillment Date',
      width: 200,
    },
    { field: 'createdAt', headerName: 'Created At', width: 180 },
  ];

  const selectedOrder = orders.find((order) => order.id === selectedRowId);

  return (
    <div>
      <h1>Order History</h1>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={orders}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 25]}
            disableSelectionOnClick
            onRowClick={(params) => handleRowClick(params.row.id)}
          />
          {selectedOrder && (
            <Card className={styles.expandedRow}>
              <CardContent>
                <Typography variant='h6'>
                  Order Details for {selectedOrder.id}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant='subtitle1'>Order Items:</Typography>
                    {renderOrderItems(selectedOrder.orderItems)}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='subtitle1'>
                      Tracking Details:
                    </Typography>
                    {renderTrackingDetails(selectedOrder.trackingDetails)}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
        </div>
      )}
    </div>
  );
};

export default OrderHistory;
