import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Autocomplete, TextField, FormControl, Grid } from '@mui/material';
import { fetchAllDispositions, getAgents, getCampaigns } from 'utils/api';
import dayjs from 'dayjs';
import styles from './allDispositions.module.scss';
import CustomDateRangePicker from 'components/CustomElements/CustomDatepicker';
import { useApp } from 'context/AppContext';
import PageLoader from 'components/Loaders/PageLoader';
import { agentsBasedOnRoleId } from 'utils/constants';

const AllDispositions = () => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [agents, setAgents] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [rowCount, setRowCount] = useState(0);

  const { campaignIds, setCampaignIds, agentFilter, setAgentFilter } = useApp();

  const navigate = useNavigate();

  const fetchCampaigns = async () => {
    try {
      setLoading(true);
      const params = { skip: 0, limit: 15 };
      const response = await getCampaigns()(params);
      setCampaignOptions(response.data.data);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAgents = async () => {
    try {
      setLoading(true);
      const data = { roleId: agentsBasedOnRoleId.CRM_Agent };
      const response = await getAgents()(data);
      setAgents(response.data.data);
    } catch (error) {
      console.error('Error fetching agents:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTasks = async (page, pageSize) => {
    try {
      const data = {
        data: {
          internalUserIds: agentFilter || [],
          campaignIds:
            campaignIds.length > 0 ? campaignIds.map(Number) : undefined,
          startDate: fromDate
            ? dayjs(fromDate).format('YYYY-MM-DD')
            : undefined,
          endDate: toDate ? dayjs(toDate).format('YYYY-MM-DD') : undefined,
        },
        params: {
          skip: page * pageSize,
          limit: pageSize,
        },
      };

      const response = await fetchAllDispositions()(data);

      setTasks(response.data.data.dispositions);
      setRowCount(response.data.data.count || 100);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAgents();
    fetchCampaigns();
  }, []);

  useEffect(() => {
    fetchTasks(page, pageSize);
  }, [campaignIds, fromDate, toDate, agentFilter, page, pageSize, rowCount]);

  const handleRowClick = (params) => {
    const userId = params.row.userId;
    const taskId = params.row.id;
    navigate(`/crm-dashboard/lead-history/${userId}/${taskId}`);
  };

  const handlePaginationModelChange = (newModel) => {
    setPage(newModel.page);
    setPageSize(newModel.pageSize);
  };

  const rows = tasks.map((task) => ({
    id: task?.id,
    taskId: task?.task?.id,
    internalUserId: task?.internalUserId,
    name: task?.task?.name,
    campaignId: task?.task?.campaignId || 'N/A',
    phoneNumber: task?.agent?.phone || 'N/A',
    userId: task?.task?.userId || 'N/A',
    activeCartValue: Number(task?.task?.activeCartValue),
    status: task?.status || 'N/A',
    disposition: task?.disposition || 'N/A',
    subDisposition: task?.subDisposition || 'N/A',
    createdAt: task?.createdAt
      ? new Date(task.createdAt).toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        })
      : 'N/A',
    completedAt: task?.task?.completedAt
      ? new Date(task?.task?.completedAt).toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        })
      : 'N/A',
    agentName: task?.agent?.username || '-',
    medicineRemaining: task?.meta?.medicineRemaining || 'N/A',
  }));

  const columns = [
    { field: 'name', headerName: 'Task Name', width: 200 },
    { field: 'disposition', headerName: 'Disposition', width: 150 },
    { field: 'subDisposition', headerName: 'Sub Disposition', width: 150 },
    { field: 'status', headerName: 'Status', width: 120 },
    {
      field: 'medicineRemaining',
      headerName: 'Medicine Remaining',
      width: 150,
    },
    { field: 'activeCartValue', headerName: 'Active Cart Value', width: 160 },
    { field: 'phoneNumber', headerName: 'Phone Number', width: 120 },
    { field: 'userId', headerName: 'User ID', width: 120 },
    { field: 'createdAt', headerName: 'Created At', width: 180 },
    { field: 'completedAt', headerName: 'completed At', width: 180 },
    { field: 'agentName', headerName: 'Agent Name', width: 150 },
  ];

  return (
    <>
      {loading || campaignOptions.length === 0 || agents.length === 0 ? (
        <PageLoader />
      ) : (
        <div className='home'>
          <div
            className={styles.filtersContainer}
            style={{ display: 'flex', gap: '10px' }}
          >
            <FormControl variant='outlined' fullWidth>
              <Autocomplete
                id='campaign-autocomplete'
                multiple
                options={campaignOptions.map((campaign) => campaign.name)}
                value={
                  campaignIds.map(
                    (id) =>
                      campaignOptions.find((campaign) => campaign.id === id)
                        ?.name
                  ) || []
                }
                onChange={(event, newValue) => {
                  const selectedCampaignIds = newValue
                    .map((name) => {
                      const selectedCampaign = campaignOptions.find(
                        (campaign) => campaign.name === name
                      );
                      return selectedCampaign ? selectedCampaign.id : null;
                    })
                    .filter((id) => id !== null);
                  setCampaignIds(selectedCampaignIds);
                }}
                renderInput={(params) => (
                  <TextField {...params} label='Campaign' variant='outlined' />
                )}
              />
            </FormControl>
            <FormControl variant='outlined' fullWidth>
              <Autocomplete
                id='agent-autocomplete'
                multiple
                options={agents.map((agent) => agent.username)}
                value={
                  agentFilter.map(
                    (id) => agents.find((agent) => agent.id === id)?.username
                  ) || []
                }
                onChange={(event, newValue) => {
                  const selectedAgentIds = newValue
                    .map((name) => {
                      const selectedAgent = agents.find(
                        (agent) => agent.username === name
                      );
                      return selectedAgent ? selectedAgent.id : null;
                    })
                    .filter((id) => id !== null);
                  setAgentFilter(selectedAgentIds);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Select Agent'
                    variant='outlined'
                  />
                )}
              />
            </FormControl>
            <Grid fullWidth>
              <CustomDateRangePicker
                setStartDate={setFromDate}
                setEndDate={setToDate}
                startDate={fromDate}
                endDate={toDate}
              />
            </Grid>
          </div>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            loading={loading}
            autoHeight
            initialState={{
              pagination: {
                paginationModel: { pageSize: 25, page: 0 },
              },
            }}
            paginationMode='server'
            pageSizeOptions={[10, 25, 50, 100]}
            paginationModel={{ page, pageSize }}
            onPaginationModelChange={handlePaginationModelChange}
            rowCount={rowCount}
            disableSelectionOnClick
            onRowClick={handleRowClick}
            sx={{
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#f5f5f5',
                fontWeight: 'bold',
              },
              '& .MuiDataGrid-footerContainer': {
                backgroundColor: '#f5f5f5',
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export default AllDispositions;
