import { de } from 'date-fns/locale';
import createAxios from './createAxios';
import axios from 'axios';

const browserAxios = createAxios();

export const InternalUserLogin =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: '/internal-users/login', data });

export const sendOtp =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: '/auth/sendOtp', data });

export const getPharmacistOrders =
  (axios = browserAxios) =>
  (data) =>
    axios.get({
      url: `/orders/pharmacistOrders`,
      params: data,
    });

export const getDoctorOrders =
  (axios = browserAxios) =>
  (data) =>
    axios.get({
      url: `/orders/doctorOrders`,
      params: data,
    });

export const getAllOrders =
  (axios = browserAxios) =>
  (data) =>
    axios.get({
      url: `/orders/internal/fetchOrders`,
      params: data,
    });

export const getAllPharmacists =
  (axios = browserAxios) =>
  () =>
    axios.get({ url: '/internal-users/getAllPharmacists' });

export const getAllDoctors =
  (axios = browserAxios) =>
  () =>
    axios.get({ url: '/internal-users/getAllDoctors' });

export const getPastPrescriptions =
  (axios = browserAxios) =>
  (data) =>
    axios.get({
      url: '/digitizedPrescriptions/pastPrescriptions',
      params: data,
    });

export const assignPharmacist =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: '/orders/assignPharmacist', data });

export const assignDoctor =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: '/orders/assignDoctor', data });

export const markPharmacistUnavailable =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/internal-users/changePharmacistAvailability/${data.pharmacistId}`,
      data: { active: data.active },
    });

export const markDoctorUnavailable =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/internal-users/changeDoctorAvailability/${data.doctorId}`,
      data: { active: data.active },
    });

export const createDigitizedPrx =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: '/digitizedPrescriptions/bulkCreate', data });

export const deleteDigitizedPrx =
  (axios = browserAxios) =>
  () =>
    axios.delete({ url: '/digitizedPrescriptions/2' });

export const getOrderDetails =
  (axios = browserAxios) =>
  (data) =>
    axios.get({
      url: `/orders/internal/fetchOrderDetails/${data.orderId}?warehouseLocations=true`,
    });

export const getOrderDetailsWithBoxCode =
  (axios = browserAxios) =>
  (data) =>
    axios.get({
      url: `/orders/internal/fetchOrderDetails/barcode/${data.barcodeSerial}`,
    });

export const getUserPrescriptions =
  (axios = browserAxios) =>
  (data) =>
    axios.get({
      url: `/files/prescriptions`,
      params: { cartId: data?.cartId },
    });

export const cloneCart =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: `/orders/internal/cloneOrderCart/${data.orderId}` });

export const getDoctorInfo =
  (axios = browserAxios) =>
  (data) =>
    axios.get({ url: '/ops/internal/doctor', params: data });

export const getCartDetail =
  (axios = browserAxios) =>
  (data) =>
    axios.get({ url: `/cart/internal/fetchCartFromId/${data.cartId}` });

export const addItemInCartByQuantity =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/cart/internal/${data.userId}/setItemQuantity?cartGeneratedBy=doctor&cartId=${data.cartId}`,
      data,
    });

export const updateDoctorReviewStatus =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: '/orders/updateDoctorReviewStatus', data });

export const signAndSend =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: '/ops/internal/createDoctorOrOpsCart', data });

export const createPrxForDoctorCart =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: '/ops/internal/generatePrescriptionForCart', data });

export const placeOrderWithNewCart =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/payment/internal/cloneOrderWithNewCart/${data.orderId}`,
      data,
    });

export const makeCall =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: `orders/callPatient/${data.id}` });

export const fetchUserDetails =
  (axios = browserAxios) =>
  (id) =>
    axios.get({
      url: `/users/fetchuserdetails`,
      headers: { user_id: id },
    });

export const addUpdateUserPatient =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: `/users/internal/addUpdateUserPatient`, data });

export const getProcurementItems =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/procurement/items`,
      data: data?.data,
      params: data?.params,
    });

export const createPurchaseOrder =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/procurement/createPurchaseOrder`,
      data: data,
    });

export const createDraftPurchaseOrder =
  (axios = browserAxios) =>
  (data) =>
    axios.put({
      url: `/procurement/draftPurchaseOrderItem`,
      data: data,
    });

export const getPendingOrdersOfDrug =
  (axios = browserAxios) =>
  (masterDrugCode) =>
    axios.get({
      url: `/procurement/items/${masterDrugCode}/orders`,
    });

export const getWarehousePendingOrders =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/procurement/warehousePendingOrders`,
      params: data.params,
      data: data.data,
    });

export const getPendingOrderItems =
  (axios = browserAxios) =>
  (orderId) =>
    axios.get({
      url: `/procurement/orderItemProcurement/${orderId}`,
    });

export const draftPurchaseOrderItem =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/procurement/draftPurchaseOrderItems`,
      params: data.params,
      data: data.data,
    });

export const deleteDraftPurchaseOrderItem =
  (axios = browserAxios) =>
  (id) =>
    axios.delete({
      url: `/procurement/draftPurchaseOrderItem/${id}`,
    });

export const getPurchaseOrderItems =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/procurement/purchaseOrderItems`,
      params: data.params,
      data: data.data,
    });

export const getPurchaseOrders =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `procurement/purchaseOrders`,
      params: data.params,
      data: data.data,
    });

export const createPurchaseOrderItems =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/procurement/createPurchaseOrder`,
      params: data,
    });

export const updatePurchaseOrderStatus =
  (axios = browserAxios) =>
  (data) =>
    axios.put({
      url: `/procurement/purchaseOrderItem/${data.id}`,
      data: data.data,
    });

export const getDrugReorderPoints =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/procurement/drugReorderPoints`,
      params: data.params,
      data: data.data,
    });

export const putDrugReorderPoint =
  (axios = browserAxios) =>
  (data) =>
    axios.put({
      url: `/procurement/drugReorderPoint`,
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

export const getReorderSuggestions =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/procurement/reorderSuggestions`,
      params: data.params,
      data: data.data,
    });

export const MarkProcurementOrderAsQCDone =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/ops/markOrderAsQcDone`,
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

export const getPendingWarehouseOrders =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `orders/v2/fetchPendingWarhouseOrders`,
      data: data,
    });

export const getWareHouseOrderDetails =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: `/orders/v2/internal/warehouseOrder/${data.orderId}` });

export const MarkProcurementSplitOrderAsQCDone =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/ops/v2/markOrderAsQcDone`,
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

export const UploadExcelFile =
  (axios = browserAxios) =>
  (data) =>
    axios.put({
      url: `/procurement/upload/draftPurchaseOrderItem`,
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

export const deletePurchaseOrder =
  (axios = browserAxios) =>
  (data) =>
    axios.delete({ url: `/procurement/purchaseOrder`, data: data });

export const markAsClosedBulk =
  (axios = browserAxios) =>
  (data) =>
    axios.patch({ url: `/procurement/close/purchaseOrderItems`, data: data });

export const getEvents =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: `/events/user`, params: data.params, data: data.data });

export const getTasks =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: `/tasks`, params: data?.params, data: data?.data });

export const getCustomerTasks =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/tasks/customer`,
      params: { ...data?.params },
      data: data?.data,
    });

export const patchDisposition =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/tasks/disposition/`,
      data: data.data,
      headers: {
        user_id: data.userId,
        source: 'cc-dashboard',
      },
    });

export const getTasksforAdmin =
  (axios = browserAxios) =>
  (data) => {
    return axios.post({
      url: `/tasks/all`,
      data: data?.data,
      params: data?.params,
    });
  };

export const getAgents =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: `/internal-users/list`, data: data });

export const assignOrUpdateOwner =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: `/tasks/assignOrUpdateOwner`, data: data });

export const AcceptTask =
  (axios = browserAxios) =>
  (data) =>
    axios.patch({ url: `/tasks/status`, data: data });

export const getTaskDetails =
  (axios = browserAxios) =>
  (data) =>
    axios.get({ url: `/tasks/${data.taskId}` });

export const getAdminTaskDetails =
  (axios = browserAxios) =>
  (data) =>
    axios.get({ url: `/tasks/admin/${data.taskId}` });

export const subscribeToWebNotifications =
  (axios = browserAxios) =>
  () =>
    axios.get({ url: `/webNotifications/subscribe` });

export const getCampaigns =
  (axios = browserAxios) =>
  (params) =>
    axios.get({ url: `/campaign`, params: params });

export const createTask =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: `/tasks/new`, data: data });

export const sendWhatsappMessage =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: `/gupshup/whatsappTemplateMessage`, data: data });

export const uploadPurchaseOrderFile =
  (axios = browserAxios) =>
  (data) =>
    axios.patch({
      url: `/procurement/purchaseOrder/${data.id}`,
      data: data.data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

export const updateAgentStatus =
  (axios = browserAxios) =>
  (data) =>
    axios.patch({ url: `/internal-users/status`, data: data });

export const autoAcceptOrder =
  (axios = browserAxios) =>
  (data) =>
    axios.get({ url: `/picker/order`, params: data });

export const scanBoxCode =
  (axios = browserAxios) =>
  (data) =>
    axios.patch({
      url: `/picker/acceptOrderAndAssignBarcode`,
      data: data,
    });

export const pickUpDone =
  (axios = browserAxios) =>
  (data) =>
    axios.patch({
      url: `/picker/picked/${data?.orderId}`,
    });

export const pickUpDoneTL =
  (axios = browserAxios) =>
  (data) =>
    axios.patch({
      url: `/picker/tl/picked/${data?.orderId}`,
    });

export const raisePickerIssues =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/picker/raiseIssue`,
      data: data,
    });

export const fetchIssueOrders =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/picker/tl/pickingIssueOrders`,
      params: data.params,
      data: data.data,
    });

export const unassignItem =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/picker/tl/unassignPicker/${data.orderId}`,
    });

export const updateIssueAPI =
  (axios = browserAxios) =>
  (data) =>
    axios.patch({
      url: `/orders/remarks/${data.id}`,
      data: data,
    });

export const deleteIssueAPI =
  (axios = browserAxios) =>
  (data) =>
    axios.delete({
      url: `/orders/remarks/${data.id}`,
    });

export const bulkCloseTasks =
  (axios = browserAxios) =>
  (data) =>
    axios.patch({
      url: `/tasks/ignore`,
      data: data,
    });

export const bulkClosePOs =
  (axios = browserAxios) =>
  (data) =>
    axios.delete({
      url: `/procurement/bulk/draftPurchaseOrderItem`,
      data: data,
    });

export const fetchAllDispositions =
  (axios = browserAxios) =>
  (data) => {
    return axios.post({
      url: `/tasks/dispositions`,
      data: data?.data,
      params: data?.params,
    });
  };

export const getCustomerOrders =
  (axios = browserAxios) =>
  (data) => {
    return axios.get({
      url: `orders/v2/internal/getCustomerOrders/${data?.userId}`,
      params: data?.params,
    });
  };

export const getFrequentDetails =
  (axios = browserAxios) =>
  (data) => {
    return axios.get({
      url: `/internal-users/details`,
    });
  };

export const updateOrderStatus =
  (axios = browserAxios) =>
  (data) => {
    return axios.post({
      url: `/orders/updateStatus`,
      data: data,
    });
  };

export const getAWBList =
  (axios = browserAxios) =>
  (data) =>
    axios.get({
      url: `/ops/manifestedOrders`,
      params: data,
    });

export const markAsDispatched =
  (axios = browserAxios) =>
  (data) =>
    axios.patch({
      url: `/ops/markAsDispatched`,
      data: data,
    });

export const getUserViaNumber =
  (axios = browserAxios) =>
  (data) => {
    return axios.get({
      url: `/users/internal/fetchuserdetails/${data?.number}`,
    });
  };

export const updateInternalUser =
  (axios = browserAxios) =>
  (data) => {
    return axios.post({
      url: `/users/update-internal`,
      data: data,
    });
  };

export const deleteUserAddress =
  (axios = browserAxios) =>
  (data) => {
    return axios.post({
      url: `/users/internal/deleteUserAddress`,
      data: data,
    });
  };

export const deleteUserPatient =
  (axios = browserAxios) =>
  (data) => {
    return axios.post({
      url: `/users/internal/deleteUserPatient`,
      data: data,
    });
  };

export const addUpdateUserAddress =
  (axios = browserAxios) =>
  (data) => {
    return axios.post({
      url: `/users/internal/addUpdateUserAddress`,
      data: data,
    });
  };

export const getAutoComplete =
  (axios = browserAxios) =>
  async (data) =>
    await axios.get({ url: `/google/automcomplete?input=${data}` });

export const getPlaceDetails =
  (axios = browserAxios) =>
  async (data) =>
    await axios.get({ url: `/google/placeDetails?placeId=${data}` });

export const getPinCodeDetails = () => async (data) =>
  await axios.get(`https://api.postalpincode.in/pincode/${data.pincode}`);

export const getGeoCodeDetails =
  (axios = browserAxios) =>
  async (data) =>
    await axios.get({ url: `/google/geoCodeDetails?latlng=${data}` });

export const getWalletTransactions =
  (axios = browserAxios) =>
  async (data) =>
    await axios.post({
      url: `/wallet/transactions`,
      params: data.params,
      headers: data.headers,
      data: data.data,
    });

export const getWallet =
  (axios = browserAxios) =>
  async (data) => {
    return axios.get({ url: '/wallet', headers: data.headers });
  };

export const getOrders =
  (axios = browserAxios) =>
  (data) => {
    return axios.get({ url: '/orders/internal/fetchOrders', params: data });
  };

export const cancelOrder =
  (axios = browserAxios) =>
  (data) => {
    return axios.post({ url: '/orders/v2/cancelOrder', data });
  };

export const initiateEditOrder =
  (axios = browserAxios) =>
  (data) => {
    return axios.post({
      url: `/orders/internal/initiateEditOrder/${data.orderId}`,
    });
  };

export const getActiveCartWithCartId =
  (axios = browserAxios) =>
  (data) => {
    return axios.get({
      url: `/cart/internal/fetchActiveCart/${data.userId}?cartId=${data?.cartId}`,
    });
  };

export const getActiveCart =
  (axios = browserAxios) =>
  (data) => {
    return axios.get({
      url: `/cart/internal/fetchActiveCart/${data.userId}`,
    });
  };

export const setItemQuantity =
  (axios = browserAxios) =>
  (data) => {
    return axios.post({
      url: data.cartId
        ? `/cart/internal/${data.userId}/setItemQuantity?cartId=${data.cartId}`
        : `/cart/internal/${data.userId}/setItemQuantity`,
      data: data.data,
    });
  };

export const getAllCoupons =
  (axios = browserAxios) =>
  async () =>
    await axios.get({ url: '/coupons/fetchAllActiveCoupons?page=1' });

export const applyCoupon =
  (axios = browserAxios) =>
  (data) => {
    return axios.post({
      url: `/coupons/internal/applyCoupon/${data.userId}?cartId=${data.cartId}`,
      data: data.data,
    });
  };

export const removeAppliedCoupon =
  (axios = browserAxios) =>
  (data) => {
    return axios.post({
      url: `/coupons/internal/removeAppliedCoupon/${data.userId}?cartId=${data.cartId}`,
    });
  };

export const confirmEditOrder =
  (axios = browserAxios) =>
  (data) => {
    return axios.post({
      url: `/orders/internal/confirmEditOrder/${data.orderId}`,
      data: data.data,
    });
  };

export const getPrescriptions =
  (axios = browserAxios) =>
  (data) => {
    return axios.get({
      url: 'files/prescriptions',
      params: data,
    });
  };

export const getAzureUrls =
  (axios = browserAxios) =>
  (data) => {
    return axios.post({
      url: '/files/getAzureUrls',
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

export const initiateReturn =
  (axios = browserAxios) =>
  (data) => {
    return axios.post({
      url: `/returns/initiateReturn/${data.orderId}`,
      data: data.data,
    });
  };

export const updatePaymentDetails =
  (axios = browserAxios) =>
  (data) => {
    return axios.post({
      url: `/returns/updatepaymentDetails/${data.returnId}`,
      data: data.data,
    });
  };

export const getReturnRequests =
  (axios = browserAxios) =>
  (data) => {
    return axios.get({
      url: `/returns/getReturnRequest`,
      params: data,
    });
  };

export const getReturnItems =
  (axios = browserAxios) =>
  (data) => {
    return axios.get({
      url: `/returns/v2/getReturnItems/${data.returnId}`,
    });
  };

export const updateReturnStatus =
  (axios = browserAxios) =>
  (data) => {
    return axios.post({
      url: `/returns/updateReturnStatus`,
      data: data,
    });
  };

export const updateBarcode =
  (axios = browserAxios) =>
  (data) =>
    axios.patch({
      url: `/orders/internal/updateBarcode`,
      data: data,
    });

export const initiateOrder =
  (axios = browserAxios) =>
  (data) => {
    return axios.post({
      url: `/orders/internal/${data.userId}/initiateOrder`,
      data: data.data,
    });
  };

export const ApplyPrxCurrency =
  (axios = browserAxios) =>
  async (data) => {
    return axios.post({
      url: '/cart/applyPrxCurrency',
      data: data.data,
      headers: data.headers,
    });
  };

export const addCartLoyalty =
  (axios = browserAxios) =>
  async (data) =>
    await axios.post({
      url: `/cart/addLoyalty`,
      data: data.data,
      headers: data.headers,
    });

export const removeCartLoyalty =
  (axios = browserAxios) =>
  async (data) =>
    await axios.post({
      url: `/cart/removeLoyalty`,
      headers: data,
    });

export const uploadFiles =
  (axios = browserAxios) =>
  async (data) =>
    await axios.post({
      url: `/files/internal/${data.headers['user-id']}/uploads`,
      data: data.data,
      headers: data.headers,
    });

export const setReturnItemQuantity =
  (axios = browserAxios) =>
  (data) => {
    return axios.post({
      url: `/returns/return-item/${data.returnItemId}`,
      data: data.data,
    });
  };

export const updateReturnRequest =
  (axios = browserAxios) =>
  (data) => {
    return axios.post({
      url: `/returns/updateReturnRequest`,
      data: data,
    });
  };
