import React from 'react';
import styles from '../Status.module.scss';
import { ReactComponent as Cross } from 'assets/icons/charm.svg';
import { ReactComponent as Tick } from 'assets/icons/Tick.svg';
import { ReactComponent as GreenTick } from 'assets/icons/Green Tick.svg';

const TimeStamp = ({ item }) => {
  const {
    isStatusPending = true,
    statusName = '',
    timeStamp = '',
  } = item || {};

  return (
    <div className={styles.buy_steps}>
      <div className={styles.content}>
        <div className={styles.status_icon}>
          {isStatusPending ? (
            <Tick />
          ) : statusName.includes('Cancelled') ? (
            <Cross />
          ) : (
            <GreenTick />
          )}
        </div>
        <div
          className={`${
            isStatusPending ? styles.grey_class : styles.green_class
          } ${styles.timestamps}`}
        >
          {statusName.includes('Cancelled') && !isStatusPending ? (
            <div>
              <p className={styles.cancelled_content}>{statusName} </p>
              <p className={`${styles.date} ${styles.cancelled_content}`}>
                {timeStamp}
              </p>
            </div>
          ) : (
            <div>
              <p>{statusName}</p>
              <p className={styles.date}>{timeStamp}</p>
            </div>
          )}
        </div>
      </div>
      <div
        className={`${
          isStatusPending ? styles.grey_line_class : styles.green_class
        } ${styles.dashed_step_border}`}
      />
    </div>
  );
};

export default TimeStamp;

// "trackingDetails": [
//   {
//       "timeStamp": "20/01/2025 05:24 PM",
//       "statusName": "Order Created",
//       "isStatusPending": false
//   },
//   {
//       "timeStamp": "20/01/2025 05:24 PM",
//       "statusName": "Rx Valid",
//       "isStatusPending": false
//   },
//   {
//       "timeStamp": "11/03/2025 03:49 PM",
//       "statusName": "Order is being processed",
//       "isStatusPending": false
//   },
//   {
//       "timeStamp": "11/03/2025 03:49 PM",
//       "statusName": "Order is being Processed",
//       "isStatusPending": false
//   }
// ],

// "trackingDetails": [
//   {
//       "timeStamp": "27/02/2025 01:14 AM",
//       "statusName": "Return Request Received",
//       "isStatusPending": false
//   },
//   {
//       "timeStamp": "27/02/2025 01:15 AM",
//       "statusName": "Validation By Pharmacy Team",
//       "isStatusPending": false
//   },
//   {
//       "timeStamp": "27/02/2025 01:28 AM",
//       "statusName": "Return Cancelled",
//       "isStatusPending": false
//   },
//   {
//       "timeStamp": "03/03/2025 01:21 PM",
//       "statusName": "Prx Pharmacy Team validated",
//       "isStatusPending": false
//   }
// ],
