import { create } from 'zustand';

const useReturnStore = create((set) => ({
  returnData: {},
  totalItems: 0,
  currentItem: null,
  returnItems: [],

  setReturnData: (returnData) => {
    set({
      returnData: returnData,
      totalItems: returnData?.items?.length,
      returnItems: returnData?.items,
    });
  },

  setReturnItems: (returnItems) => {
    set({
      returnItems: returnItems,
    });
  },

  setCurrentItem: (item) => {
    set({
      currentItem: item,
    });
  },

  resetReturn: () => {
    set({ totalItems: 0, returnData: [] });
  },
}));

export default useReturnStore;
