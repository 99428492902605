import { CloseRounded } from '@mui/icons-material';
import { IconButton, Drawer, Typography, Box } from '@mui/material';

const CustomDrawer = ({
  position,
  title,
  openDrawer,
  setOpenDrawer,
  setItemToAdd,
  children,
  showCloseIcon = true,
  matches,
  iconStyle,
  customClass,
  onClose = () => {},
}) => {
  const handleClose = () => {
    setOpenDrawer && setOpenDrawer(false);
    if (setItemToAdd) setItemToAdd(null);
    onClose();
  };

  return (
    <Drawer
      className={customClass}
      PaperProps={{
        style: {
          maxHeight: matches ? '80vh' : 'auto',
          minWidth: 'auto',
          scrollbarWidth: 'none', // Hide scrollbar for Firefox
          '&::-webkit-scrollbar': {
            // Hide scrollbar for Webkit browsers
            display: 'none',
          },
        },
      }}
      anchor={position}
      open={openDrawer}
      onClose={handleClose}
    >
      {title && (
        <Typography
          variant='h6'
          sx={{ m: 0, p: 2 }}
          id='customized-dialog-title'
          fontWeight={400}
          style={{ textAlign: 'center' }}
        >
          {title}
        </Typography>
      )}
      {!!showCloseIcon && (
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={
            iconStyle || {
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'black',
            }
          }
        >
          <CloseRounded />
        </IconButton>
      )}
      {children}
    </Drawer>
  );
};

export default CustomDrawer;
