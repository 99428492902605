import React, { useEffect, useState } from 'react';
import styles from './AddToCartDropdown.module.scss';
import { CircularProgress, Typography } from '@mui/material';
import useCartStore from 'store/cartStore';
import { setItemQuantity } from 'utils/api';
import { ReactComponent as RightArrow } from 'assets/icons/RightArrow.svg';
import Button from 'components/CustomElements/Button';
import QuantityModal from './quantityModal';
import { enqueueSnackbar } from 'notistack';

const AddToCartDropdown = ({
  drugCode,
  userId,
  cartId,
  openCartModal,
  addButtonStyles = {},
  getAddtoCartDetails,
  isCoupon = false,
  isPriceLoading,
  extraText,
}) => {
  const [item, setItem] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [addToCartloading, setAddToCartLoading] = useState(false);
  const cartItems = useCartStore((state) => state.cartItems);

  const getItemDetailsFromCart = (cartItems) => {
    const itemData = cartItems?.userCart?.find(
      (drug) => drug?.itemCartData?.drugCode === drugCode
    );
    setItem(itemData);
  };

  useEffect(() => {
    getItemDetailsFromCart(cartItems);
  }, [cartItems, drugCode]);

  const addToCart = async (e, increaseQuantityBy = 1) => {
    e.preventDefault();
    try {
      setAddToCartLoading(true);
      const payload = {
        data: {
          drugCode: drugCode,
          quantity: increaseQuantityBy,
        },
        userId,
        cartId,
      };
      const res = await setItemQuantity()(payload);

      const { userCart, priceDetails, couponDetails } = res?.data?.message;
      useCartStore.setState({
        cartItems: res?.data?.message,
        totalItems: userCart.length,
        priceDetails: priceDetails,
        coupon: couponDetails?.couponCode,
      });

      openCartModal && increaseQuantityBy !== 0 && openCartModal();
      if (
        !res.data.message?.userCart.find(
          (drug) => drug?.itemCartData?.drugCode === drugCode
        )
      ) {
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    } finally {
      setAddToCartLoading(false);
      setOpenDropdown(false);
    }
  };

  const handleDropdown = () => {
    setOpenDropdown((prev) => !prev);
  };

  return (
    <>
      {item ? (
        <div className={styles.add_btn}>
          <p className={styles.extraText}>{extraText ? extraText : null}</p>
          <div
            onClick={(e) => e.preventDefault()}
            className={styles['change-cart-btn']}
          >
            <div
              style={{ width: isCoupon && '36px', height: isCoupon && '24px' }}
              className={styles.quantity}
            >
              {item?.itemCartData?.quantity}
            </div>
            <QuantityModal
              openDropdown={openDropdown}
              setOpenDropdown={setOpenDropdown}
              item={item}
              addToCart={addToCart}
            />
            <div
              onClick={() => {
                handleDropdown();
              }}
              className={`${styles.arrow} open-dropdown`}
            >
              <RightArrow />
            </div>
          </div>
        </div>
      ) : (
        <Button
          startIcon={
            addToCartloading && (
              <CircularProgress
                size={addButtonStyles.progressSize || '20px'}
                style={{ color: '#fff' }}
              />
            )
          }
          type={getAddtoCartDetails?.type}
          handleClick={(e) => {
            addToCart(e, 1);
          }}
          customStyles={{
            ...getAddtoCartDetails?.customStyles,
            ...addButtonStyles,
          }}
          disabled={getAddtoCartDetails?.disabled || isPriceLoading}
        >
          <Typography variant='p'>{getAddtoCartDetails?.text}</Typography>
        </Button>
      )}
      {openDropdown && (
        <div
          onClick={() => setOpenDropdown(false)}
          className={styles.overlay}
        ></div>
      )}
    </>
  );
};

export default AddToCartDropdown;
