import React from 'react';
import {
  StyledAccordion,
  StyledAccordionSummary,
} from 'components/CustomElements/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './cartDetails.module.scss';
import statusStyle from '../Status/Status.module.scss';
import ItemDetails from './ItemDetails';

const CartDetails = ({ orderItems, statusId, shipment }) => {
  return (
    <>
      {statusId !== 0 && (
        <StyledAccordion
          className={styles.accordion}
          defaultExpanded={true}
          slotProps={{ transition: { unmountOnExit: true } }}
        >
          <StyledAccordionSummary
            expandIcon={
              <ExpandMoreIcon className={statusStyle.accordion__expand_icon} />
            }
          >
            {shipment ? 'Shipment' : 'Order'} Cart Details
          </StyledAccordionSummary>
          <div className={styles.details_container}>
            {orderItems?.length > 0 &&
              orderItems.map((item, index) => (
                <div key={index}>
                  <ItemDetails item={item} />
                  {index !== orderItems.length - 1 && (
                    <div className={styles.horizontal_divider} />
                  )}
                </div>
              ))}
          </div>
        </StyledAccordion>
      )}
    </>
  );
};

export default CartDetails;
