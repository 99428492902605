import PdfSvg from "../../assets/icons/PdfSvg";
const PDFContainer = ({ width, height, src }) => {
  return src ? (
    <a href={src} target="_blank">
      <PdfSvg width={width} height={height} />
    </a>
  ) : (
    <div href={src} style={{ cursor: "not-allowed" }}>
      <PdfSvg width={width} height={height} />
    </div>
  );
};

export default PDFContainer;
