import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Chip,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const CustomDateRangePicker = ({
  setStartDate,
  setEndDate,
  startDate,
  endDate,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [customRange, setCustomRange] = useState('');

  const open = Boolean(anchorEl);

  const presetRanges = [
    { label: 'Today', value: 'today' },
    { label: 'Yesterday', value: 'yesterday' },
    { label: 'Last 7 Days', value: 'last7Days' },
    { label: 'Last 15 Days', value: 'last15Days' },
    { label: 'Last 30 Days', value: 'last30Days' },
  ];

  const handlePreset = (range) => {
    const now = new Date();
    if (range === 'today') {
      const startOfDay = new Date(now.setHours(0, 0, 0, 0));
      setStartDate(startOfDay);
      setEndDate(new Date());
    } else if (range === 'yesterday') {
      const yesterday = new Date(now);
      yesterday.setDate(yesterday.getDate() - 1);
      const startOfYesterday = new Date(yesterday.setHours(0, 0, 0, 0));
      const endOfYesterday = new Date(yesterday.setHours(23, 59, 59, 999));
      setStartDate(startOfYesterday);
      setEndDate(endOfYesterday);
    } else if (range === 'last7Days') {
      setStartDate(new Date(now - 7 * 24 * 60 * 60 * 1000));
      setEndDate(now);
    } else if (range === 'last15Days') {
      setStartDate(new Date(now - 15 * 24 * 60 * 60 * 1000));
      setEndDate(now);
    } else if (range === 'last30Days') {
      setStartDate(new Date(now - 30 * 24 * 60 * 60 * 1000));
      setEndDate(now);
    }
    setCustomRange(range);
    handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReset = () => {
    setCustomRange('');
    setStartDate(null);
    setEndDate(null);
    handleClose();
  };

  useEffect(() => {
    if (startDate && endDate) {
      setCustomRange('custom');
    }
  }, [startDate, endDate]);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box>
        <Button
          fullWidth
          variant='outlined'
          onClick={handleClick}
          sx={{ p: ' 12px', textWrap: 'nowrap' }}
        >
          {customRange != ''
            ? `${startDate?.toLocaleDateString()} - ${endDate?.toLocaleDateString()}`
            : 'Select Date Range'}
        </Button>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <Box sx={{ display: 'flex', overflowX: 'auto', p: 1 }}>
            {presetRanges.map((range) => (
              <Chip
                key={range.value}
                label={range.label}
                onClick={() => handlePreset(range.value)}
                sx={{ m: 0.5 }}
              />
            ))}
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography variant='subtitle1'>Custom Range</Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '12px',
                p: 1,
                justifyContent: 'space-between',
              }}
            >
              <DatePicker
                label='Start Date'
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
              <DatePicker
                label='End Date'
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
            <Box sx={{ display: 'flex', gap: '12px', p: 1 }}>
              <Button fullWidth variant='contained' onClick={handleClose}>
                Apply
              </Button>
              <Button fullWidth variant='outlined' onClick={handleReset}>
                Reset
              </Button>
            </Box>
          </Box>
        </Menu>
      </Box>
    </LocalizationProvider>
  );
};

export default CustomDateRangePicker;
