import React, { useEffect, useState } from 'react';
import { getPendingOrderItems } from 'utils/api';
import { DataGrid } from '@mui/x-data-grid';
import {
  StyledAccordion,
  StyledAccordionSummary,
} from 'components/CustomElements/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './PendingOrders.module.scss';
import statusStyle from '../Status/Status.module.scss';

const PendingOrders = ({ orderId }) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchItems = async () => {
    try {
      setLoading(true);
      const response = await getPendingOrderItems()(orderId);
      console.log('Res', response);
      setItems(response.data.orderItems);
    } catch (e) {
      console.log('error', e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const columns = [
    {
      field: 'masterDrugCode',
      headerName: 'Master Drug Code',
      width: 140,
      type: 'number',
      valueFormatter: (row, value) => {
        return row ? row.toString() : '';
      },
    },
    {
      field: 'masterDrugName',
      headerName: 'Drug Name',
      width: 250,
      type: 'string',
    },
    {
      field: 'requiredForThisOrder',
      headerName: 'Required Qty',
      type: 'number',
      width: 150,
      valueGetter: (value, row) => row.requiredForThisOrder ?? '-',
    },
    {
      field: 'availableQty',
      headerName: 'Available Qty',
      type: 'number',
      width: 120,
      valueGetter: (value, row) => row.availableQty ?? '-',
    },
    {
      field: 'promisedQty',
      headerName: 'Promised Qty',
      type: 'number',
      width: 120,
      valueGetter: (value, row) => row.promisedQty ?? '-',
    },
  ];

  const rows = items.map((item, index) => ({
    id: index,
    masterDrugCode: item.masterDrugCode,
    requiredQuantity: item.requiredQuantity,
    availableQty: item.availableQty,
    promisedQty: item.promisedQty,
    requiredForThisOrder: item.requiredForThisOrder,
    masterDrugName: item.masterDrugData.displayName,
  }));

  return (
    <StyledAccordion
      className={styles.accordion}
      slotProps={{ transition: { unmountOnExit: true } }}
    >
      <StyledAccordionSummary
        expandIcon={
          <ExpandMoreIcon className={statusStyle.accordion__expand_icon} />
        }
      >
        Pending Orders
      </StyledAccordionSummary>
      <div className={styles.details_container}>
        <div>
          <DataGrid
            rows={rows}
            columns={columns}
            loading={loading}
            hideFooter={true}
            initialState={{
              sorting: {
                sortModel: [{ field: 'requiredForThisOrder', sort: 'desc' }],
              },
            }}
            sx={{
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
              },
            }}
          />
        </div>
      </div>
    </StyledAccordion>
  );
};

export default PendingOrders;
