import React from 'react';
import styles from './order-address.module.scss';
import { addDelimeter } from 'utils/commonFunctions';

const OrderAddress = ({
  address,
  number,
  patientName,
  patientAge,
  userName,
  statusId,
  tokenAmount,
  totalDiscount,
  paymentMode,
  loyaltyAmount,
}) => {
  const totalTokenAmount =
    Number(tokenAmount || 0) + Number(loyaltyAmount || 0);

  return (
    <div className={styles.patient_details}>
      <p className={styles.patient_details__title}>Delivery Details</p>
      {statusId === 0 ? (
        <p className={styles.patient_details__our_message}>
          Our team will contact you soon to confirm your order
        </p>
      ) : (
        <div className={styles.patient_details__items}>
          {statusId !== 0 && (
            <p className={styles.patient_details__item__name}>
              Address:{' '}
              <span className={styles.patient_details__item__name__label}>
                {address}
              </span>
            </p>
          )}
          <p className={styles.patient_details__item__name}>
            Name:{' '}
            <span className={styles.patient_details__item__name__label}>
              {patientName}
            </span>
          </p>
          <p className={styles.patient_details__item__name}>
            Mobile Number:{' '}
            <span className={styles.patient_details__item__name__value}>
              {number}
            </span>
          </p>
          <p className={styles.patient_details__item__name}>
            Age:{' '}
            <span className={styles.patient_details__item__name__value}>
              {patientAge}
            </span>
          </p>

          {totalTokenAmount > 0 &&
            totalTokenAmount?.toFixed(0) !== totalDiscount?.toFixed(0) &&
            paymentMode !== 'Online Payment' && (
              <p className={styles.partial_payment}>
                Partial Payment:{' '}
                <span className={styles.partial_payment__price}>
                  ₹{addDelimeter(totalTokenAmount)} (Online)
                </span>
              </p>
            )}
        </div>
      )}
    </div>
  );
};

export default OrderAddress;
