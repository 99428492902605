export const vendorOptions = [
  { id: '1614352000000145909', name: 'Dinesh Pharma Sales' },
  { id: '1614352000000289045', name: 'Focus Medisales Pvt Ltd' },
  { id: '1614352000000359939', name: 'GANESH MEDICAL' },
  { id: '1614352000000289109', name: 'Generica India Limited' },
  { id: '1614352000000581950', name: 'HEXACARE PHARMACEUTICALS' },
  { id: '1614352000000832788', name: 'Janatha Pharma' },
  { id: '1614352000000223778', name: 'Jupiter Pharma' },
  { id: '1614352000002003826', name: 'Laxmi Healthcare' },
  { id: '1614352000002003931', name: 'Laxmi Hospicare LLP' },
  { id: '1614352000001502997', name: 'Laxmi Pharma' },
  { id: '1614352000000289169', name: 'Mahaveer Medi Sales Pvt Ltd' },
  { id: '1614352000001580872', name: 'MAHAVEER MEDICARE (117)' },
  { id: '1614352000000828446', name: 'Med Pharma' },
  { id: '1614352000000581730', name: 'Mini International' },
  { id: '1614352000003104171', name: 'PHARMA HUB' },
  { id: '1614352000000577376', name: 'pharma link' },
  { id: '1614352000000980278', name: 'PharmaXYL Drug' },
  { id: '1614352000001311732', name: 'Pradeep Distributors' },
  {
    id: '1614352000000289141',
    name: 'Truemeds - Intellihealth Solutions Pvt Ltd',
  },
  { id: '1614352000001381864', name: 'Udaan' },
  { id: '1614352000000289013', name: 'Vardhman Medisales Pvt Ltd' },
  { id: '1614352000001311848', name: 'VPI MEDISALES' },
  { id: '1614352000098390008', name: 'No Stock' },
  { id: '1614352000105831503', name: 'Prx Internal Transfer' },
];

export const today = new Date().toISOString().split('T')[0];

export const agentsBasedOnRoleId = {
  CRM_Agent: 19,
  SUPPORT_AGENT: 22,
};

export const reasonss = [
  'I want to modify Items in my order',
  'Bought item from outside',
  'Delay in Order',
  'Forgot to apply coupon',
  'Other',
  'Facing Payment related issues',
  'Just trying the App',
  'Incorrect Address',
];

export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const returnStatus = {
  280: 'cancelledReturn',
  270: 'orderReturned',
  260: 'RefundProcessed',
  250: 'ItemQualityCheckAtOurFacility',
  240: 'ReturnReceivedAtWarehouse',
  230: 'returnPickupInitiated',
  220: 'PrxPharmacyTeamValidated',
  210: 'partialReturnRequested',
  200: 'returnRequestReceived',
};

export const hypelocalPincodes = [
  560002, 560004, 560009, 560010, 560018, 560019, 560020, 560021, 560023,
  560025, 560028, 560039, 560040, 560044, 560050, 560053, 560055, 560056,
  560059, 560061, 560070, 560072, 560079, 560085, 560086, 560098, 560104,
  560110, 560026,
];

export const chatTemplates = [
  {
    name: 'Initiate Chat',
    value: 'b02c892f-3014-4af2-b497-627fafc3d5e6',
    message:
      'Greetings from PlatinumRx! 💬 Dear Customer, can you please initiate the chat here so that we can share further details with you on WhatsApp? 📲',
  },
  {
    name: 'Medicine Image Request',
    value: 'cba1e5a0-54b4-4863-a5ab-cfba4a623206',
    message:
      'Greetings from PlatinumRx! 😊 Dear Customer, as discussed, could you please share a clear image of the product you’re looking for? Kindly ensure that the name and composition are visible. 📸🔍',
  },
  {
    name: 'Prescription Request',
    value: '0ca82390-b864-467a-96d0-4d09b35f9e0f',
    message:
      'Greetings from PlatinumRx! 😊 Dear Customer, to assist you better, could you please share a clear image of the healthcare note or recommendation? This will help us ensure we place the order for correct items. 🏥📋',
  },
  {
    name: 'Repeat Order',
    value: '6f305dfe-5838-47b4-bb0b-632a741d5726',
    message:
      'Greetings from PlatinumRx! 🌟 Dear Customer, we tried reaching out to you. Would you like us to reorder all the items from your previous order, or would you prefer to modify any of them? Let us know, and we’ll process it right away! 🛍️✨',
  },
  {
    name: 'Address, Patient & Payment Details Request',
    value: '5956f65a-278d-4315-b61d-8cac5079dd4f',
    message:
      'Greetings from PlatinumRx! 🚀 Dear Customer, to confirm your order, please share the following details: 👤Patient Name & Age: Full Name, Age 🏠 Complete Address: Include House No, Street, City & Pincode This will help us process your order smoothly. 😊',
  },
  {
    name: 'Order Already in Transit',
    value: '656ce7ce-0210-4f78-8060-073ff64ce495',
    message:
      'Greetings from PlatinumRx! 🚚 Dear Customer, we tried reaching out to you. It looks like a similar order has already been placed and is on its way. Would you still like to proceed with this order, or do you need any modifications? Let us know! 💖',
  },
  {
    name: 'Stock Unavailability',
    value: '4527ba1c-54e9-44e4-85c6-04b65bbb1e00',
    message:
      'Greetings from PlatinumRx! 😊 Dear Customer, we’re sorry, but some items in your order are currently unavailable. 😔 We apologize for the inconvenience. Please feel free to reach out for any future requirements. 🛒',
  },
  {
    name: 'Pincode Serviceability Issue',
    value: 'aadde4ae-6085-4866-a77b-d4d8c3c6e484',
    message:
      'Greetings from PlatinumRx! 😊 Dear Customer, unfortunately, your pincode is currently unserviceable for temperature-sensitive items. ❄️📦 However, we can still proceed with the remaining items in your order. Let us know how you’d like to proceed!',
  },
  {
    name: 'Restricted Items Notification',
    value: '8b7ffcaa-7954-44d9-9d8f-8d35ff49be98',
    message:
      'Greetings from PlatinumRx! 🚫 Dear Customer, we tried reaching out to you. It looks like one or more items in your order are currently restricted for online sale. Would you like us to proceed with the remaining items? 📋',
  },
  {
    name: 'Temperature-Sensitive Products – Pincode Issue',
    value: '4669b0fc-51c1-4be3-83d2-e10ae3b93c67',
    message:
      'Greetings from PlatinumRx! ❄️ Dear Customer, since your cart contains temperature-sensitive products, and your pincode is currently unserviceable, we won’t be able to deliver them. Would you like to add other products and place the order? Let us know!',
  },
  {
    name: 'Customer Support & Existing Order Follow-Up',
    value: '21fc7d8e-e45a-46e3-b6ad-a5081b507dd3',
    message:
      'Greetings from PlatinumRx! 💙 Dear Customer, if you need any assistance regarding your existing order, please reach out to us via: 📧 Email: care@platinumrx.in 📱 WhatsApp: +91 6366995773 For quick updates, kindly mention your Order ID in your message so we can assist you faster. We’re happy to help! 😊',
  },
];
