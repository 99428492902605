import CustomDrawer from 'components/CustomElements/Drawer';
import './zoom-img-drawer.css';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import PDFContainer from 'components/PDFContainer/PDFContainer';

const ZoomImgDrawer = ({
  openDrawer,
  setOpenDrawer,
  images,
  img,
  changeImg,
  index,
  checkForPDF,
}) => {
  return (
    <CustomDrawer
      position='bottom'
      openDrawer={openDrawer}
      setOpenDrawer={setOpenDrawer}
      showCloseIcon={true}
    >
      <div className='zoom-drawer-container'>
        <div className={'zoom-modal-zoom-text'}>Double click to zoom</div>

        <div className='zoom-drawer-zoomed-img'>
          <TransformWrapper defaultScale={1}>
            {() => (
              <TransformComponent>
                {checkForPDF(img) ? (
                  <PDFContainer
                    width={108}
                    height={108}
                    src={img}
                    alt='Prescription File'
                  />
                ) : (
                  <img
                    className={'drawerImage'}
                    alt='Medicine image'
                    src={img}
                  />
                )}
              </TransformComponent>
            )}
          </TransformWrapper>
        </div>
        <div className='zoom-modal-footer'>
          {images
            .slice(Math.max(0, index - 1), Math.min(index + 3, images.length))
            .map((el, idx) => {
              let highlightClass = '';
              if (index === 0 && idx === 0) {
                highlightClass = 'zoom-modal-footer-image-active';
              } else if (index >= 1 && idx === 1) {
                highlightClass = 'zoom-modal-footer-image-active';
              } else if (index >= images.length - 2 && idx === 2) {
                highlightClass = 'zoom-modal-footer-image-active';
              } else if (index >= images.length - 1 && idx === 3) {
                highlightClass = 'zoom-modal-footer-image-active';
              }

              return (
                <div
                  key={el}
                  onClick={() => changeImg(el)}
                  className={`zoom-modal-footer-image ${highlightClass}`}
                >
                  {checkForPDF(el.url) ? (
                    <PDFContainer alt='Prescription File' />
                  ) : (
                    <img alt='Medicine Image' src={el.url} />
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </CustomDrawer>
  );
};

export default ZoomImgDrawer;
