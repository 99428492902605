'use client';
import React, { useState, useEffect, useCallback } from 'react';
import {
  confirmEditOrder,
  getActiveCartWithCartId,
  getOrderDetails,
  initiateEditOrder,
  setItemQuantity,
} from 'utils/api';
import { Button, CircularProgress } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import styles from './order-details.module.scss';
import BillingDetails from 'containers/OrderPage/BillDetails/BillDetails';
import { useParams } from 'react-router-dom';
import CartItems from 'components/CartItems/index';
import useCartStore from 'store/cartStore';
import AddMedicineModal from 'containers/Polaris/Order/AddMedicineModal/AddMedicineModal';
import { enqueueSnackbar } from 'notistack';
import CouponSection from 'containers/Polaris/Order/CouponSection';
import ConfirmEdit from 'containers/Polaris/Order/ConfirmEdit';
import EditOrderDetails from 'containers/OrderDetails/EditOrderDetails';

const EditOrderDetailsPage = () => {
  const [newCart, setNewCart] = useState({});
  const [loading, setLoading] = useState(true);
  const [cartDetails, setCartDetails] = useState({});
  const priceDetails = useCartStore((state) => state.priceDetails);
  const cartItems = useCartStore((state) => state.cartItems);
  const [openMedicineModal, setOpenMedicineModal] = useState(false);
  const { orderId } = useParams();
  const [addToCartLoading, setAddToCartLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});

  const startEditOrder = async () => {
    const payload = {
      orderId,
    };
    const response = await initiateEditOrder()(payload);
    setNewCart(response.data.newCart);
    fetchActiveCart(response.data.newCart);
    console.log(response);
  };

  const fetchOrderDetails = useCallback(async () => {
    try {
      const payload = { orderId };
      const resp = await getOrderDetails()(payload);
      const user_id = resp.data.order.userId;
      setOrderDetails(resp.data);
      localStorage.setItem('userID', user_id);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [orderId]);

  const fetchActiveCart = async ({ userId, cartId }) => {
    try {
      setLoading(true);
      const payload = { userId, cartId };
      const resp = await getActiveCartWithCartId()(payload);
      useCartStore.getState().setCartItems(resp.data.message);
      useCartStore.getState().setPriceDetails(resp.data.message.priceDetails);
      useCartStore
        .getState()
        .setCoupon(resp.data.message.couponDetails.couponCode);
      setCartDetails(resp.data.message);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const addToCart = async (item) => {
    try {
      const drugCode = item?.masterItemData?.master_drug_code;
      setAddToCartLoading(true);
      const payload = {
        data: {
          drugCode: drugCode,
          quantity: 1,
        },
        userId: newCart.userId,
        cartId: newCart.cartId,
      };
      const res = await setItemQuantity()(payload);
      if (res.data?.message?.error) {
        enqueueSnackbar(res?.data?.message?.error, { variant: 'error' });
        return;
      }
      const { userCart, priceDetails, couponDetails } = res?.data?.message;
      useCartStore.setState({
        cartItems: res?.data?.message,
        totalItems: userCart.length,
        priceDetails: priceDetails,
        coupon: couponDetails?.couponCode,
      });

      if (
        !res.data.message?.userCart.find(
          (drug) => drug?.itemCartData?.drugCode === drugCode
        )
      ) {
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    } finally {
      setAddToCartLoading(false);
    }
  };

  useEffect(() => {
    fetchOrderDetails();
    startEditOrder();
  }, []);

  return (
    <div className={styles.order_details_page}>
      {loading || !newCart ? (
        <div className={styles.loading_container}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className={styles.order_details_container}>
            <div className={styles.order_details}>
              <EditOrderDetails data={orderDetails?.order?.meta} />
              <CartItems
                data={cartItems?.userCart}
                userId={newCart.userId}
                cartId={newCart.cartId}
              />

              <div
                onClick={() => setOpenMedicineModal(true)}
                className={styles.add_medicine_btn}
              >
                <AddCircleIcon style={{ fontSize: '18px', color: '#FC5A5A' }} />
                <p>Add Medicines</p>
              </div>
            </div>

            <div className={styles.bill_details_container}>
              <CouponSection userId={newCart.userId} cartId={newCart.cartId} />
              <BillingDetails
                data={priceDetails}
                order={cartDetails?.order}
                oldPriceDetails={cartDetails?.oldPriceDetails}
                paymentMode={cartDetails?.orderPriceDetailsV2?.paymentMode}
              />
              <ConfirmEdit orderId={orderId} />
            </div>
          </div>

          {openMedicineModal && (
            <AddMedicineModal
              userId={newCart.userId}
              cartId={newCart.cartId}
              selectedData={cartItems?.userCart}
              open={openMedicineModal}
              setOpen={setOpenMedicineModal}
              addMedicine={addToCart}
              toggle={() => setOpenMedicineModal(false)}
              banned={false}
              substituteMedicine={false}
            />
          )}
        </>
      )}
    </div>
  );
};

export default EditOrderDetailsPage;
