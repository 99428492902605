import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { createTask, getCampaigns, getCustomerTasks } from 'utils/api';
import styles from './Tasks.module.scss';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useApp } from 'context/AppContext';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
const CustomerTasks = ({ userId, activeTab }) => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [openNewTaskDialog, setOpenNewTaskDialog] = useState(false);
  const [campaign, setCampaign] = useState(null);
  const navigate = useNavigate();
  const {
    campaignIds,
    setCampaignIds,
    statusFilters,
    setStatusFilters,
    userPhoneNumber,
  } = useApp();

  const statusOptions = {
    1: 'PENDING',
    5: 'ASSIGNED',
    10: 'IN_PROGRESS',
    20: 'COMPLETED',
    50: 'CLOSED',
    60: 'IGNORED',
  };

  const handleRowClick = (params) => {
    const userId = params.row.userId;
    const taskId = params.row.id;
    navigate(`/crm-dashboard/lead-history/${userId}/${taskId}`);
  };

  const fetchTasks = async () => {
    setLoading(true);

    try {
      const data = {
        data: {
          campaignIds:
            campaignIds.length > 0 ? campaignIds.map(Number) : undefined,
          statuses:
            statusFilters.length > 0 ? statusFilters.map(Number) : undefined,
          userId: Number(userId),
        },
        params: { limit: 50, skip: 0 },
      };

      const response = await getCustomerTasks()(data);

      setTasks(response.data.data);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCampaigns = async () => {
    try {
      setLoading(true);
      const params = { skip: 0, limit: 15 };
      const response = await getCampaigns()(params);
      setCampaignOptions(response.data.data);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateNewTask = async () => {
    try {
      const data = {
        userId: Number(userId),
        priority: campaign.priority,
        name: campaign.name,
        campaignId: campaign.id,
        status: 5,
      };
      await createTask()(data);
      enqueueSnackbar('Task created successfully', {
        variant: 'success',
      });
      setOpenNewTaskDialog(false);
      fetchTasks();
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error.response.data.message, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (userId && activeTab === 'tasks') {
      fetchTasks();
    }
  }, [userId, activeTab, campaignIds, statusFilters, userPhoneNumber]);

  useEffect(() => {
    fetchCampaigns();
  }, []);

  const rows = tasks.map((task) => ({
    id: task.id,
    name: task.name,
    campaignId: task.campaignId || 'N/A',
    userId: task.userId || 'N/A',
    activeCartValue: task.activeCartValue,
    status: statusOptions[task.status] || 'N/A',
    createdAt: new Date(task.createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }),
    completedAt: task.completedAt
      ? new Date(task.completedAt).toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        })
      : 'N/A',
    assignedAt: task.assignedAt
      ? new Date(task.assignedAt).toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        })
      : 'N/A',
    agentName: task.agent?.username || '-',
  }));

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'name', headerName: 'Task Name', width: 200 },
    { field: 'userId', headerName: 'User ID', width: 120 },
    { field: 'activeCartValue', headerName: 'Active Cart Value', width: 160 },
    { field: 'status', headerName: 'Status', width: 120 },
    { field: 'createdAt', headerName: 'Created At', width: 180 },
    { field: 'completedAt', headerName: 'completed At', width: 180 },
    { field: 'assignedAt', headerName: 'assigned At', width: 180 },
    { field: 'agentName', headerName: 'Agent Name', width: 150 },
  ];

  return (
    <div className={styles.tasksContainer}>
      <div
        className={styles.filtersContainer}
        style={{ display: 'flex', gap: '10px' }}
      >
        <FormControl variant='outlined' fullWidth>
          <Autocomplete
            id='campaign-autocomplete'
            multiple
            options={campaignOptions.map((campaign) => campaign.name)}
            value={
              campaignIds.map(
                (id) =>
                  campaignOptions.find((campaign) => campaign.id === id)?.name
              ) || []
            }
            onChange={(event, newValue) => {
              const selectedCampaignIds = newValue
                .map((name) => {
                  const selectedCampaign = campaignOptions.find(
                    (campaign) => campaign.name === name
                  );
                  return selectedCampaign ? selectedCampaign.id : null;
                })
                .filter((id) => id !== null);
              setCampaignIds(selectedCampaignIds);
            }}
            renderInput={(params) => (
              <TextField {...params} label='Campaign' variant='outlined' />
            )}
          />
        </FormControl>
        <FormControl variant='outlined' fullWidth>
          <Autocomplete
            id='status-autocomplete'
            multiple
            options={Object.values(statusOptions)}
            value={statusFilters.map((status) => statusOptions[status]) || []}
            onChange={(event, newValue) => {
              const selectedStatusIds = newValue
                .map((name) => {
                  const selectedStatus = Object.keys(statusOptions).find(
                    (key) => statusOptions[key] === name
                  );
                  return selectedStatus ? parseInt(selectedStatus) : null;
                })
                .filter((id) => id !== null);
              setStatusFilters(selectedStatusIds);
            }}
            renderInput={(params) => (
              <TextField {...params} label='Status' variant='outlined' />
            )}
          />
        </FormControl>
        <Button
          fullWidth
          sx={{ height: '56px' }}
          size='small'
          variant='contained'
          color='primary'
          onClick={() => setOpenNewTaskDialog(true)}
        >
          Create New Task
        </Button>
      </div>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        loading={loading}
        autoHeight
        disableSelectionOnClick
        onRowClick={handleRowClick}
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#f5f5f5',
            fontWeight: 'bold',
          },
          '& .MuiDataGrid-footerContainer': {
            backgroundColor: '#f5f5f5',
          },
        }}
      />
      <Dialog
        open={openNewTaskDialog}
        onClose={() => setOpenNewTaskDialog(false)}
      >
        <div className={styles.dialogContainer}>
          <DialogTitle>Create a New Task</DialogTitle>
          <DialogContent>
            <FormControl variant='outlined' fullWidth>
              <InputLabel>Create a New Task</InputLabel>
              <Select
                value={campaign}
                onChange={(e) => {
                  setCampaign(e.target.value);
                }}
                label='Create a New Task'
              >
                {campaignOptions?.map((campaign) => (
                  <MenuItem key={campaign.id} value={campaign}>
                    {campaign.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <Button
            fullWidth
            className={styles.saveButton}
            variant='contained'
            color='primary'
            onClick={handleCreateNewTask}
          >
            Create Task
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default CustomerTasks;
