import React, { useState } from 'react';
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from 'react-zoom-pan-pinch';
import Modal from 'components/CustomElements/Modal';
import './zoom-img-modal.css';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import Reset from '@mui/icons-material/RestartAlt';
import PDFContainer from 'components/PDFContainer/PDFContainer';

const Controls = (customClass) => {
  const { zoomIn, zoomOut, resetTransform } = useControls();

  return (
    <div className={customClass}>
      <ZoomInIcon onClick={() => zoomIn()} />
      <Reset onClick={() => resetTransform()} />
      <ZoomOutIcon onClick={() => zoomOut()} />
    </div>
  );
};

const ZoomImgModal = ({
  openModal,
  setOpenModal,
  images,
  img,
  changeImg,
  index,
  checkForPDF,
  source,
}) => {
  return (
    <Modal
      openModal={openModal}
      setOpenModal={setOpenModal}
      customClass='zoom-img-container'
    >
      <div className={'zoom-modal-container'}>
        <div className={'zoom-modal-zoom-text'}>Double click to zoom</div>
        <div className={'zoom-modal-zoomed-image'}>
          <TransformWrapper defaultScale={1} smooth={true}>
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <>
                <TransformComponent>
                  {source == 'prescription' ? (
                    <div>
                      {checkForPDF(img) ? (
                        <PDFContainer
                          width={108}
                          height={108}
                          src={img}
                          alt='Prescription File'
                        />
                      ) : (
                        <img
                          className={'modalImage'}
                          alt='Medicine Image'
                          src={URL.createObjectURL(img)}
                        />
                      )}
                    </div>
                  ) : (
                    <div>
                      {checkForPDF(img) ? (
                        <PDFContainer
                          width={108}
                          height={108}
                          src={img}
                          alt='Prescription File'
                        />
                      ) : (
                        <img
                          className={'modalImage'}
                          alt='Medicine Image'
                          src={img}
                        />
                      )}
                    </div>
                  )}
                </TransformComponent>
                <Controls customClass='tools' />
              </>
            )}
          </TransformWrapper>
        </div>
        <div className={'hr'}></div>
        <div className='zoom-modal-footer'>
          {images
            .slice(Math.max(0, index - 1), Math.min(index + 3, images.length))
            .map((el, idx) => {
              let highlightClass = '';
              if (index == 0 && idx == 0) {
                highlightClass = 'zoom-modal-footer-image-active';
              } else if (index >= 1 && idx == 1) {
                highlightClass = 'zoom-modal-footer-image-active';
              } else if (index >= images.length - 2 && idx == 2) {
                highlightClass = 'zoom-modal-footer-image-active';
              } else if (index >= images.length - 1 && idx == 3) {
                highlightClass = 'zoom-modal-footer-image-active';
              }

              return (
                <div
                  key={el}
                  onClick={() => changeImg(el, idx)}
                  className={`zoom-modal-footer-image ${highlightClass}`}
                >
                  {checkForPDF(el.file_url) ? (
                    <PDFContainer alt='Prescription File' />
                  ) : (
                    <img alt='Medicine Image' src={el.file_url} />
                  )}
                </div>
              );
            })}
        </div>
        {/* <div className={'zoom-modal-footer'}>
          {images.map((el) => {
            return (
              <div
                key={el}
                onClick={() => changeImg(el)}
                className={`${'zoom-modal-footer-image'} ${
                  img === el.file_url && 'zoom-modal-footer-image-active'
                }`}
              >
                <img alt='Medicine Image' src={el.file_url} />
              </div>
            );
          })}
        </div> */}
      </div>
    </Modal>
  );
};

export default ZoomImgModal;
