import React from 'react';
import styles from '../order-details.module.scss';
import { getFormattedDate } from 'utils/commonFunctions';

const Dates = ({ statusId, placedOn, tentativeDelivery, shipment }) => {
  const formatedDate = getFormattedDate(placedOn);
  return (
    <div className={styles.dates}>
      {!shipment && placedOn && (
        <p className={styles.dates__placed_on}>Placed On: {formatedDate}</p>
      )}
      {statusId > 0 && statusId < 70 && (
        <p className={styles.dates__tentative_delivery}>
          Tentative Delivery:{' '}
          {tentativeDelivery || 'Delivery Date would be updated shortly'}
        </p>
      )}
    </div>
  );
};

export default Dates;
