import { addDelimeter } from 'utils/commonFunctions';

const HowToShowMrp = ({
  mrp,
  discountedPrice,
  drugStock,
  customClass,
  type,
  extratext,
  outerTag,
}) => {
  let Tag = outerTag || 'p';
  const CURRENCY = '₹';
  const isMrpGteDiscountedPrice = Number(mrp) > Number(discountedPrice);
  const isStockAvailable = [1, 2, 6, 3, 7, '1', '2', '3', '6', '7'].includes(
    drugStock
  );

  return (
    <>
      {type &&
      type === 'substitute' &&
      isMrpGteDiscountedPrice &&
      isStockAvailable ? (
        <Tag className={customClass}>
          {extratext} {CURRENCY}
          <span>{addDelimeter(mrp)}</span>
        </Tag>
      ) : (
        <Tag className={customClass}>
          {extratext} {CURRENCY}
          {addDelimeter(mrp)}
        </Tag>
      )}
    </>
  );
};

export default HowToShowMrp;
