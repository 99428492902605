export const getAddtoCart = ({
  banned,
  substituteMedicine,
  type,
  text,
  isMobile,
}) => {
  if (banned) {
    return {
      type: 'outlined',
      customStyles: {
        borderRadius: 8,
        background: 'white',
        color: '#F34D4D',
        border: '1px solid #cccccc ',
        fontSize: type == 'PLP' && isMobile && '10px',
        padding: type == 'PLP' && isMobile && '2px 6px',
      },
      disabled: true,
      text: 'Not for Sale',
    };
  } else if (!substituteMedicine || substituteMedicine == 0) {
    return {
      type: 'containedSquared',
      customStyles: {
        borderRadius: 8,
        background: '#f5f5f5 !important',
        color: '#000 !important',
        border: '1px solid var(--Platinum-RX-Black-100, #CDD1D4)',
        fontSize: type == 'PLP' && isMobile && '10px',
        padding: type == 'PLP' && isMobile && '2px 6px',
      },
      disabled: true,
      text: 'Out of Stock',
    };
  } else {
    return {
      type: 'containedSquared',
      disabled: false,
      text: text || 'Add to Cart',
    };
  }
};
