import React from 'react';
import {
  StyledAccordion,
  StyledAccordionSummary,
} from 'components/CustomElements/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './UserDeliveryDetails.module.scss';
import {
  addCartLoyalty,
  removeCartLoyalty,
  updateInternalUser,
} from 'utils/api';
import { enqueueSnackbar } from 'notistack';
import Address from 'containers/Polaris/Customer/Address';
import Patients from 'containers/Polaris/Customer/Patients';
import { Button, Typography } from '@mui/material';
import useCartStore from 'store/cartStore';

const UserDeliveryDetails = ({
  userData,
  setUserData,
  handleSearch,
  getCurrentAddress,
  getCurrentPatient,
}) => {
  const { priceDetails } = useCartStore();
  const handleUpdateUser = async (data) => {
    try {
      const payload = {
        ...data,
        user_id: userData.userId,
      };
      await updateInternalUser()(payload);
      setUserData({ ...userData, ...data });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, {
        variant: 'error',
      });
    }
  };

  const isEdgeMembershipExpired = () => {
    if (!userData?.edgeMemberExpiry) return true;
    const expiryDate = new Date(userData.edgeMemberExpiry);
    const currentDate = new Date();
    return expiryDate < currentDate;
  };

  const handleAddLoyalty = async () => {
    try {
      const payload = {
        data: {
          duration: 90,
          user_id: userData.userId,
        },
        headers: {
          'user-id': userData.userId,
        },
      };
      const res = await addCartLoyalty()(payload);
      useCartStore.setState({
        cartItems: res?.data?.data?.message,
        totalItems: res?.data?.data?.message?.userCart?.length,
        priceDetails: res?.data?.data?.message?.priceDetails,
      });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, {
        variant: 'error',
      });
    }
  };

  const handleRemoveLoyalty = async () => {
    try {
      const headers = {
        'user-id': userData.userId,
      };

      const res = await removeCartLoyalty()(headers);
      useCartStore.setState({
        cartItems: res?.data?.data?.message,
        totalItems: res?.data?.data?.message?.userCart?.length,
        priceDetails: res?.data?.data?.message?.priceDetails,
      });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, {
        variant: 'error',
      });
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.delivery_to_container}>
        <div>
          <div className={styles.delivery_to}>
            <Typography variant='body2'>
              Delivery To:{' '}
              <span className={styles.delivery_to_name}>
                {getCurrentPatient()?.patient_name}{' '}
                {getCurrentPatient()?.patient_age &&
                  `(${getCurrentPatient()?.patient_age} years old)`}
                {getCurrentPatient()?.patient_gender &&
                  `(${getCurrentPatient()?.patient_gender})`}
              </span>
            </Typography>
          </div>
          <Typography variant='body2'>
            Address:{' '}
            <span className={styles.address}>
              {getCurrentAddress()?.formatted_address}
            </span>
          </Typography>
        </div>
        {isEdgeMembershipExpired() && priceDetails?.loyaltyAmount == 0 && (
          <Button
            onClick={handleAddLoyalty}
            color='secondary'
            variant='contained'
          >
            Add Loyalty
          </Button>
        )}
        {priceDetails?.loyaltyAmount > 0 && (
          <Button
            onClick={handleRemoveLoyalty}
            color='secondary'
            variant='contained'
          >
            Remove Loyalty
          </Button>
        )}
      </div>

      <div className={styles.accordion_container}>
        <StyledAccordion
          className={styles.accordion}
          slotProps={{ transition: { unmountOnExit: true } }}
        >
          <StyledAccordionSummary
            expandIcon={
              <ExpandMoreIcon className={styles.accordion__expand_icon} />
            }
          >
            Address Details
          </StyledAccordionSummary>
          <div className={styles.details_container}>
            <Address
              source='PO'
              userData={userData}
              setUserData={setUserData}
              refetchUserDetails={handleSearch}
              handleUpdateUser={handleUpdateUser}
            />
          </div>
        </StyledAccordion>
        <StyledAccordion
          className={styles.accordion}
          slotProps={{ transition: { unmountOnExit: true } }}
        >
          <StyledAccordionSummary
            expandIcon={
              <ExpandMoreIcon className={styles.accordion__expand_icon} />
            }
          >
            Patient Details
          </StyledAccordionSummary>
          <div className={styles.details_container}>
            <Patients
              source='PO'
              userData={userData}
              handleUpdateUser={handleUpdateUser}
            />
          </div>
        </StyledAccordion>
      </div>
    </div>
  );
};

export default UserDeliveryDetails;
