import React, { useState, useEffect } from 'react';
import { IconButton } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import styles from './upload-prescription.module.scss';
import ZoomImgModal from 'components/Modals/zoom-img-modal';
import PdfSvg from 'assets/icons/PdfSvg';

const UploadedFiles = ({ prescriptionFiles, removeImg, type }) => {
  const [open, setOpen] = useState(false);
  const [selectedImg, setSelectedImg] = useState('');
  const [imageList, setImageList] = useState([]);

  const handleSelectedImg = (img) => {
    setSelectedImg(img);
    setOpen(true);
  };

  useEffect(() => {
    const images = [];
    prescriptionFiles.map((file) => {
      images.push({ id: file.id, url: URL.createObjectURL(file) });
    });
    setImageList(images);
  }, [prescriptionFiles]);

  const checkForPDF = (file) => {
    return file?.type?.includes('pdf');
  };

  const uploadedText =
    prescriptionFiles.length === 1
      ? 'Uploaded Prescription'
      : 'Uploaded Prescriptions';

  return (
    <div>
      <h4 className={styles.uploadedFiles__heading}>{uploadedText}</h4>
      <div
        className={
          type == 'Cart' ? styles.uploadedFilesCart : styles.uploadedFiles
        }
      >
        {prescriptionFiles.map((file, index) => (
          <div
            key={index}
            className={styles.uploadedFiles__item}
            onClick={() => handleSelectedImg(file)}
          >
            {checkForPDF(file) ? (
              <PdfSvg />
            ) : (
              <img
                className={styles.uploadedFiles__image}
                src={URL.createObjectURL(file)}
                alt={`Prescription ${index + 1}`}
              />
            )}
            <IconButton
              style={{
                position: 'absolute',
                right: '-10px',
                top: '-10px',
                background: '#FC5A5A',
                zIndex: 1000,
              }}
              size='small'
              onClick={(e) => removeImg(e, file)}
            >
              <CloseRounded
                style={{ color: '#fff', width: '14px', height: '14px' }}
              />
            </IconButton>
          </div>
        ))}
      </div>

      {open && (
        <ZoomImgModal
          openModal={open}
          setOpenModal={setOpen}
          images={imageList}
          img={selectedImg}
          details={null}
          changeImg={setSelectedImg}
          checkForPDF={checkForPDF}
          source='prescription'
        />
      )}
    </div>
  );
};

export default UploadedFiles;
