import React from 'react';
import styles from '../order-details.module.scss';

const SavingSection = ({ savingAmount, loyaltySavings }) => {
  return (
    <div
      className={`${styles.saving_section} ${
        loyaltySavings ? styles.loyalty_saving_section : ''
      }`}
    >
      <p
        className={`${styles.saving_section__text} ${
          loyaltySavings ? styles.loyalty_saving_text : ''
        }`}
      >
        You saved ₹{savingAmount} on this Medicines
      </p>
    </div>
  );
};

export default SavingSection;
