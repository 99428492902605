export const getFormattedDate = (isoDateString) => {
  const date = new Date(isoDateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};

export const getFormattedDateWithMonthName = (isoDateString) => {
  const date = new Date(isoDateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = date
    .toLocaleString('default', { month: 'long' })
    .substring(0, 3);
  const year = date.getFullYear();
  const formattedDate = `${day} ${month} ${year}`;
  return formattedDate;
};

export const getWeekDay = (isoDateString) => {
  const date = new Date(isoDateString);
  const day = date.toLocaleString('default', { weekday: 'long' });
  return day;
};

export const getTimestamp = (time) => {
  const dateObj = new Date(time);
  const istTime = dateObj.toLocaleString('en-US', {
    timeZone: 'Asia/Kolkata',
    hour: '2-digit',
    minute: '2-digit',
  });
  return istTime;
};

export const getTimeFromSeconds = (time) => {
  const [hours, minutes, seconds] = time.split(':');
  return `${hours > 0 ? `${hours}h ` : ''}${minutes}m ${seconds}s`;
};

export const handlePdpRedirect = (name, code) => {
  const url_name = name?.toLowerCase().replace(/[^a-zA-Z0-9]+/g, '-');
  const encodedMasterDrugCode = encodeURIComponent(code);
  console.log(`/product-display/${url_name}/${encodedMasterDrugCode}`);
  window.open(
    `https://www.platinumrx.in/product-display/${url_name}/${encodedMasterDrugCode}`,
    '_blank'
  );
};

export const handleRedirect = (url) => {
  window.open(url, '_blank');
};

export const getBoxType = (count, coldItemsPresent) => {
  if (coldItemsPresent) {
    return 'ColdChain Item Box';
  } else if (count <= 5) {
    return 'Small Box';
  } else if (count >= 15) {
    return 'Large Box';
  } else {
    return 'Medium Box';
  }
};

export const checkColdItems = (items, setColdItemsPresent) => {
  const check = items?.itemCartData?.some(
    (item) => item?.itemCartData?.coldStorage === true
  );
  setColdItemsPresent(check);
};

export const getFormattedDateAndTime = (isoDateString) => {
  const date = new Date(isoDateString);

  date.setMinutes(date.getMinutes() - 330);

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;

  const formattedTime = `${hours}:${minutes} ${ampm}`;
  const formattedDate = `${day}/${month}/${year} ${formattedTime}`;

  return formattedDate;
};

export const addDelimeter = (num) => {
  if (num) {
    const decimalNumber = Math.round(num * 10) / 10;
    const commaSeperator = Number(decimalNumber);
    return commaSeperator.toLocaleString('en-GB', {
      timeZone: 'UTC',
    });
  }
};

export const getOfferPriceFromCart = (cartItems, drugCode, showTotalPrice) => {
  const itemData = cartItems?.userCart?.find(
    (drug) => drug?.itemCartData?.drugCode == drugCode
  );
  if (showTotalPrice) {
    return (
      itemData?.itemCartData?.offerPrice * itemData?.itemCartData?.quantity
    );
  }
  return itemData?.itemCartData?.offerPrice;
};
