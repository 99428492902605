import React from 'react';
import styles from './cartDetails.module.scss';
import ItemDetails from './ItemDetails';

const CartDetailsReturnView = ({ orderItems }) => {
  return (
    <div className={styles.details_container}>
      {orderItems?.length > 0 &&
        orderItems.map((item, index) => (
          <div
            className={styles.details_container__item}
            key={index}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <ItemDetails item={item} returnItem={true} />
          </div>
        ))}
    </div>
  );
};

export default CartDetailsReturnView;
