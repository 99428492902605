import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import useCartStore from 'store/cartStore';
import { applyCoupon, removeAppliedCoupon } from 'utils/api';
import styles from './CouponSection.module.scss';
import { enqueueSnackbar } from 'notistack';

const CouponSection = ({ userId, cartId }) => {
  const setCoupon = useCartStore((state) => state.setCoupon);
  const coupon = useCartStore((state) => state.coupon);
  const [couponCode, setCouponCode] = useState('');

  const handleSubmit = async () => {
    try {
      const payload = {
        userId,
        cartId,
        data: {
          couponCode,
        },
        headers: {
          'user-id': userId,
        },
      };
      const response = await applyCoupon()(payload);
      useCartStore.getState().setCartItems(response.data.message);
      useCartStore
        .getState()
        .setPriceDetails(response.data.message.priceDetails);
      setCoupon(couponCode);
      enqueueSnackbar('Coupon applied successfully', { variant: 'success' });
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
    }
  };

  const handleRemove = async () => {
    try {
      const payload = {
        userId,
        cartId,
      };
      const response = await removeAppliedCoupon()(payload);
      useCartStore.getState().setCartItems(response.data.message);
      useCartStore
        .getState()
        .setPriceDetails(response.data.message.priceDetails);
      setCoupon(null);
      enqueueSnackbar('Coupon removed successfully', { variant: 'success' });
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
    }
  };

  return (
    <div>
      {coupon ? (
        <div className={styles.coupon_section}>
          <p className={styles.coupon_text}>{coupon}</p>
          <Button variant='contained' onClick={handleRemove}>
            Remove Coupon
          </Button>
        </div>
      ) : (
        <div className={styles.coupon_section}>
          <TextField
            label='Enter Coupon Code'
            variant='outlined'
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
          />
          <Button variant='contained' color='primary' onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      )}
    </div>
  );
};

export default CouponSection;
