import useCartStore from 'store/cartStore';
import { addDelimeter, getOfferPriceFromCart } from 'utils/commonFunctions';

const HowToShowDiscounterPrice = ({
  mrp,
  discountedPrice,
  drugStock,
  customClass,
  type,
  extratext,
  outerTag,
  drugCode,
  showTotalPrice,
}) => {
  let Tag = outerTag || 'p';
  const CURRENCY = '₹';
  const isMrpGteDiscountedPrice = Number(mrp) > Number(discountedPrice);
  const isStockAvailable = [1, 2, 3, 6, 7, '1', '2', '3', '6', '7'].includes(
    drugStock
  );
  const cartItems = useCartStore((state) => state.cartItems);

  return (
    <>
      {type &&
      type === 'substitute' &&
      isMrpGteDiscountedPrice &&
      isStockAvailable ? (
        <Tag className={customClass}>
          {extratext} {CURRENCY}
          {addDelimeter(
            getOfferPriceFromCart(cartItems, drugCode, showTotalPrice) &&
              getOfferPriceFromCart(cartItems, drugCode, showTotalPrice) != 0
              ? getOfferPriceFromCart(cartItems, drugCode, showTotalPrice)
              : discountedPrice
          )}
        </Tag>
      ) : null}
    </>
  );
};

export default HowToShowDiscounterPrice;
