import React, { useState } from 'react';
import { Typography, Radio, FormControlLabel, Button } from '@mui/material';
import Drawer from 'components/CustomElements/Drawer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSnackbar } from 'notistack';
import { reasonss } from 'utils/constants';

const CancelOrder = ({
  isMobile,
  isOpen,
  onClose,
  setDrawerOpen,
  orderDetails,
  warehouseItemIndex,
  handleSubmitReason,
}) => {
  const position = isMobile ? 'bottom' : 'right';
  const [selectedReason, setSelectedReason] = useState(null);
  const id = orderDetails?.order?.split
    ? orderDetails?.warehouseOrders[warehouseItemIndex]?.warehouseOrder?.orderId
    : orderDetails?.order?.orderId;
  const { enqueueSnackbar } = useSnackbar();

  const handleReasonChange = (reason) => {
    setSelectedReason((prevSelectedReason) =>
      prevSelectedReason === reason ? null : reason
    );
  };

  const handleConfirm = () => {
    if (selectedReason) {
      handleSubmitReason(selectedReason, id);
    } else {
      enqueueSnackbar('Select Reason', { variant: 'error' });
    }
  };

  return (
    <Drawer
      customClass='cancelDrawer'
      position={position}
      openDrawer={isOpen}
      setDrawerOpen={setDrawerOpen}
      showCloseIcon={true}
      onClose={onClose}
      matches={isMobile}
      customDrawerStyle={{
        zIndex: 10000,
      }}
    >
      <div style={{ padding: '16px', minWidth: isMobile ? 'auto' : '450px' }}>
        {isMobile ? (
          <div className='rea'>
            <Typography variant='h6' style={{ fontSize: '19px' }}>
              Reason for Cancellation
            </Typography>
            <hr style={{ margin: '7px 0', border: '0.01px solid #eee' }} />
            {reasonss.map((reason, index) => (
              <React.Fragment key={index}>
                <FormControlLabel
                  control={
                    <Radio
                      checked={selectedReason === reason}
                      onChange={() => handleReasonChange(reason)}
                      value={reason}
                      size='15px'
                      style={{
                        color:
                          selectedReason === reason ? '#FC5A5A' : '#FC5A5A',
                      }}
                    />
                  }
                  label={reason}
                  style={{
                    cursor: 'pointer',
                    pointerEvents: 'auto',
                    fontSize: '16px',
                    lineHeight: '1.3',
                  }}
                />
                {index !== reasonss.length - 1 && (
                  <hr
                    style={{ margin: '8px 0', border: '0.01px solid #eee' }}
                  />
                )}
              </React.Fragment>
            ))}
            <Button
              variant='contained'
              color='primary'
              fullWidth
              onClick={handleConfirm}
              style={{ marginTop: '10px' }}
            >
              Confirm
            </Button>
          </div>
        ) : (
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span onClick={onClose}>
                <ArrowBackIcon />
              </span>
              <div style={{ width: '16px' }}></div>
              <Typography variant='h6' style={{ fontSize: '19px' }}>
                Reason for Cancellation
              </Typography>
            </div>
            <hr style={{ margin: '10px 0', border: '0.01px solid #eee' }} />
            <br></br>
            {reasonss.map((reason, index) => (
              <React.Fragment key={index}>
                <FormControlLabel
                  control={
                    <Radio
                      checked={selectedReason === reason}
                      onChange={() => handleReasonChange(reason)}
                      value={reason}
                      size='15px'
                      style={{
                        color:
                          selectedReason === reason ? '#FC5A5A' : '#FC5A5A',
                      }}
                    />
                  }
                  label={reason}
                  style={{
                    cursor: 'pointer',
                    pointerEvents: 'auto',
                    fontSize: '16px',
                    lineHeight: '1.5',
                  }}
                />
                {index !== reasonss.length - 1 && (
                  <hr
                    style={{ margin: '15px 0', border: '0.01px solid #eee' }}
                  />
                )}
              </React.Fragment>
            ))}
            <Button
              variant='contained'
              color='primary'
              fullWidth
              onClick={handleConfirm}
              style={{ marginTop: '20px' }}
            >
              Confirm
            </Button>
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default CancelOrder;
