import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  TextField,
  FormControl,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import {
  assignOrUpdateOwner,
  getAgents,
  getTasksforAdmin,
  getCampaigns,
  bulkCloseTasks,
} from 'utils/api';
import { enqueueSnackbar } from 'notistack';
import styles from './tasksadmin.module.scss';
import { agentsBasedOnRoleId } from 'utils/constants';
import CustomDateRangePicker from 'components/CustomElements/CustomDatepicker';
import dayjs from 'dayjs';
import { useApp } from 'context/AppContext';
import PageLoader from 'components/Loaders/PageLoader';
import SaveIcon from '@mui/icons-material/CopyAll';
import RefreshIcon from '@mui/icons-material/RefreshRounded';

const SupportTasksAdmin = () => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [agents, setAgents] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [open, setOpen] = useState(false);
  const [gridKey, setGridKey] = useState(0);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [rowCount, setRowCount] = useState(0);
  const [dataGridLoading, setDataGridLoading] = useState(false);
  const {
    campaignIds,
    setCampaignIds,
    statusFilters,
    setStatusFilters,
    agentFilter,
    setAgentFilter,
    minCartValue,
    setMinCartValue,
    maxCartValue,
    setMaxCartValue,
    userPhoneNumber,
    setUserPhoneNumber,
  } = useApp();

  const navigate = useNavigate();

  const statusOptions = {
    1: 'PENDING',
    5: 'ASSIGNED',
    10: 'IN_PROGRESS',
    20: 'COMPLETED',
    50: 'CLOSED',
    60: 'IGNORED',
  };

  const fetchAgents = async () => {
    try {
      setLoading(true);
      const data = { roleId: agentsBasedOnRoleId.SUPPORT_AGENT };
      const response = await getAgents()(data);
      setAgents(response.data.data);
    } catch (error) {
      console.error('Error fetching agents:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchLeadTasks = async (page, pageSize) => {
    setDataGridLoading(true);
    try {
      const data = {
        data: {
          campaignIds:
            campaignIds.length > 0 ? campaignIds.map(Number) : [12, 11],
          statuses:
            statusFilters.length > 0 ? statusFilters.map(Number) : undefined,
          internalUserIds: agentFilter || [],
          startDate: fromDate
            ? dayjs(fromDate).format('YYYY-MM-DD')
            : undefined,
          endDate: toDate ? dayjs(toDate).format('YYYY-MM-DD') : undefined,
          minCartValue: Number(minCartValue),
          maxCartValue: Number(maxCartValue),
          userPhoneNumber: userPhoneNumber,
        },
        params: {
          skip: page * pageSize,
          limit: pageSize,
        },
      };
      const response = await getTasksforAdmin()(data);
      setTasks(response.data.data.tasks);
      setRowCount(response.data.data.count || 100);
    } catch (error) {
      console.error('Error fetching lead tasks:', error);
    } finally {
      setLoading(false);
      setDataGridLoading(false);
    }
  };

  const fetchCampaigns = async () => {
    try {
      setLoading(true);
      const params = { skip: 0, limit: 15 };
      const response = await getCampaigns()(params);
      const filterData = response.data.data.filter(
        (campaign) => campaign.type === 'SUPPORT'
      );
      setCampaignOptions(filterData);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleBulkAssign = async () => {
    try {
      const data = { internalUserId: selectedAgent, taskIds: selectedRows };
      await assignOrUpdateOwner()(data);
      setOpen(false);
      setGridKey(gridKey + 1);
      setSelectedRows([]);
      enqueueSnackbar(`Tasks assigned to ${selectedAgent} successfully`, {
        variant: 'success',
      });
      fetchLeadTasks(page, pageSize);
    } catch (error) {
      console.error('Error assigning tasks:', error);
    }
  };

  const handleClose = () => {
    setSelectedAgent(null);
    setOpen(false);
  };

  const handleBulkClose = async () => {
    try {
      const data = { taskIds: selectedRows };

      await bulkCloseTasks()(data);
      enqueueSnackbar(`Tasks closed successfully`, {
        variant: 'success',
      });
      fetchLeadTasks(page, pageSize);
    } catch (error) {
      console.error('Error closing tasks:', error);
    }
  };

  const handleRedirectToDispositions = () => {
    navigate('/crm-dashboard/all-dispositions');
  };

  useEffect(() => {
    fetchCampaigns();
    fetchAgents();
  }, []);

  useEffect(() => {
    fetchLeadTasks(page, pageSize);
  }, [
    statusFilters,
    agentFilter,
    campaignIds,
    fromDate,
    toDate,
    minCartValue,
    maxCartValue,
    userPhoneNumber,
    page,
    pageSize,
    rowCount,
  ]);

  const handleRowClick = (params) => {
    const userId = params.row.userId;
    const taskId = params.row.id;
    navigate(`/crm-dashboard/lead-history/${userId}/${taskId}?admin=${true}`);
  };

  const handleCopy = (params) => {
    navigator.clipboard.writeText(params.row.phoneNumber);
    enqueueSnackbar(
      `Phone number ${params.row.phoneNumber} copied to clipboard`,
      {
        variant: 'success',
      }
    );
  };

  const rows = tasks.map((task) => ({
    id: task.id,
    name: task.name,
    campaignId: task.campaignId || 'N/A',
    phoneNumber: task?.user?.phoneNumber || 'N/A',
    userId: task.userId || 'N/A',
    activeCartValue: Number(task.activeCartValue),
    status: statusOptions[task.status] || 'N/A',
    statusCode: task.status,
    createdAt: new Date(task.createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }),
    agentName: task.agent?.username || '-',
    previousAgent: task.actualOwner?.username || '-',
  }));

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'name', headerName: 'Task Name', width: 200 },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      width: 120,
    },
    {
      field: 'copy',
      headerName: '',
      width: 1,
      type: 'actions',
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label='Save'
            onClick={() => handleCopy(params)}
            color='primary'
          />,
        ];
      },
    },
    { field: 'userId', headerName: 'User ID', width: 120 },
    { field: 'activeCartValue', headerName: 'Active Cart Value', width: 160 },
    { field: 'status', headerName: 'Status', width: 120 },
    { field: 'createdAt', headerName: 'Created At', width: 180 },
    { field: 'agentName', headerName: 'Agent Name', width: 150 },
    { field: 'previousAgent', headerName: 'Previous Agent Name', width: 150 },
  ];

  const isRowSelectable = (params) => {
    return (
      params.row.statusCode === 1 ||
      params.row.statusCode === 5 ||
      params.row.statusCode === 10
    );
  };

  const handlePaginationModelChange = (newModel) => {
    setPage(newModel.page);
    setPageSize(newModel.pageSize);
  };

  return (
    <div className='home'>
      {loading || campaignOptions.length === 0 || agents.length === 0 ? (
        <PageLoader />
      ) : (
        <>
          <div className={styles.filtersContainer}>
            <div className={styles.filters}>
              <FormControl variant='outlined' fullWidth>
                <Autocomplete
                  id='campaign-autocomplete'
                  multiple
                  options={campaignOptions.map((campaign) => campaign.name)}
                  value={
                    campaignIds.map(
                      (id) =>
                        campaignOptions.find((campaign) => campaign.id === id)
                          ?.name
                    ) || []
                  }
                  onChange={(event, newValue) => {
                    const selectedCampaignIds = newValue
                      .map((name) => {
                        const selectedCampaign = campaignOptions.find(
                          (campaign) => campaign.name === name
                        );
                        return selectedCampaign ? selectedCampaign.id : null;
                      })
                      .filter((id) => id !== null);
                    setCampaignIds(selectedCampaignIds);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Campaign'
                      variant='outlined'
                    />
                  )}
                />
              </FormControl>
              <FormControl variant='outlined' fullWidth>
                <Autocomplete
                  id='status-autocomplete'
                  multiple
                  options={Object.values(statusOptions)}
                  value={
                    statusFilters.map((status) => statusOptions[status]) || []
                  }
                  onChange={(event, newValue) => {
                    const selectedStatusIds = newValue
                      .map((name) => {
                        const selectedStatus = Object.keys(statusOptions).find(
                          (key) => statusOptions[key] === name
                        );
                        return selectedStatus ? parseInt(selectedStatus) : null;
                      })
                      .filter((id) => id !== null);
                    setStatusFilters(selectedStatusIds);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label='Status' variant='outlined' />
                  )}
                />
              </FormControl>
              <FormControl variant='outlined' fullWidth>
                <TextField
                  label='User Phone Number'
                  value={userPhoneNumber}
                  onChange={(e) => setUserPhoneNumber(e.target.value)}
                />
              </FormControl>
              <Grid fullWidth>
                <CustomDateRangePicker
                  setStartDate={setFromDate}
                  setEndDate={setToDate}
                  startDate={fromDate}
                  endDate={toDate}
                />
              </Grid>
              {selectedRows.length > 0 && (
                <Button
                  fullWidth
                  size='small'
                  variant='contained'
                  color='primary'
                  style={{ padding: '14px 8px' }}
                  onClick={() => handleBulkClose()}
                >
                  Bulk Close ({selectedRows.length})
                </Button>
              )}
            </div>
            <div className={styles.filters}>
              <FormControl variant='outlined' fullWidth>
                <Autocomplete
                  id='agent-autocomplete'
                  multiple
                  options={agents.map((agent) => agent.username)}
                  value={
                    agentFilter.map(
                      (id) => agents.find((agent) => agent.id === id)?.username
                    ) || []
                  }
                  onChange={(event, newValue) => {
                    const selectedAgentIds = newValue
                      .map((name) => {
                        const selectedAgent = agents.find(
                          (agent) => agent.username === name
                        );
                        return selectedAgent ? selectedAgent.id : null;
                      })
                      .filter((id) => id !== null);
                    setAgentFilter(selectedAgentIds);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Select Agent'
                      variant='outlined'
                    />
                  )}
                />
              </FormControl>
              <TextField
                fullWidth
                type='number'
                label='Min Value'
                value={minCartValue || ''}
                onChange={(e) => setMinCartValue(Number(e.target.value))}
              />
              <TextField
                fullWidth
                type='number'
                label='Max Value'
                value={maxCartValue || ''}
                onChange={(e) => setMaxCartValue(Number(e.target.value))}
              />
              <Button
                size='small'
                fullWidth
                variant='contained'
                color='primary'
                style={{ padding: '14px 8px' }}
                onClick={handleRedirectToDispositions}
              >
                Go to All Dispositions
              </Button>

              {selectedRows.length > 0 && (
                <Button
                  fullWidth
                  size='small'
                  variant='contained'
                  color='primary'
                  style={{ padding: '14px 8px' }}
                  onClick={() => setOpen(true)}
                >
                  Assign to Agent ({selectedRows.length})
                </Button>
              )}
            </div>
            <div className={styles.filters}>
              <div
                className={styles.refreshIcon}
                onClick={() => fetchLeadTasks(page, pageSize)}
              >
                <RefreshIcon />
              </div>
              <div className={styles.Count}>Total Count: {rowCount}</div>
            </div>
          </div>

          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
          >
            <DataGrid
              key={gridKey}
              rows={rows}
              columns={columns}
              autoHeight
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 25, page: 0 },
                },
              }}
              loading={dataGridLoading}
              paginationMode='server'
              pageSizeOptions={[10, 25, 50, 100]}
              paginationModel={{ page, pageSize }}
              onPaginationModelChange={handlePaginationModelChange}
              rowCount={rowCount}
              disableSelectionOnClick
              onRowClick={handleRowClick}
              checkboxSelection
              checkboxSelectionVisibleOnly={true}
              isRowSelectable={isRowSelectable}
              selectionModel={selectedRows}
              onRowSelectionModelChange={(newSelection) => {
                setSelectedRows(newSelection);
              }}
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: '#f5f5f5',
                  fontWeight: 'bold',
                },
                '& .MuiDataGrid-footerContainer': {
                  backgroundColor: '#f5f5f5',
                },
              }}
            />
          </div>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <div className={styles.confirmDialog}>
              <DialogTitle>Assign Tasks</DialogTitle>
              <DialogContent>
                <Autocomplete
                  id='assign-agent-autocomplete'
                  options={agents.map((agent) => agent.username)}
                  onChange={(event, newValue) => {
                    const selectedAgent = agents.find(
                      (agent) => agent.username === newValue
                    );
                    setSelectedAgent(selectedAgent ? selectedAgent.id : null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Select Agent to assign'
                      variant='outlined'
                    />
                  )}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleBulkAssign} color='primary'>
                  Confirm
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        </>
      )}
    </div>
  );
};

export default SupportTasksAdmin;
