import React, { useState } from 'react';
import { Info as InfoIcon } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import styles from '../Status.module.scss';
import Nudge from '../../Nudge';

const CancelButton = ({
  shipment,
  statusId,
  isMobile,
  handleCancelOrder,
  cancelType,
  status,
  price,
}) => {
  const [isToolTipOpen, setOpenTooltip] = useState(false);
  const [isOpenNudge, setisOpenNudge] = useState(false);

  const handleCloseNudge = () => {
    setisOpenNudge(false);
  };

  const handleOpenNudge = () => {
    setisOpenNudge(true);
  };

  const toggleTooltip = () => {
    setOpenTooltip((prev) => !prev);
  };

  return (
    <div>
      {statusId === 70 || statusId == 80 ? null : (
        <div className={styles.accordion__btn_container}>
          <button
            className={`${styles.accordion__cancel_button} ${
              cancelType === 'show Tooltip' ? styles.accordion__disabled : ''
            }`}
            onClick={handleOpenNudge}
            disabled={cancelType === 'show Tooltip'}
          >
            Cancel {shipment ? 'Shipment' : 'Order'}
          </button>
          {cancelType === 'show Tooltip' && (
            <Tooltip
              className={styles.accordion__tooltip}
              setOpenTooltip={setOpenTooltip}
              isToolTipOpen={isToolTipOpen}
              content={
                <div className={styles.accordion__tooltip__message}>
                  {status} orders can’t be cancelled.
                  <br />
                  You may refuse to accept the order at the time of delivery{' '}
                </div>
              }
              icon={
                <InfoIcon
                  fontSize='16px'
                  className={styles.accordion__tooltip__icon}
                  onClick={toggleTooltip}
                />
              }
            />
          )}
        </div>
      )}
      <Nudge
        statusId={statusId}
        isOpenNudge={isOpenNudge}
        setisOpenNudge={setisOpenNudge}
        handleCloseNudge={handleCloseNudge}
        price={price}
        isMobile={isMobile}
        handleCancelOrder={handleCancelOrder}
      />
    </div>
  );
};

export default CancelButton;
