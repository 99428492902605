import React, { useState, useCallback, useEffect } from 'react';
import {
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  Button,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import styles from 'pages/Polaris/Customer/Customer.module.scss';
import { Edit, Delete } from '@mui/icons-material';
import {
  deleteUserAddress,
  addUpdateUserAddress,
  getPinCodeDetails,
  getAutoComplete,
  getGeoCodeDetails,
  getPlaceDetails,
} from 'utils/api';
import { enqueueSnackbar } from 'notistack';

const Address = ({
  source,
  userData,
  setUserData,
  refetchUserDetails,
  handleUpdateUser,
}) => {
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [addressForm, setAddressForm] = useState({
    name: '',
    formatted_address: '',
    pincode: '',
    city: '',
    state: '',
    delivery_phone: '',
    house_no: '',
    locality: '',
  });
  const [addressDialogOpen, setAddressDialogOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [localityInput, setLocalityInput] = useState('');
  const [currentLatLong, setCurrentLatLong] = useState(null);
  const [currentLocationDetails, setCurrentLocationDetails] = useState(null);

  const handleEditAddress = (address) => {
    setSelectedAddress(address);
    setAddressForm({
      name: address.name,
      formatted_address: [
        address?.house_no,
        address?.locality,
        address?.city,
        address?.state,
        address?.pincode,
      ]
        .filter((p) => p)
        .join(' '),
      pincode: Number(address.pincode),
      city: address.city,
      state: address.state,
      delivery_phone: address.delivery_phone,
      house_no: address.house_no,
      locality: address.locality,
    });
    setAddressDialogOpen(true);
    setLocalityInput(address.locality);
  };

  const handleAddressChange = (e) => {
    setAddressForm({ ...addressForm, [e.target.name]: e.target.value });
    if (e.target.name === 'locality') {
      setLocalityInput(e.target.value);
    }
  };

  const handlePincodeChange = async (e) => {
    const pincode = e.target.value;
    setAddressForm({ ...addressForm, pincode });

    if (!isNaN(pincode) && pincode.length === 6) {
      try {
        const response = await getPinCodeDetails()({ pincode });
        if (response?.data[0].Status === 'Success') {
          const city = response.data[0].PostOffice[0].District;
          const state = response.data[0].PostOffice[0].State;
          setAddressForm((prev) => ({ ...prev, city, state }));
        }
      } catch (error) {
        enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      }
    }
  };

  const handleSaveAddress = async () => {
    try {
      const response = await addUpdateUserAddress()({
        ...addressForm,
        formatted_address: [
          addressForm?.house_no,
          addressForm?.locality,
          addressForm?.city,
          addressForm?.state,
          addressForm?.pincode,
        ]
          .filter((p) => p)
          .join(' '),
        locality: localityInput,
        pincode: Number(addressForm.pincode),
        user_id: userData.userId,
        id: selectedAddress ? selectedAddress.id : undefined,
      });
      setUserData(response.data);
      setAddressDialogOpen(false);
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, {
        variant: 'error',
      });
    }
  };

  const handleDeleteAddress = async (addressId) => {
    try {
      await deleteUserAddress()({
        id: addressId,
        user_id: userData.userId,
      });
      refetchUserDetails(userData.userId);
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, {
        variant: 'error',
      });
    }
  };

  const fetchAutoComplete = async (input) => {
    try {
      const resp = await getAutoComplete()(input);
      const Suggestions = resp.data.data.predictions;
      setSuggestions(Suggestions);
    } catch (error) {
      console.error('Error fetching autocomplete', error);
    }
  };

  const handleAutoComplete = (e) => {
    const value = e.target.value;
    setLocalityInput(value);
    if (value) {
      fetchAutoComplete(value);
    } else {
      setSuggestions([]);
    }
  };

  const showPosition = async (position) => {
    try {
      const { lat, lng } = position.coords || position;
      const latlong = `${lat}%2C${lng}`;
      const resp = await getGeoCodeDetails()(latlong);
      setCurrentLocationDetails(resp.data.data);
    } catch (error) {
      enqueueSnackbar(error.message || 'Error fetching location details', {
        variant: 'error',
      });
    }
  };

  const handlePickCurrentLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const newPosition = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCurrentLatLong(newPosition);
          showPosition({ coords: newPosition });
        },
        (error) => {
          enqueueSnackbar(error.message || 'Error fetching current location', {
            variant: 'error',
          });
        }
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  }, [currentLatLong]);

  const handlePlaceDetails = async (placeId) => {
    try {
      const resp = await getPlaceDetails()(placeId);
      const data = resp?.data?.data;
      if (data) {
        setAddressForm((prev) => ({
          ...prev,
          locality: data?.first_line_address || data?.sublocality || '',
          pincode: Number(data?.pincode) || '',
          city: data?.city || '',
          state: data?.state || '',
        }));
        setSuggestions([]);
      }
    } catch (error) {
      console.error('Error fetching place details', error);
    }
  };

  useEffect(() => {
    if (currentLocationDetails) {
      setAddressForm((prev) => ({
        ...prev,
        pincode: currentLocationDetails.pincode || '',
        city: currentLocationDetails.city || '',
        state: currentLocationDetails.state || '',
      }));
      setLocalityInput(
        currentLocationDetails.sublocality ||
          currentLocationDetails.sublocality2 ||
          ''
      );
    }
  }, [currentLocationDetails]);

  const handleCurrentAddressChange = async (event) => {
    const addressId = event.target.value;
    try {
      handleUpdateUser({
        currentAddressId: Number(addressId),
      });
      enqueueSnackbar('Current address updated successfully', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, {
        variant: 'error',
      });
    }
  };

  return (
    <Grid item xs={12}>
      <Card
        className={
          source === 'PO' ? styles.customerCard_po : styles.customerCard
        }
      >
        <CardContent className={styles.customerCard__content}>
          <div className={styles.customerCard__header}>
            <div className={styles.customerCard__header__left}>
              <Typography variant={source === 'PO' ? 'body1' : 'h5'}>
                Addresses
              </Typography>
            </div>
            <Button
              className={styles.customerButton}
              variant='contained'
              onClick={() => {
                setSelectedAddress(null);
                setAddressForm({
                  name: '',
                  formatted_address: '',
                  pincode: null,
                  city: '',
                  state: '',
                  delivery_phone: '',
                  house_no: '',
                  locality: '',
                });
                setAddressDialogOpen(true);
              }}
            >
              Add Address
            </Button>
          </div>
          <RadioGroup
            value={userData?.currentAddressId}
            onChange={handleCurrentAddressChange}
          >
            {userData.addresses.map((address) => (
              <div key={address.id} className={styles.address}>
                <FormControlLabel
                  value={address.id}
                  control={<Radio />}
                  label={
                    <div className={styles.addressContent}>
                      <p className={styles.addressContent__text}>
                        {address.formatted_address}
                      </p>
                      <div className={styles.addressActions}>
                        <IconButton
                          color='default'
                          onClick={(e) => {
                            e.preventDefault(); // Prevent radio button selection
                            handleEditAddress(address);
                          }}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          color='error'
                          onClick={(e) => {
                            e.preventDefault(); // Prevent radio button selection
                            handleDeleteAddress(address.id);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </div>
                    </div>
                  }
                />
              </div>
            ))}
          </RadioGroup>
        </CardContent>
      </Card>

      <Dialog
        open={addressDialogOpen}
        onClose={() => setAddressDialogOpen(false)}
      >
        <div className={styles.addressDialog}>
          <DialogTitle>
            {selectedAddress ? 'Edit Address' : 'Add Address'}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin='dense'
              name='name'
              label='Name'
              type='text'
              fullWidth
              value={addressForm.name}
              onChange={handleAddressChange}
            />

            <TextField
              margin='dense'
              name='house_no'
              label='House No'
              type='text'
              fullWidth
              value={addressForm.house_no}
              onChange={handleAddressChange}
            />
            <div className={styles.locality_container}>
              <TextField
                name='locality'
                label='Locality'
                fullWidth
                value={localityInput}
                onChange={handleAutoComplete}
              />
              <div
                className={styles.locality_suggestions}
                style={{
                  display: suggestions.length > 0 ? 'block' : 'none',
                }}
              >
                {suggestions.map((suggestion) => (
                  <div
                    key={suggestion.place_id}
                    className={styles.suggestion_item}
                    onClick={() => handlePlaceDetails(suggestion.place_id)}
                  >
                    {suggestion.description}
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.pincode_container}>
              <TextField
                margin='dense'
                name='pincode'
                label='Pincode'
                type='tel'
                fullWidth
                inputProps={{
                  maxLength: 6,
                  inputMode: 'numeric',
                }}
                value={addressForm.pincode}
                onChange={handlePincodeChange}
              />
              <div className={styles.current_location_container}>
                <Button
                  className={`prx_btn_1 ${styles.current_location}`}
                  onClick={handlePickCurrentLocation}
                >
                  Pick My Location
                </Button>
              </div>
            </div>
            <TextField
              margin='dense'
              name='city'
              label='City'
              type='text'
              fullWidth
              value={addressForm.city}
              onChange={handleAddressChange}
            />
            <TextField
              margin='dense'
              name='state'
              label='State'
              type='text'
              fullWidth
              value={addressForm.state}
              onChange={handleAddressChange}
            />
            <TextField
              margin='dense'
              name='delivery_phone'
              label='Delivery Phone'
              type='text'
              fullWidth
              value={addressForm.delivery_phone}
              onChange={handleAddressChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAddressDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleSaveAddress}>
              {selectedAddress ? 'Update' : 'Add'}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </Grid>
  );
};

export default Address;
