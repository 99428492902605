import React, { useState } from 'react';
import {
  Container,
  Paper,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';
import styles from './FindWithId.module.scss';
import {
  StyledAccordion,
  StyledAccordionSummary,
} from 'components/CustomElements/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CartDetails from 'containers/QCDetails/CartDetails';
import { getOrderDetails, getOrderDetailsWithBoxCode } from 'utils/api';
import { checkColdItems, getBoxType } from 'utils/commonFunctions';

const FindWithId = () => {
  const [searchInput, setSearchInput] = useState('');
  const [searchType, setSearchType] = useState('boxcode');
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [coldItemsPresent, setColdItemsPresent] = useState(false);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!searchInput.trim()) return;
    console.log(searchType);
    try {
      if (searchType == 'orderId') {
        const data = {
          orderId: Number(searchInput),
        };
        setLoading(true);
        const response = await getOrderDetails()(data);
        checkColdItems(response?.data?.orderItems, setColdItemsPresent);

        setOrder(response?.data);
      } else {
        const data = {
          barcodeSerial: searchInput,
        };
        setLoading(true);
        const response = await getOrderDetailsWithBoxCode()(data);
        checkColdItems(response?.data?.data?.orderItems, setColdItemsPresent);

        setOrder(response?.data?.data);
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='home'>
      <Paper elevation={3} sx={{ p: 3, margin: { xs: 'auto', md: 'unset' } }}>
        <Typography variant='h5' gutterBottom>
          Search by Box Code or Order ID
        </Typography>

        <Box
          component='form'
          onSubmit={handleSearch}
          sx={{
            display: 'flex',
            gap: 2,
            mb: 3,
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <FormControl sx={{ minWidth: 150, mb: { xs: 2, md: 0 } }}>
            <Select
              value={searchType}
              onChange={(e) => setSearchType(e.target.value)}
              size='small'
            >
              <MenuItem value='boxcode'>Box Code</MenuItem>
              <MenuItem value='orderId'>Order ID</MenuItem>
            </Select>
          </FormControl>

          <TextField
            fullWidth
            size='small'
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            placeholder={
              searchType === 'boxcode' ? 'Enter Box Code' : 'Enter Order ID'
            }
            variant='outlined'
          />

          <Button
            type='submit'
            variant='contained'
            disabled={loading}
            sx={{ minWidth: 120, mt: { xs: 2, md: 0 } }}
          >
            {loading ? (
              <CircularProgress size={24} color='inherit' />
            ) : (
              'Search'
            )}
          </Button>
        </Box>

        {order && (
          <div className={styles.hidenContainer}>
            <div className={styles.order_info}>
              <div className={styles.item}>
                <p className={styles.item__title}>Order Id:</p>
                <p className={styles.item__value}>{order?.order?.orderId}</p>
              </div>
              <div className={styles.item}>
                <p className={styles.item__title}>Box Id:</p>
                <p className={styles.item__value}>
                  {order?.order?.barcodeSerial}
                </p>
              </div>
              <div className={styles.item}>
                <p className={styles.item__title}>Rack Id:</p>
                <p className={styles.item__value}>
                  {order?.order?.rackIdentifier}
                </p>
              </div>
              <div className={styles.item}>
                <p className={styles.item__title}>Box Type</p>
                <p className={styles.item__value}>
                  {getBoxType(order?.orderItems?.length, coldItemsPresent)}
                </p>
              </div>
              <div className={styles.item}>
                <p className={styles.item__title}>Payment Type:</p>
                <p className={styles.item__value}>
                  {order?.orderPaymentDetails[0]?.modeOfPayment}
                </p>
              </div>
              <div className={styles.item}>
                <p className={styles.item__title}>SKU Qty:</p>
                <p className={styles.item__value}>
                  {order?.orderItems?.length}
                </p>
              </div>
              <div className={styles.item}>
                <p className={styles.item__title}>Cold SKU's Available:</p>
                <p className={styles.item__value}>
                  {coldItemsPresent ? 'Yes' : 'No'}
                </p>
              </div>
              <div className={styles.item}>
                <p className={styles.item__title}>Value:</p>
                <p className={styles.item__value}>
                  {order?.orderPaymentDetails[0]?.totalAmountPayable}
                </p>
              </div>
            </div>
            <div>
              <StyledAccordion
                className={styles.accordion}
                defaultExpanded={true}
                slotProps={{ transition: { unmountOnExit: true } }}
              >
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                  Order Details
                </StyledAccordionSummary>
                <div className={styles.details_container}>
                  <CartDetails hideHeader={true} orderDetails={order} />
                </div>
              </StyledAccordion>
            </div>
          </div>
        )}
      </Paper>
    </div>
  );
};

export default FindWithId;
