import { styled } from '@mui/material/styles';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

const StyledAccordion = styled(Accordion)`
  line-height: 20px;
  letter-spacing: 0.25px;
  padding: 0 16px;
  border-radius: 8px;
  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)({
  borderRadius: '8px',
  padding: 0,
  fontWeight: 500,
  fontSize: '14px',
  '&.Mui-expanded': {
    minHeight: '0px',
  },
});

const StyledAccordionDetails = styled(AccordionDetails)({});

export { StyledAccordion, StyledAccordionSummary, StyledAccordionDetails };
