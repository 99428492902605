'use client';
import React from 'react';
import { Divider } from '@mui/material';
import styles from './bill-details.module.scss';
import { addDelimeter } from 'utils/commonFunctions';

const BillingDetails = ({ data = [], isMobile, order, oldPriceDetails }) => {
  const {
    shippingCharges = 0,
    originalShippingCharges = 0,
    totalAmountToBePaid = 0,
    paymentMode = '',
    tokenAmount = 0,
    totalDiscount = 0,
    loyaltyAmount = 0,
    totalSavingOnOrder = 0,
  } = data;

  const greenStyle = { color: '#031926' };

  const billDetails = [
    {
      title: 'Item MRP',
      value: 'totalMrp',
    },
    {
      title: 'Price Discount',
      value: 'totalDiscount',
      valueStyle: greenStyle,
      keyStyle: greenStyle,
    },
    {
      title: 'Prepaid discount',
      value: 'prepaidDiscountAmount',
      valueStyle: greenStyle,
      keyStyle: greenStyle,
    },
    {
      title: 'Coupon Discount',
      value: 'couponDiscount',
      valueStyle: greenStyle,
      keyStyle: greenStyle,
    },
    {
      title: 'Platinum Edge membership',
      value: 'loyaltyAmount',
    },
    {
      title: 'PRx Coins',
      value: 'prxCoin',
      valueStyle: greenStyle,
      keyStyle: greenStyle,
    },
    {
      title: 'PRx Cash',
      value: 'prxCash',
      valueStyle: greenStyle,
      keyStyle: greenStyle,
    },
    {
      title: 'Handling & Packaging',
      value: 'packagingAndHandlingCharges',
      style: {
        display: data['packagingAndHandlingCharges'] == 0 ? 'none' : 'flex',
      },
    },
    {
      title: 'Shipping',
      value: 'shippingCharges',
    },
  ];
  return (
    <>
      {isMobile && <p className={styles.title}>Price Summary</p>}
      <div className={styles.bill_details_container}>
        {billDetails.map((charges, index) => (
          <div key={index}>
            {' '}
            {charges.value !== 'shippingCharges' &&
            !data[charges.value] ? null : (
              <div className={styles.bill_item} style={charges.style}>
                <p
                  className={styles.bill_item__title}
                  style={charges.keyStyle || {}}
                >
                  {charges?.title}
                </p>
                <div className={styles.bill_middle_line}></div>
                {charges?.value == 'shippingCharges' && !shippingCharges ? (
                  <div className={styles.bill_item__value_container}>
                    <p className={styles.bill_item__value_container__free}>
                      FREE
                      <span
                        className={
                          styles.bill_item__value_container__value__span
                        }
                      >
                        ₹99
                      </span>
                    </p>
                  </div>
                ) : charges?.value == 'shippingCharges' &&
                  shippingCharges == 49 ? (
                  <p
                    className={styles.bill_item__value_container__value}
                    style={charges.valueStyle || {}}
                  >
                    ₹{shippingCharges}
                    <span
                      className={styles.bill_item__value_container__value__span}
                    >
                      ₹99
                    </span>
                  </p>
                ) : (
                  <p
                    className={styles.bill_item__value_container__value}
                    style={charges.valueStyle || {}}
                  >
                    {charges.valueStyle == greenStyle ? '-' : ''}₹
                    {addDelimeter(data[charges.value])}
                  </p>
                )}
              </div>
            )}
          </div>
        ))}
        <div className={styles.total_savings}>
          <p className={styles.total_savings__title}>Total Savings </p>
          <p className={styles.total_savings__value}>
            -₹{totalSavingOnOrder == 0 ? 0 : addDelimeter(totalSavingOnOrder)}
          </p>
        </div>

        <Divider />
        {(tokenAmount > 0 || loyaltyAmount > 0) &&
        tokenAmount?.toFixed(0) != totalDiscount?.toFixed(0) &&
        paymentMode != 'Online Payment' ? (
          <div className={styles.total_pay}>
            <div className={styles.totalAmount}>
              <p className={styles.total_pay__title}>Grand Total :</p>
              <p className={styles.total_pay__value}>
                ₹
                {totalAmountToBePaid === 0
                  ? 0
                  : addDelimeter(totalAmountToBePaid)}
              </p>
            </div>
            <div className={styles.partialPayemtDetails}>
              <div className={styles.partialPayemtDetails__item}>
                <p>Online:</p>
                <span>
                  ₹{' '}
                  {tokenAmount || loyaltyAmount
                    ? addDelimeter(tokenAmount + loyaltyAmount)
                    : 0}
                </span>
              </div>
              <div className={styles.partialPayemtDetails__item}>
                <p>Cash on Delivery:</p>
                <span>
                  ₹{' '}
                  {tokenAmount || loyaltyAmount
                    ? addDelimeter(
                        totalAmountToBePaid - (tokenAmount + loyaltyAmount)
                      )
                    : 0}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.total_pay_normal}>
            <p className={styles.total_pay_normal__title}>Grand Total :</p>
            <p className={styles.total_pay_normal__value}>
              ₹
              {totalAmountToBePaid === 0
                ? 0
                : addDelimeter(totalAmountToBePaid)}
            </p>
          </div>
        )}
        {paymentMode && (
          <div className={styles.payment_mode}>
            <p className={styles.payment_mode__title}>Payment Mode:</p>
            <p className={styles.payment_mode__value}>{paymentMode}</p>
          </div>
        )}
        {order?.meta?.order_edit_pdf_url && (
          <div className={styles.order_edit}>
            {data.paymentMode === 'Cash On Delivery' ? (
              <p className={styles.order_edit__text}>
                Order was edited.
                <a
                  className={styles.order_edit__link}
                  href={order?.meta?.order_edit_pdf_url}
                  target='_blank'
                >
                  Click here{' '}
                </a>
                for details.
              </p>
            ) : (
              <p className={styles.order_edit__text}>
                Order was edited and amount
                {addDelimeter(
                  oldPriceDetails?.totalAmountToBePaid - totalAmountToBePaid
                )}
                was credited to your original payment source.
                <a
                  className={styles.order_edit__link}
                  href={order?.meta?.order_edit_pdf_url}
                  target='_blank'
                >
                  Click here{' '}
                </a>
                for details.
              </p>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default BillingDetails;
