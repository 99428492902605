import { useEffect, useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import { getUserPrescriptions } from 'utils/api';
import './PrescriptionContainer.css';

import PDFContainer from '../PDFContainer/PDFContainer';
import ZoomImgModal from '../Modals/zoom-img-modal';
import ZoomImgDrawer from '../Drawers/zoom-img-drawer';
import { getFormattedDate } from 'utils/commonFunctions';
import { Download, DownloadOutlined } from '@mui/icons-material';

function PrescriptionContainer({ isMobile, userId, details, source }) {
  const [images, setImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [prx, setPrx] = useState();
  const [date, setDate] = useState([]);

  useEffect(() => {
    const fetchPastPrescription = async () => {
      const cartId = details?.orderDetails?.cartId;
      const response = await getUserPrescriptions()({
        userId: userId,
        cartId: cartId,
      });
      const prxList = [];
      const datelist = [];
      response.data.map((item) => {
        if (item.doctorGeneratedPrescription.length > 0) {
          item.doctorGeneratedPrescription.map((el) => {
            prxList.push({ type: 'Doctor', url: el });
            datelist.push(item.createdAt);
          });
        }
        if (item.userUploadedPrescriptions.length > 0) {
          item.userUploadedPrescriptions.map((el) => {
            prxList.push({ type: 'User', url: el });
            datelist.push(item.createdAt);
          });
        }
      });
      prxList.reverse();
      datelist.reverse();
      setPrx(prxList[0]);
      setImages(prxList);
      setDate(datelist);
    };
    fetchPastPrescription();
  }, []);

  const moveFront = () => {
    setIndex((prev) => {
      if (prev === images.length - 1) return prev;
      else return prev + 1;
    });
  };

  const moveBack = () => {
    setIndex((prev) => {
      if (prev === 0) return prev;
      else return prev - 1;
    });
  };

  const checkForPDF = (prx) => {
    const ext = prx.split('.')[5];
    if (ext == 'pdf') return true;
    else return false;
  };

  useEffect(() => {
    if (images.length === 0) return;
    setPrx(images[index]);
  }, [index]);

  const changeImg = (el) => {
    setIndex(images.indexOf(el));
  };

  return (
    <>
      <div className={`presc-container`}>
        {prx && (
          <div
            className={`prx-validity-dropbox ${
              source === 'polaris' ? 'polaris-prx-validity-dropbox' : ''
            }`}
          >
            <div>{getFormattedDate(date[index])}</div>
            <DownloadOutlined
              color='primary'
              onClick={() => window.open(prx.url, '_blank')}
            />
          </div>
        )}
        <div
          className={`prescription ${
            source === 'polaris' ? 'polaris-prescription' : ''
          }`}
        >
          {index !== 0 && prx && (
            <ArrowBackIosNewIcon
              onClick={moveBack}
              style={{ position: 'absolute', left: '10px', top: '49%' }}
            />
          )}
          {prx && !checkForPDF(prx.url) && (
            <>
              <img
                loading='lazy'
                onClick={() => setOpen(true)}
                src={prx.url}
                alt='Prescription Image'
              />
            </>
          )}
          {prx && checkForPDF(prx.url) && (
            <PDFContainer
              width={108}
              height={108}
              src={prx.url}
              alt='Prescription File'
            />
          )}
          {!prx && <h4>No prescription found!</h4>}
          {index !== images.length - 1 && prx && (
            <ArrowForwardIosIcon
              onClick={moveFront}
              style={{ position: 'absolute', right: '10px', top: '49%' }}
            />
          )}
        </div>

        <div className={'zoom-modal-footer'}>
          {images
            .slice(Math.max(0, index - 1), Math.min(index + 3, images.length))
            .map((el, idx) => {
              let highlightClass = '';
              if (index === 0 && idx === 0) {
                highlightClass = 'zoom-modal-footer-image-active';
              } else if (index >= 1 && idx === 1) {
                highlightClass = 'zoom-modal-footer-image-active';
              } else if (index >= images.length - 2 && idx === 2) {
                highlightClass = 'zoom-modal-footer-image-active';
              } else if (index >= images.length - 1 && idx === 3) {
                highlightClass = 'zoom-modal-footer-image-active';
              }

              return (
                <div
                  key={el}
                  onClick={() => changeImg(el)}
                  className={`zoom-modal-footer-image ${highlightClass}`}
                >
                  {checkForPDF(el.url) ? (
                    <PDFContainer alt='Prescription File' />
                  ) : (
                    <img alt='Medicine Image' src={el.url} />
                  )}
                </div>
              );
            })}
        </div>
      </div>
      {!isMobile && open && (
        <ZoomImgModal
          checkForPDF={checkForPDF}
          openModal={open}
          setOpenModal={setOpen}
          images={images}
          img={prx.url}
          changeImg={changeImg}
          index={index}
        />
      )}
      {isMobile && open && (
        <ZoomImgDrawer
          checkForPDF={checkForPDF}
          openDrawer={open}
          setOpenDrawer={setOpen}
          images={images}
          img={prx.url}
          changeImg={changeImg}
          index={index}
        />
      )}
    </>
  );
}

export default PrescriptionContainer;
