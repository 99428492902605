'use client';
import React, { useState } from 'react';
import styles from './cart-items.module.scss';
import { getAddtoCart } from 'components/AddToCart/utils';
import AddToCartDropdown from 'components/AddToCart/AddToCartDropdown';
import { addDelimeter } from 'utils/commonFunctions';
import HowToShowMrp from 'components/Common/howtoShowMrp';
import HowToShowDiscounterPrice from 'components/Common/howtoShowDiscountedPrice';

const ItemDetails = ({ item, userId, showOnlyColdItems, cartId }) => {
  const [addToCartloading, setAddToCartLoading] = useState(false);
  const { itemCartData, itemCatalogData } = item;

  const getDiscountPercentage = () => {
    const itemTotalPrice = itemCartData.itemTotalPrice;
    const itemTotalDiscountPrice = itemCartData.itemTotalDiscountPrice;
    const discountPercentage = Math.round(
      ((itemTotalPrice - itemTotalDiscountPrice) / itemTotalPrice) * 100
    );
    return discountPercentage;
  };

  const banned = null;
  const substituteMedicine = true;

  if (showOnlyColdItems && !itemCatalogData.coldStorage) {
    return null;
  }

  return (
    <div className={styles.item}>
      <div className={styles.item__flex_container}>
        <div className={styles.item__img}>
          <div
            style={{ backgroundImage: `url("${itemCatalogData.heroImage}")` }}
            className={styles.item__img__image}
          />
          {itemCatalogData.drugCategory === 'RX' && (
            <div className={styles.rx_icon}>Rx</div>
          )}
        </div>
        <div className={styles.item__content}>
          <div className={styles.item__texts}>
            <div>
              <p className={styles.drug_name}>{itemCartData.drugName}</p>
              {!showOnlyColdItems && (
                <div className={styles.manufacturer_details}>
                  <p>{itemCatalogData.manufacturerName}</p>
                  <div className={styles.vertical_divider} />
                  <p>
                    {itemCatalogData?.packForm} of{' '}
                    {itemCatalogData?.packQuantityValueDecimal}{' '}
                    {itemCatalogData?.unitOfMeasurement ||
                      itemCatalogData?.drugForm}
                  </p>
                </div>
              )}
            </div>
            {!showOnlyColdItems && itemCartData.couponDiscount && (
              <p className={styles.coupon_discount}>
                Coupon Discount: ₹{addDelimeter(itemCartData.couponDiscount)}
              </p>
            )}
            <div className={styles.price_container}>
              <div className={styles.price_container__info}>
                <HowToShowDiscounterPrice
                  drugCode={itemCatalogData.masterDrugCode}
                  mrp={itemCartData.itemTotalPrice}
                  discountedPrice={
                    itemCartData.couponDiscount
                      ? itemCartData.itemTotalDiscountPrice -
                        itemCartData.couponDiscount
                      : itemCartData.itemTotalDiscountPrice
                  }
                  drugStock={itemCatalogData.drugStock}
                  customClass={styles.price_container__discounted}
                  type='substitute'
                  showTotalPrice={true}
                />
                <HowToShowMrp
                  mrp={itemCartData.itemTotalPrice}
                  discountedPrice={itemCartData.itemTotalDiscountPrice}
                  drugStock={itemCatalogData.drugStock}
                  customClass={styles.price_container__mrp}
                  type='substitute'
                  extratext={null}
                />
                <div className={styles.discountPercentage}>
                  {getDiscountPercentage()}% OFF
                </div>
              </div>
              {!showOnlyColdItems && (
                <AddToCartDropdown
                  type='Cart'
                  userId={userId}
                  cartId={cartId}
                  smallButton={true}
                  showTrashButton={true}
                  drugCode={itemCatalogData.masterDrugCode}
                  discountedPrice={itemCatalogData.discountedPrice}
                  offerPrice={itemCatalogData.offerPrice}
                  addToCartloading={addToCartloading}
                  setAddToCartLoading={setAddToCartLoading}
                  getAddtoCartDetails={getAddtoCart({
                    banned,
                    substituteMedicine,
                    type: 'PLP',
                    text: 'Add',
                    isMobile: true,
                  })}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {!showOnlyColdItems &&
        (itemCartData?.flow || itemCatalogData.coldStorage) && (
          <div className={styles.chips_container}>
            {itemCatalogData.coldStorage && (
              <div className={styles.chip}>Cold Storage</div>
            )}
            {itemCatalogData.coldStorage && (
              <div className={styles.chip}>Non Returnable</div>
            )}
            {itemCartData?.baseDrugName && itemCartData?.flow == 'reorder' && (
              <div className={styles.item__img__tooltips}>
                <p className={styles.ReplaceMent__icon}>Re</p>
              </div>
            )}
          </div>
        )}
    </div>
  );
};

const CartItemsReturn = ({ data, userId, showOnlyColdItems, cartId }) => {
  return (
    <div>
      <div className={styles.items_container}>
        {data?.map((item, index) => (
          <ItemDetails
            key={index}
            item={item}
            userId={userId}
            showOnlyColdItems={showOnlyColdItems}
            cartId={cartId}
          />
        ))}
      </div>
    </div>
  );
};

export default CartItemsReturn;
