import { create } from 'zustand';

const useCartStore = create((set) => ({
  cartItems: [],
  totalItems: 0,
  currentItem: null,
  address: null,
  subtituteMedicine: null,
  drawerOpen: null,
  priceDetails: {},
  coupon: '',
  drugStock: 1,
  banned: null,
  addBtnType: {
    substituteMedicine: true,
    drugStock: 0,
    banned: 0,
  },
  setAddress: (data) => {
    set({ address: data });
  },
  setDrawerOpen: (drawer) => {
    set({ drawerOpen: drawer });
  },
  setCartItems: (cartData) => {
    set({ cartItems: cartData, totalItems: cartData?.userCart?.length });
  },
  setCurrentItem: (item) => {
    if (item?.hasSubstitute)
      set({
        currentItem: item,
      });
    else set({ currentItem: null });
  },

  setBtnType: (data) => {
    if (data) {
      set({ addBtnType: data });
    }
  },
  setPriceDetails: (cartData) => {
    set({ priceDetails: cartData });
  },
  setCoupon: (couponCode) => {
    set({ coupon: couponCode });
  },
  resetCart: () => {
    // Reset the cart state to initial values
    set({ totalItems: 0, cartItems: [], priceDetails: {}, coupon: '' });
    localStorage.removeItem('cartTotalItems');
  },
}));

export default useCartStore;
