import React from 'react';
import styles from './nudge.module.scss';
import Drawer from 'components/CustomElements/Drawer';
import Modal from 'components/CustomElements/Modal';
import nudgeAnimation from 'assets/lottie/Nudge/nudge_animation.gif';
import { Button } from '@mui/material';

const NudgeItem = ({
  price,
  handleCancelOrder,
  handleCloseNudge,
  statusId,
}) => {
  const handleCancel = () => {
    handleCancelOrder();
    handleCloseNudge();
  };

  const handleNo = () => {
    handleCloseNudge();
  };

  return (
    <div className={styles.nudgeItem}>
      <div className={styles.nudgeItem__content}>
        <div className={styles.nudgeItem__price_container}>
          <img
            className={styles.nudgeGif}
            src={nudgeAnimation}
            alt='nudge_animation'
          />
          <p className={styles.nudgeItem__price}>
            {statusId === 0 ? '' : `₹${price}`}
          </p>
        </div>
        <p className={styles.nudgeItem__text}>
          {statusId === 0
            ? 'You are missing on Great Savings,'
            : 'Your saving on this Order,'}
          <br /> Are you sure, you want to cancel the order
        </p>
      </div>
      <div className={styles.nudgeItem__btn_container}>
        <Button
          variant='outlined'
          onClick={() => handleCancel()}
          className={styles.cancel_btn}
        >
          Yes, Cancel
        </Button>
        <Button onClick={() => handleNo()} className={styles.no_btn}>
          No
        </Button>
      </div>
    </div>
  );
};

const Nudge = ({
  statusId,
  isMobile,
  isOpenNudge,
  setisOpenNudge,
  handleCloseNudge,
  handleCancelOrder,
  price,
}) => {
  return (
    <>
      {isMobile ? (
        <Drawer
          position='bottom'
          openDrawer={isOpenNudge}
          setDrawerOpen={setisOpenNudge}
          showCloseIcon={true}
          onClose={handleCloseNudge}
        >
          <NudgeItem
            statusId={statusId}
            handleCancelOrder={handleCancelOrder}
            handleCloseNudge={handleCloseNudge}
            price={price}
          />
        </Drawer>
      ) : (
        <Modal
          openModal={isOpenNudge}
          showCloseIcon={true}
          setOpenModal={setisOpenNudge}
          onClose={handleCloseNudge}
        >
          <NudgeItem
            statusId={statusId}
            handleCloseNudge={handleCloseNudge}
            price={price}
            handleCancelOrder={handleCancelOrder}
          />
        </Modal>
      )}
    </>
  );
};

export default Nudge;
