'use client';
import React, { useState, useEffect } from 'react';
import Header from './Header/Header';
import './Layout.css';
import { ThemeProvider } from '@mui/material';
import { lightTheme, darkTheme } from 'theme';
import { useApp } from 'context/AppContext';

const Layout = ({ title, children }) => {
  const { sideBarExpanded } = useApp();

  const [themeMode, setThemeMode] = useState(
    localStorage.getItem('themeMode') || 'light'
  );

  useEffect(() => {
    // Update the `data-theme` attribute on the <html> element
    document.documentElement.setAttribute('data-theme', themeMode);
    // Save the current theme to localStorage
    localStorage.setItem('themeMode', themeMode);
  }, [themeMode]);

  const toggleTheme = () => {
    setThemeMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  return (
    <ThemeProvider theme={themeMode === 'light' ? lightTheme : darkTheme}>
      <div className={`layout ${sideBarExpanded ? 'expanded' : 'notExpanded'}`}>
        <Header title={title} toggleTheme={toggleTheme} themeMode={themeMode} />
        {children}
      </div>
    </ThemeProvider>
  );
};

export default Layout;
