import { createContext, useContext, useState } from 'react';
const AppContext = createContext();

function AppProvider({ children }) {
  const [cartData, setCartData] = useState([]);
  const [digitizedPrx, setDigitizedPrx] = useState([]);
  const [doctorCart, setDoctorCart] = useState([]);
  const [campaignIds, setCampaignIds] = useState([]);
  const [statusFilters, setStatusFilters] = useState([5, 10]);
  const [agentFilter, setAgentFilter] = useState([]);
  const [minCartValue, setMinCartValue] = useState(null);
  const [maxCartValue, setMaxCartValue] = useState(null);
  const [userPhoneNumber, setUserPhoneNumber] = useState('');
  const [sideBarExpanded, setsideBarExpanded] = useState(false);
  const [showSideBar, setShowSideBar] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [customerParams, setCustomerParams] = useState({
    searchValue: '',
    searchType: 'userId',
  });
  const [orderParams, setOrderParams] = useState({
    searchValue: '',
    searchType: 'orderIds',
  });
  const [returnStatusParams, setReturnStatusParams] = useState([200, 210]);
  return (
    <AppContext.Provider
      value={{
        cartData,
        setCartData,
        digitizedPrx,
        setDigitizedPrx,
        doctorCart,
        setDoctorCart,
        campaignIds,
        setCampaignIds,
        statusFilters,
        setStatusFilters,
        agentFilter,
        setAgentFilter,
        minCartValue,
        setMinCartValue,
        maxCartValue,
        setMaxCartValue,
        userPhoneNumber,
        setUserPhoneNumber,
        fromDate,
        setFromDate,
        toDate,
        setToDate,
        // start side bar
        sideBarExpanded,
        setsideBarExpanded,
        showSideBar,
        setShowSideBar,
        customerParams,
        setCustomerParams,
        orderParams,
        setOrderParams,
        returnStatusParams,
        setReturnStatusParams,
        // end side bar
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export const useApp = () => {
  return useContext(AppContext);
};

export default AppProvider;
