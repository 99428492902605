import React from 'react';
import {
  StyledAccordion,
  StyledAccordionSummary,
} from 'components/CustomElements/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './BillingAccordion.module.scss';
import statusStyle from '../Status/Status.module.scss';
import BillingDetails from 'containers/OrderPage/BillDetails/BillDetails';

const BillingAccordion = ({ data, order, oldPriceDetails, paymentMode }) => {
  return (
    <>
      <StyledAccordion
        className={styles.accordion}
        slotProps={{ transition: { unmountOnExit: true } }}
      >
        <StyledAccordionSummary
          expandIcon={
            <ExpandMoreIcon className={statusStyle.accordion__expand_icon} />
          }
        >
          Billing Details
        </StyledAccordionSummary>
        <div className={styles.details_container}>
          <BillingDetails
            warehouse={true}
            data={data}
            order={order}
            oldPriceDetails={oldPriceDetails}
            paymentMode={paymentMode}
          />
        </div>
      </StyledAccordion>
    </>
  );
};

export default BillingAccordion;
