import { useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import { Menu, MenuItem } from '@mui/material';
import { useState, useEffect } from 'react';

import { useAuth } from '../../../context/authContext';
import './Header.css';
import ToggleThemeButton from 'components/ToggleThemeButton';
import ChangeCRMAgentStatus from 'containers/ManageTables/ChangeCRMAgentStatus/index';
import { useApp } from 'context/AppContext';
import { getFrequentDetails } from 'utils/api';
import { getFormattedDate, getTimestamp } from 'utils/commonFunctions';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material';

function Header({ title, toggleTheme, themeMode }) {
  const theame = useTheme();
  const { setLogin, setProfile, setPermissions } = useAuth();
  const { sideBarExpanded, showSideBar, setShowSideBar, setsideBarExpanded } =
    useApp();
  const isMobile = useMediaQuery(theame.breakpoints.down('sm'));
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [data, setData] = useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGetDetailsEveryLogin = async () => {
    try {
      const response = await getFrequentDetails()(); // Assuming this is your API call
      setData(response.data.data);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  useEffect(() => {
    handleGetDetailsEveryLogin();
  }, []);

  const logout = () => {
    localStorage.removeItem('platinumRxDashboardUserDetail');
    localStorage.removeItem('platinumRxDashboardToken');
    localStorage.removeItem('user_id');
    setLogin(false);
    setProfile(null);
    setPermissions([]);
  };

  const handleHome = () => {
    navigate('/');
  };

  const toggleSideBar = () => {
    if (!showSideBar) {
      setShowSideBar(true);
      setsideBarExpanded(true);
    } else {
      setShowSideBar(false);
    }
  };

  return (
    <nav
      className={`header-nav ${sideBarExpanded ? '' : 'headerCollapsed'} ${
        showSideBar && 'showWhiteTitle'
      }`}
    >
      <div className='leftContainer'>
        {isMobile ? (
          <MenuIcon
            onClick={() => toggleSideBar()}
            style={{
              cursor: 'pointer',
            }}
          />
        ) : (
          <KeyboardBackspaceIcon
            onClick={() => navigate(-1)}
            style={{
              cursor: 'pointer',
              borderRadius: '999px',
              border: '3px solid white',
            }}
          />
        )}
        <p
          style={{
            cursor: 'pointer',
          }}
          onClick={() => handleHome()}
        >
          {title}
        </p>
      </div>
      <div className='rightContainer'>
        <ChangeCRMAgentStatus detailsStatus={data?.status} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{ marginRight: '10px', textAlign: 'right' }}>
            {data && <div style={{ fontSize: '14px' }}>{data.username}</div>}
            {data?.statusLogs?.createdAt && (
              <div className='header-date' style={{ fontSize: '12px' }}>
                {getFormattedDate(data?.statusLogs[0]?.createdAt)}{' '}
                <span>{getTimestamp(data?.statusLogs[0]?.createdAt)}</span>
              </div>
            )}
          </div>

          <AccountCircleIcon
            onClick={handleClick}
            style={{ marginRight: '5px' }}
          />
        </div>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => navigate('/profile')}>Profile</MenuItem>
          <MenuItem onClick={logout}>Logout</MenuItem>
          <MenuItem>
            <ToggleThemeButton
              toggleTheme={toggleTheme}
              themeMode={themeMode}
            />
          </MenuItem>
        </Menu>
      </div>
    </nav>
  );
}

export default Header;
