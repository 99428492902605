import React, { useState, useEffect } from 'react';
import styles from './upload-prescription.module.scss';
import ZoomImgModal from 'components/Modals/zoom-img-modal';

const UploadedFiles = ({ reasonImgs, removeImg, type, showText = true }) => {
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [selectedImg, setSelectedImg] = useState('');
  const [imageList, setImageList] = useState(reasonImgs);

  const handleSelectedImg = (img, index) => {
    setSelectedImg(img.file_url);
    setIndex(index);
    setOpen(true);
  };

  const handleChangeImg = (img, index) => {
    setSelectedImg(img.file_url);
    setIndex(index);
  };

  useEffect(() => {
    setImageList(reasonImgs);
  }, [reasonImgs]);

  const checkForPDF = (img) => {
    const ext = img.split('.')[5];
    if (ext == 'pdf') return true;
    else return false;
  };

  const uploadedText =
    reasonImgs.length === 1 ? 'Uploaded Image' : 'Uploaded Images';

  return (
    <div>
      <div
        className={
          type == 'Cart' ? styles.uploadedFilesCart : styles.uploadedFiles
        }
      >
        {reasonImgs.map((file, index) => (
          <div
            key={index}
            className={styles.uploadedFiles__item}
            onClick={() => handleSelectedImg(file, index)}
          >
            <img
              className={styles.uploadedFiles__image}
              src={file.file_url}
              alt={`Prescription ${index + 1}`}
            />
          </div>
        ))}
      </div>
      {showText && (
        <h4 className={styles.uploadedFiles__heading}>{uploadedText}</h4>
      )}

      {open && (
        <ZoomImgModal
          openModal={open}
          index={index}
          setOpenModal={setOpen}
          images={imageList}
          img={selectedImg}
          details={null}
          changeImg={handleChangeImg}
          checkForPDF={checkForPDF}
        />
      )}
    </div>
  );
};

export default UploadedFiles;
