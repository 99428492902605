import React from 'react';
import styles from './upload-prescription.module.scss';
import { AddAPhotoOutlined } from '@mui/icons-material';
import UploadedFiles from './uploaded-files';
import ComponentLoader from 'components/Loaders/ComponentLoader';

const UploadFile = ({
  removeImg,
  handleFileSelect,
  prescriptionFiles,
  id,
  uploading,
}) => {
  return (
    <div className={styles.contentContainer}>
      <div width='fit-content' gap='20px'>
        <label htmlFor={id} className={styles.uploadLabel}>
          {uploading ? (
            <ComponentLoader />
          ) : (
            <>
              <AddAPhotoOutlined style={{ fontSize: 24 }} color='primary' />
              <p>Upload Img</p>
            </>
          )}
        </label>
        <input
          type='file'
          id={id}
          accept='image/*, application/pdf'
          style={{ display: 'none' }}
          onChange={(e) => handleFileSelect(e.target.files)}
          multiple
          disabled={prescriptionFiles.length >= 5}
        />
      </div>

      {!!prescriptionFiles.length && (
        <UploadedFiles
          removeImg={removeImg}
          prescriptionFiles={prescriptionFiles}
        />
      )}
    </div>
  );
};

export default UploadFile;
