import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import React, { useState } from 'react';
import { confirmEditOrder } from 'utils/api';
import styles from './ConfirmEdit.module.scss';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

const ConfirmEdit = ({ orderId }) => {
  const [description, setDescription] = useState('');
  const [reason, setReason] = useState('');
  const navigate = useNavigate();

  const handleEdit = async () => {
    try {
      const payload = {
        orderId,
        data: {
          description,
          reason,
        },
      };
      const response = await confirmEditOrder()(payload);
      navigate('/polaris/order-details/' + orderId);
      console.log(response);
    } catch (error) {
      console.log(error);
      enqueueSnackbar(
        error?.response?.data?.message || 'Failed to confirm edit',
        {
          variant: 'error',
        }
      );
    }
  };

  return (
    <div className={styles.confirm_edit}>
      <TextField
        label='Description'
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        fullWidth
      />
      <FormControl fullWidth>
        <InputLabel>Preferred Reason</InputLabel>
        <Select
          input={<OutlinedInput label='Preferred Reason' />}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          fullWidth
        >
          <MenuItem value='Item Shortage in WH'>Item Shortage in WH</MenuItem>
          <MenuItem value='CX requested'>CX requested</MenuItem>
          <MenuItem value='MRP Mismatch'>MRP Mismatch</MenuItem>
        </Select>
      </FormControl>
      <Button variant='contained' onClick={handleEdit}>
        Confirm Edit
      </Button>
    </div>
  );
};

export default ConfirmEdit;
