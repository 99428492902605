import { Button } from '@mui/material';
import React from 'react';

const CallRecording = ({ src }) => {
  const handleOpenLink = () => {
    window.open(src, '_blank');
  };
  return (
    <div>
      <Button variant='outlined' onClick={handleOpenLink}>
        Check Call Recording
      </Button>
      {/* <audio controls>
        <source src={src} type='audio/mpeg' />
        Your browser does not support the audio element.
      </audio> */}
    </div>
  );
};

export default CallRecording;
