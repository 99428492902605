import { useState, useEffect } from 'react';
import styles from './HadAConversation.module.scss';
import {
  Select,
  MenuItem,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { createTask, getCampaigns, patchDisposition } from 'utils/api';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

const HadAConversation = ({ userId, taskId, activeTab }) => {
  const [status, setStatus] = useState('');
  const [disposition, setDisposition] = useState('');
  const [subDisposition, setSubDisposition] = useState('');
  const [medicineRemaining, setMedicineRemaining] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [notes, setNotes] = useState('');
  const [markAsCompleted, setMarkAsCompleted] = useState(true);
  const [willCreateNewTask, setWillCreateNewTask] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [campaign, setCampaign] = useState(null);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const dispositionOptions = {
    Connected: [
      'Open',
      'Interested in Ordering',
      'Interested-Others',
      'Not Interested in Ordering',
      'Dead Lead',
      'Post Order Concerns/Feedback',
      'Repeat Customer',
    ],
    'Not Connected': ['Open', 'Dead Lead'],
  };

  const subDispositionOptions = {
    Open: ['RNR', 'Busy', 'Callback'],
    'Interested in Ordering': [
      'Website Link Shared',
      'Order Link Shared',
      'Ordering Completed On Call',
    ],
    'Interested-Others': [
      'Substitute Not Available on PlatinumRx',
      'Will Browse And Order Later',
      'Expected Discount On Listed Price',
      'Plan To Purchase Medicines later',
      'Non Operational City',
      'Wants To Receive Medicine On Exact Day',
      'Already Bought From PRx',
      'Wants A Quicker Delivery Time',
      "Doesn't Want to Pay for Delivery",
      'Bought From Outside; Will order next time',
      'Will consult doctor for substitute medicine',
      'Medicine Short supply/Discontinued',
    ],
    'Not Interested in Ordering': [
      'Wants To Buy Prescribed Medicines Only',
      "Doesn't Plan To Buy Online",
      'Will only buy from current source',
      "Doesn't Trust PlatinumRx",
    ],
    'Dead Lead': [
      "Number Doesn't Exist",
      'Test Lead',
      'Do Not Call (DNC)',
      'Non-Patient',
      'Need Non Rx medicine (Vet, FMCG, etc.)',
      'Language Barrier',
      'Banned Items',
      'Call on different number',
    ],
    'Post Order Concerns/Feedback': ['Post Order Concern/Feedback'],
    'Repeat Customer': [
      'Meds discontinued by doctor',
      'Medicine not required anymore',
      'Was one-time purchase (Acute, FMCG)',
      'Medicine not available on website',
      'Stock still available',
      'Price has increased',
      'Better Price was available outside',
      'Substitute not giving same effects',
      'Doctor asked to switch to original meds',
      'Forgot to order from PRx',
      'Was unable to contact PRx for order',
      'Poor experience with previous order',
      'Delivery TAT was high',
      "Delivery wasn't avaible on this pincode",
      'Tech issue [Payment not working, Website / App not loading]',
      'Order from a different Phone number',
      'Out of home location',
    ],
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    setDisposition('');
    setSubDisposition('');
  };

  const handleDispositionChange = (e) => {
    setDisposition(e.target.value);
    setSubDisposition('');
  };

  const fetchCampaigns = async () => {
    const params = {
      skip: 0,
      limit: 100,
    };
    const response = await getCampaigns()(params);
    setCampaignOptions(response.data.data);
  };

  const handleSubmit = async () => {
    try {
      if (!status || !disposition || !subDisposition) {
        enqueueSnackbar('Please fill all mandatory fields', {
          variant: 'error',
        });
        return;
      }

      // Add validation for specific sub-dispositions
      const mandatoryFollowUpSubDispositions = [
        'Callback',
        'Stock still available',
      ];
      if (
        mandatoryFollowUpSubDispositions.includes(subDisposition) &&
        (!selectedDate || !selectedTime)
      ) {
        enqueueSnackbar(
          'Follow-up date and time are mandatory for this sub-disposition',
          {
            variant: 'error',
          }
        );
        return;
      }

      let followUpDateTime = null;
      if (selectedDate && selectedTime) {
        const combinedDateTime = dayjs(selectedDate)
          .hour(selectedTime.hour())
          .minute(selectedTime.minute());
        followUpDateTime = combinedDateTime.toISOString();
      }

      const data = {
        userId: Number(userId),
        data: {
          taskId: Number(taskId),
          status: status,
          disposition: disposition,
          subDisposition: subDisposition,
          completeTask: markAsCompleted,
          followUpDate: followUpDateTime,
          meta: {
            medicineRemaining: medicineRemaining,
            notes: notes,
          },
        },
      };
      await patchDisposition()(data);
      enqueueSnackbar('Disposition updated successfully', {
        variant: 'success',
      });
      if (willCreateNewTask) {
        setOpenDialog(true);
      } else {
        navigate(`/crm-dashboard/tasks`);
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Something went wrong', {
        variant: 'error',
      });
    }
  };

  const handleCreateNewTask = async () => {
    try {
      const data = {
        userId: Number(userId),
        priority: campaign.priority,
        name: campaign.name,
        campaignId: campaign.id,
        status: 5,
      };
      await createTask()(data);
      navigate(`/crm-dashboard/tasks`);
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error.response.data.message, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (userId && activeTab === 'conversation') {
      fetchCampaigns();
    }
  }, [userId, activeTab]);

  return (
    <div className={styles.main}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className={styles.formContainer}>
          <div className={styles.formGroup}>
            <label>
              Status<span className={styles.required}>*</span>
            </label>
            <Select
              fullWidth
              value={status}
              onChange={handleStatusChange}
              className={styles.select}
              required
            >
              <MenuItem value='Not Connected'>Not Connected</MenuItem>
              <MenuItem value='Connected'>Connected</MenuItem>
            </Select>
          </div>

          <div className={styles.formGroup}>
            <label>
              Disposition<span className={styles.required}>*</span>
            </label>

            <Select
              value={disposition}
              onChange={handleDispositionChange}
              className={styles.select}
              fullWidth
              required
            >
              {dispositionOptions[status]?.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div className={styles.formGroup}>
            <label>
              Sub Disposition<span className={styles.required}>*</span>
            </label>

            <Select
              value={subDisposition}
              onChange={(e) => setSubDisposition(e.target.value)}
              className={styles.select}
              fullWidth
              required
            >
              {subDispositionOptions[disposition]?.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div className={styles.formGroup}>
            <label>Follow Up DateTime</label>
            <div className={styles.dateTimeGroup}>
              <DatePicker
                value={selectedDate}
                onChange={(newValue) => setSelectedDate(newValue)}
                className={styles.datePicker}
                minDate={dayjs()}
              />
              <TimePicker
                value={selectedTime}
                onChange={(newValue) => setSelectedTime(newValue)}
                className={styles.timePicker}
                minTime={
                  dayjs()?.hour() === selectedDate?.hour() ? dayjs() : null
                }
              />
            </div>
          </div>

          <div className={styles.formGroup}>
            <label>#Days of medicine remaining</label>
            <TextField
              value={medicineRemaining}
              type='number'
              onChange={(e) => setMedicineRemaining(e.target.value)}
              className={styles.input}
              fullWidth
            />
          </div>

          <div className={styles.formGroup}>
            <label>Notes</label>
            <TextField
              fullWidth
              multiline
              rows={4}
              placeholder='Enter any additional notes or comments...'
              className={styles.input}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </div>

          <div className={styles.btnContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={willCreateNewTask}
                  onChange={(e) => setWillCreateNewTask(e.target.checked)}
                  color='primary'
                />
              }
              label='Will Create a New Task'
            />

            <div className={styles.rightContainer}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={markAsCompleted}
                    onChange={(e) => setMarkAsCompleted(e.target.checked)}
                    color='primary'
                  />
                }
                label='Mark as Completed'
              />

              <Button
                className={styles.saveButton}
                variant='contained'
                color='primary'
                onClick={handleSubmit}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <div className={styles.dialogContainer}>
            <DialogTitle>Create a New Task</DialogTitle>
            <DialogContent>
              <FormControl variant='outlined' fullWidth>
                <InputLabel>Create a New Task</InputLabel>
                <Select
                  value={campaign}
                  onChange={(e) => {
                    setCampaign(e.target.value);
                  }}
                  label='Create a New Task'
                >
                  {campaignOptions?.map((campaign) => (
                    <MenuItem key={campaign.id} value={campaign}>
                      {campaign.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>
            <Button
              fullWidth
              className={styles.saveButton}
              variant='contained'
              color='primary'
              onClick={handleCreateNewTask}
            >
              Create Task
            </Button>
          </div>
        </Dialog>
      </LocalizationProvider>
    </div>
  );
};

export default HadAConversation;
