import React, { useEffect, useState } from 'react';
import styles from './Status.module.scss';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  StyledAccordion,
  StyledAccordionSummary,
} from 'components/CustomElements/Accordion';
import CancelButton from './CancelButton';
import TimeStamp from './TimeStamps';
import { Button } from '@mui/material';
const OrderStatus = ({
  shipment,
  isMobile,
  data = [],
  handleCancelOrder,
  customStyle,
  statusId,
  priceDetails,
  status,
  handleShowReturn,
}) => {
  const [cancelType, setCancelType] = useState('');

  useEffect(() => {
    if (statusId <= 60) {
      setCancelType('show button');
    } else if (statusId === 80 || statusId === 70) {
      setCancelType('hide button');
    } else {
      setCancelType('show Tooltip');
    }
  }, [statusId]);

  return (
    <StyledAccordion
      defaultExpanded={true}
      className={`${styles.accordion} ${customStyle}`}
      slotProps={{ transition: { unmountOnExit: true } }}
    >
      <StyledAccordionSummary
        expandIcon={
          <ExpandMoreIcon className={styles.accordion__expand_icon} />
        }
      >
        {shipment ? 'Shipment' : 'Order'} Status
      </StyledAccordionSummary>
      <div className={styles.status_cont}>
        {data.map((item, index) => {
          return <TimeStamp key={index} item={item} />;
        })}
        <div className={styles.status_cont__btn_container}>
          <CancelButton
            shipment={shipment}
            isMobile={isMobile}
            price={priceDetails?.totalSavingOnOrder}
            handleCancelOrder={handleCancelOrder}
            statusId={statusId}
            cancelType={cancelType}
            status={status}
          />
          {statusId === 70 && (
            <Button onClick={handleShowReturn} variant='text' color='primary'>
              Return Order
            </Button>
          )}
        </div>
      </div>
    </StyledAccordion>
  );
};

export default OrderStatus;
