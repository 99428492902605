import React, { useState } from 'react';
import styles from '../cartDetails.module.scss';
import { addDelimeter } from 'utils/commonFunctions';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  InputAdornment,
  OutlinedInput,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import UploadFile from '../UploadFiles';
import { getAzureUrls } from 'utils/api';
import UploadedFiles from '../UploadFiles/uploaded-files';

const ItemDetails = ({
  item,
  returnItem,
  setReturnItems,
  returnItems,
  showReturn,
}) => {
  const {
    itemCartData: {
      drugName = '',
      itemTotalDiscountPrice = 0,
      itemTotalPrice = 0,
      quantity = 0,
    } = {},
    itemCatalogData: {
      displayName = '',
      masterDrugCode = 0,
      heroImage = '',
      drugCategory = '',
      manufacturerName = '',
      packForm = '',
      packQuantityValueDecimal = 0,
      drugForm = '',
    } = {},
  } = item || {};

  const [uploading, setUploading] = useState(false);

  const ALLOWED_FILE_FORMATS = ['image/jpeg', 'image/png', 'application/pdf'];
  const MAX_FILE_SIZE_MB = 15;

  const handleQuantityChange = (e) => {
    const value = Math.min(e.target.value, quantity);
    setReturnItems(
      returnItems.map((item) =>
        item.drugCode === returnItem.drugCode
          ? { ...item, quantity: value }
          : item
      )
    );
  };

  const handleReasonChange = (e) => {
    console.log(e.target.value);
    setReturnItems(
      returnItems.map((item) =>
        item.drugCode === returnItem.drugCode
          ? { ...item, reason: e.target.value }
          : item
      )
    );
  };

  const getImgUrls = async (files) => {
    try {
      setUploading(true);
      const payload = new FormData();
      files.forEach((file) => {
        payload.append('files', file);
      });
      const res = await getAzureUrls()(payload);
      const data = res.data.data;
      setReturnItems(
        returnItems.map((item) =>
          item.drugCode === returnItem.drugCode
            ? { ...item, imageUrls: [...item.imageUrls, ...data] }
            : item
        )
      );
    } catch (error) {
      console.log(error);
    } finally {
      setUploading(false);
    }
  };

  const handleFileSelect = (files) => {
    if (returnItem?.imageUrls?.length + files?.length > 5)
      return enqueueSnackbar(
        'Too many images. You can upload a maximum of 5 images.',
        { variant: 'info' }
      );
    const newFiles = [];
    [...files].map((item) => {
      if (!ALLOWED_FILE_FORMATS.includes(item.type))
        return enqueueSnackbar('Invalid Format.', { variant: 'error' });
      if (item.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        return enqueueSnackbar('FileSize exceeded.', { variant: 'error' });
      }
      newFiles.push(item);
    });
    getImgUrls(newFiles);
  };

  const removeReasonImg = (e, imgItem) => {
    e.stopPropagation();
    setReturnItems(
      returnItems.map((item) =>
        item.drugCode === returnItem.drugCode
          ? {
              ...item,
              imageUrls: item.imageUrls.filter(
                (file) => file.file_name != imgItem.file_name
              ),
            }
          : item
      )
    );
  };

  return (
    <div className={styles.item_container}>
      <div className={styles.content}>
        <div>
          <div
            style={{
              backgroundImage: `url("${heroImage}")`,
            }}
            className={styles.item_img}
          />
          {drugCategory === 'RX' && <div className={styles.rx_icon}>Rx</div>}
        </div>
        <div className={styles.content_details}>
          <div className={styles.item_details}>
            <p className={styles.drug_name}>{displayName}</p>
            <div className={styles.img_container}>
              <div className={styles.manufacturer_name}>
                {manufacturerName?.split(' ')[0]}
              </div>
              <div className={styles.vertical_divider} />
              <div className={styles.manufacturer_name}>
                {packForm} of {packQuantityValueDecimal} {drugForm}
              </div>
            </div>
            <div className={styles.price_container}>
              <div className={styles.discounted_price}>
                ₹{addDelimeter(itemTotalDiscountPrice)}
              </div>
              <div className={styles.mrp_price}>
                ₹{addDelimeter(itemTotalPrice)}
              </div>
            </div>
          </div>
          <div>
            {returnItem && (
              <UploadFile
                removeImg={removeReasonImg}
                handleFileSelect={handleFileSelect}
                reasonImgs={returnItem?.imageUrls}
                id={returnItem?.drugCode}
                uploading={uploading}
                showReturn
              />
            )}
            {showReturn && (
              <UploadedFiles
                removeImg={removeReasonImg}
                reasonImgs={item?.imageUrls}
              />
            )}
            <div className={styles.return_details}>
              {returnItem && (
                <FormControl fullWidth size='small' sx={{ minWidth: '200px' }}>
                  <InputLabel id='return-reason-label'>
                    Reason for Return
                  </InputLabel>
                  <Select
                    color='primary'
                    labelId='return-reason-label'
                    value={returnItem?.reason}
                    onChange={handleReasonChange}
                    className={styles.return_reason_select}
                    input={
                      <OutlinedInput
                        id='return-reason-select'
                        label='Reason for Return'
                      />
                    }
                  >
                    <MenuItem value='damaged'>Damaged</MenuItem>
                    <MenuItem value='wrong_item'>Wrong Item</MenuItem>
                    <MenuItem value='not_needed'>Not Needed</MenuItem>
                    <MenuItem value='other'>Other</MenuItem>
                  </Select>
                </FormControl>
              )}
              {returnItem ? (
                <div className={styles.item_qty}>
                  {/* Qty:{' '} */}
                  <TextField
                    type='number'
                    min='1'
                    size='small'
                    max={quantity}
                    value={returnItem.quantity}
                    onChange={handleQuantityChange}
                    className={styles.return_qty_input}
                    label='Qty'
                  />
                </div>
              ) : (
                <div className={styles.item_qty}>Qty: {quantity}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemDetails;
