import React, { useState, useEffect, useRef } from 'react';
import styles from './ScanBarCode.module.scss';
import Quagga from 'quagga';
import { TextField } from '@mui/material';
import OrImg from 'assets/icons/or.png';

const ScanBarCode = ({ text, handleFunction, scanType }) => {
  const [showScanner, setShowScanner] = useState(false);
  const [manualInput, setManualInput] = useState('');
  const scannerRef = useRef(null);
  const quaggaInitialized = useRef(false);

  useEffect(() => {
    if (showScanner) {
      initializeScanner();
    }
    return () => {
      cleanupScanner();
    };
  }, [showScanner]);

  const cleanupScanner = () => {
    if (quaggaInitialized.current) {
      try {
        Quagga.offDetected();
        Quagga.stop();
        quaggaInitialized.current = false;
      } catch (err) {
        console.error('Error stopping Quagga:', err);
      }
    }
  };

  const initializeScanner = () => {
    if (!scannerRef.current || quaggaInitialized.current) return;

    Quagga.init(
      {
        inputStream: {
          name: 'Live',
          type: 'LiveStream',
          target: scannerRef.current,
          constraints: {
            facingMode: 'environment',
          },
        },
        decoder: {
          readers: ['code_128_reader'],
          multiple: false,
        },
        locate: true,
        numOfWorkers: 4,
      },
      (err) => {
        if (err) {
          console.error('Quagga initialization failed:', err);
          return;
        }
        quaggaInitialized.current = true;
        Quagga.start();

        Quagga.onDetected((result) => {
          if (result && result.codeResult) {
            const scannedCode = result.codeResult.code;
            handleFunction(scannedCode, scanType);
            setShowScanner(false);
            cleanupScanner();
          }
        });
      }
    );
  };

  return (
    <div className={styles.main}>
      <button
        className={styles.scanButton}
        onClick={() => setShowScanner(true)}
      >
        {text ? text : ' Scan Barcode'}
      </button>

      {showScanner && (
        <div className={styles.scanner_container}>
          <div ref={scannerRef} className={styles.scanner_view} />
          <button
            className={styles.closeButton}
            onClick={() => setShowScanner(false)}
          >
            Close Scanner
          </button>
          <div className={styles.btns_container}>
            <img src={OrImg} alt='orImage' width='180px' />

            <div className={styles.manualInputs}>
              <TextField
                label='Enter barcode manually'
                variant='outlined'
                value={manualInput}
                onChange={(e) => setManualInput(e.target.value)}
                fullWidth
                size='small'
              />
              <button
                className={styles.submit__btn}
                onClick={() => {
                  handleFunction(manualInput, scanType);
                  setManualInput('');
                  setShowScanner(false);
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ScanBarCode;
